export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_USER_INFO = "GET_USER_INFO";
export const TOKEN_ERROR = "TOKEN_ERROR";

export const CLEAN_DATA = "CLEAN_DATA";
export const GET_MOVIMIENTOS_LIST = "GET_MOVIMIENTOS_LIST";
export const CREATE_LINE = "CREATE_LINE";
export const GET_LAST_CLIENT_DETAIL = "GET_LAST_CLIENT_DETAIL";
export const BLOCK_LINE = "BLOCK_LINE";

export const GET_MOVIMIENTOS_BY_TYPE = "GET_MOVIMIENTOS_BY_TYPE";
export const APPROVE_MOVIMIENTO = "APPROVE_MOVIMIENTO";
export const DELETE_MOVIMIENTO = "DELETE_MOVIMIENTO";

export const GET_MOVIMIENTO_LINEA = "GET_MOVIMIENTO_LINEA";
export const CREATE_MOVIMIENTO = "CREATE_MOVIMIENTO";
export const EDIT_MOVIMIENTO = "EDIT_MOVIMIENTO";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const ERROR = "ERROR";

export const GET_MOVIMIENTOS_FOR_INBOX = "GET_MOVIMIENTOS_FOR_INBOX";
