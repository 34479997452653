import React, { useState } from 'react';
import { CardBody, Collapse} from 'reactstrap';
import PropTypes from 'prop-types';
import PosicionDeudorAccordionSection from './posicionDeudorAccordionSection';


const PosicionDeudorAccordion = ({sections}) => {
  const initialAccordion = Array(sections.length).fill(false);
  const [ accordion, setAccordion ] = useState(initialAccordion);

  const handleToggleAccordion = index => () => {
    setAccordion(accordion.map((bool, idx) => idx === index ? !bool : bool));
  };

  return (
    <>
      {sections.map( (section, idx) => (
        <PosicionDeudorAccordionSection
          key={section.name}
          title={section.name}
          toggle={handleToggleAccordion(idx)}
          collapsed={!accordion[idx]}
          isValid={section.isValid}
          expansible={section.expansible}
        >
          <Collapse isOpen={accordion[idx]}>
            <CardBody>
              {section.component}
            </CardBody>
          </Collapse>
        </PosicionDeudorAccordionSection>
      ))}
    </>
  );
};

PosicionDeudorAccordion.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PosicionDeudorAccordion;
