import isUserHasProfile from "helpers/profileValidator";
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';
import { put, get } from 'helpers/requests';
import { BASE_URL, API, ESTADOS_DEUDOR, PROFILES } from 'CONST';
import { formatRut, fdate } from '../../../helpers/helpers';
import { objectsToOptions, objectToOption } from '../../../helpers/selects';
import SafeBtn from '../../shared/SafeBtn';

const PosicionDeudorEdit = ({ openModal, datos, toggleModal, id }) => {

  const [nombre, setNombre] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [tamano, setTamano] = useState();
  const [opcionesTamano, setOpcionesTamano] =useState([]);
  const [rubro, setRubro] = useState();
  const [rubros, setRubros] = useState([]);
  const [estado, setEstado] = useState();
  const [techo, setTecho] = useState();
  const [motivo, setMotivo] = useState(null);
  const [motivosOpts, setMotivos] = useState([]);
  const [isValidTecho, setIsValidTecho] = useState(true);
  const [invalidTechoError, setInvalidTechoError] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isValidMotivo, setIsValidMotivo] = useState(true);

  const opciones = [
    { label: 'Vigente', value: 'vigente' },
    { label: 'Bloqueado', value: 'bloqueado' },
  ];

  const fetchData = (apiUrl) => {
    let url = apiUrl;
    let data = [];
    return get(url)
      .then(response => {
        data = response.results;
        url = response.next;
        if(url !== '' && url !== null){
          return fetchData(url).then((res) => data.concat(res));
        }
        return data;
    })
  }

  const getMotivo = (res) => res.id === datos.motivo_bloqueo_id;
  const getRubro = (res) => res.id === datos.rubro_id;
  const getEstado = (res) => res.value === datos.estado;
  const getTamano = (res) => res.name === datos.tamano;
  const getInput = (input) => input || '---';

  const setTechoVigente = (value) => {
    if (value === 0) {
      setTecho(null);
    }
    else {
      setTecho(value);
    }
    if (isUserHasProfile([PROFILES.analistaRiesgoCredito])) {
      if (value <= 10000) {
        setIsValidTecho(true);
      }
      else {
        setIsValidTecho(false);
        setInvalidTechoError('No tiene permitido ingresar Techo Vigente mayor a 10.000 UF.');
      }
    }
    else if (isUserHasProfile([PROFILES.gerenteRiesgoCredito])) {
      if (value <= 40000) {
        setIsValidTecho(true);
      }
      else {
        setIsValidTecho(false);
        setInvalidTechoError('No tiene permitido ingresar Techo Vigente mayor a 40.000 UF.');
      }
    }
    else if (isUserHasProfile([PROFILES.actuariado])) {
      if (value <= 68000) {
        setIsValidTecho(true);
      }
      else {
        setIsValidTecho(false);
        setInvalidTechoError('No tiene permitido ingresar Techo Vigente mayor a 68.000 UF.');
      }
    }
    else if (isUserHasProfile([PROFILES.ceo])) {
      setIsValidTecho(true);
    }
  }

  const setDataEdition = () => {
    setNombre(datos.contacto);
    setTelefono(datos.phone);
    setEmail(datos.email);
    setEstado(opciones.find(getEstado));
    setTechoVigente(datos.techo_vigente)
  }

  useEffect(() => {
    get(`${BASE_URL}${API.motivosResolucion}/?ordering=descripcion`).then((response) => {
      setMotivos(objectsToOptions(response));
      setMotivo(datos.motivo_bloqueo_id ? objectToOption(response.find(getMotivo)) : null);
    });
    fetchData(`${BASE_URL}${API.actividadEconomica}/?ordering=descripcion`).then((response) => {
      setRubros(objectsToOptions(response));
      setRubro(datos.rubro_id ? objectToOption(response.find(getRubro)) : null);
    });
    fetchData(`${BASE_URL}${API.tamanoEmpresa}/?ordering=descripcion`).then((response) => {
      setOpcionesTamano(objectsToOptions(response,false,false,'name'));
      const isInSelector = response.find(getTamano);
      if (isInSelector){
        setTamano(datos.tamano ? objectToOption(response.find(getTamano), 'name') : null);
      }
      else {
        setTamano(null);
      }

    });
    setDataEdition();
  }, [datos]);

  useEffect(() => {
    if(estado) {
      setIsValidMotivo(true);
      if(estado.value === ESTADOS_DEUDOR.bloqueado && !motivo) {
        setIsValidMotivo(false);
      }
    }
  }, [motivo, estado])

  const changeStatus = (value) => {
    setEstado(value);
    if (value.value === ESTADOS_DEUDOR.vigente){
      setMotivo(null);
    }
  }

  const onCloseModal = () => {
    toggleModal();
  };

  const onClick = () => {
    setLoading(true);
    const data = {
      rut: datos.rut,
      contacto: nombre,
      phone: telefono,
      email,
      tamano,
      estado: estado.value,
      techo_vigente: techo,
    }
    if (rubro) {
      data.rubro_id = rubro.value.id;
    }
    if (estado.value === ESTADOS_DEUDOR.bloqueado){
      data.motivo_bloqueo_id = motivo.value.id;
    }
    if (tamano) {
      data.tamano = tamano.label
    }
    put(`${BASE_URL}${API.deudor}/${id}/`, data)
    .then(() => {toggleModal();})
    .then(() => setLoading(false));
  };

  return (
    <Modal isOpen={openModal} style={{maxWidth: '1100px', width: '80%'}} type="modal-warning warning-container">
      <ModalHeader>
        Editar deudor
      </ModalHeader>
      <ModalBody>

        <Container>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="6">
              <FormGroup row>
                <Label sm={5} for="rutDeudor">
                  RUT Deudor
                </Label>
                <p id="rutDeudor" style={{ paddingLeft: 0, paddingBottom: 10 }}>{formatRut(datos.rut)}</p>
              </FormGroup>
              <FormGroup row style={{ flexWrap: 'nowrap' }}>
                <Label sm={5} for="razonSocial">
                  Razón Social
                </Label>
                <p id="razonSocial" style={{ paddingLeft: 0, paddingBottom: 10 }}>{datos.name}</p>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} for="fechaInicio">
                  Fecha inicio actividades
                </Label>
                <p id="fechaInicio" style={{ paddingLeft: 0, paddingBottom: 10 }}>
                  {getInput(fdate(datos.inicio_actividad))}
                </p>
              </FormGroup>
              <FormGroup row style={{ flexWrap: 'nowrap' }}>
                <Label sm={5} for="actividad">
                  Actividad
                </Label>
                <p id="actividad" style={{ paddingLeft: 0, paddingBottom: 10 }}>
                  {getInput(datos.actividad && datos.actividad.map((actividad) => actividad.Nombre).join(', '))}
                </p>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} for="estadoDeudor">
                  Estado Deudor
                </Label>
                <Col sm={7} style={{ paddingLeft: 0, paddingBottom: 10 }}>
                  <Select
                    id="estadoDeudor"
                    width="100%"
                    height="50%"
                    options={opciones}
                    value={estado}
                    onChange={(event) => changeStatus(event)}
                  />
                </Col>
              </FormGroup>
              {estado &&
              estado.value &&
              estado.value==='bloqueado' &&
              (
              <>
                <FormGroup row>
                  <Label sm={5} for="motivo">
                    Motivo de bloqueo
                  </Label>
                  <Col sm={7} style={{ paddingLeft: 0, paddingBottom: 15 }}>
                    <Select
                      id="motivo"
                      width="100%"
                      height="50%"
                      options={motivosOpts}
                      value={motivo}
                      onChange={(event) => setMotivo(event)}
                    />
                    {!motivo && (
                      <FormFeedback>Debe seleccionar un motivo para el bloqueo</FormFeedback>
                    )}
                  </Col>
                </FormGroup>
              </>
              )}
            </Col>
            <Col>
              <FormGroup row>
                <Label sm={5} for="rubro">
                  Rubro
                </Label>
                <Col sm={7} style={{ paddingLeft: 0, paddingBottom: 15 }}>
                  <Select
                    id="rubro"
                    width="100%"
                    height="50%"
                    options={rubros}
                    value={rubro}
                    onChange={(event) => setRubro(event)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} for="tamanno">
                  Tamaño
                </Label>
                <Col sm={7} style={{ paddingLeft: 0, paddingBottom: 15 }}>
                  <Select
                    id="tamanno"
                    options={opcionesTamano}
                    value={tamano}
                    onChange={(event) => setTamano(event)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} for="emailContacto">
                  Email contacto
                </Label>
                <Col sm={7} style={{ paddingLeft: 0, paddingBottom: 15 }}>
                  <Input
                    id="emailContacto"
                    type="text"
                    value={email || ""}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} for="telefonoContacto">
                  Teléfono contacto
                </Label>
                <Col sm={7} style={{ paddingLeft: 0, paddingBottom: 15 }}>
                  <Input
                    id="telefonoContacto"
                    type="text"
                    value={telefono || ""}
                    onChange={(event) => setTelefono(event.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} for="nombreContacto">
                  Nombre Contacto
                </Label>
                <Col sm={7} style={{ paddingLeft: 0,  paddingBottom: 15 }}>
                  <Input
                    id="nombreContacto"
                    type="text"
                    value={nombre || ""}
                    onChange={(event) => setNombre(event.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} for="techo">
                  Techo Vigente (UF)
                </Label>
                <Col sm={7} style={{ paddingLeft: 0, paddingBottom: 15 }}>
                  <NumberFormat
                    className="form-control"
                    name="techo"
                    id="techo"
                    value={techo}
                    onValueChange={({floatValue}) => setTechoVigente(floatValue)}
                    thousandSeparator="."
                    decimalScale="0"
                    decimalSeparator={false}
                    allowNegative={false}
                    renderText={(value) => <p className="small-form-value">{value}</p>}
                  />
                  {!isValidTecho && (
                    <FormFeedback style={{ fontSize: "12px", float:"right" }}>
                      <span>{invalidTechoError}</span>
                    </FormFeedback>
                  )}
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button className="warning-cancel mr-auto" onClick={() => onCloseModal()}>
          Cancelar
        </Button>
        <SafeBtn
          className="warning-accept"
          onClick={() => onClick()}
          disabled={!isValidTecho || !isValidMotivo}
          loading={loading}
        >
          Aceptar
        </SafeBtn>
      </ModalFooter>
    </Modal>
  );
};

PosicionDeudorEdit.propTypes = {
  openModal: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  datos: PropTypes.shape({
    rut: PropTypes.string,
    name: PropTypes.string,
    estado: PropTypes.string,
    motivo_bloqueo_id: PropTypes.number,
    inicio_actividad: PropTypes.string,
    actividad: PropTypes.arrayOf(
      PropTypes.object,
    ),
    rubro_id: PropTypes.string,
    tamano: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    contacto: PropTypes.string,
    score: PropTypes.number,
    techo_vigente: PropTypes.number,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default PosicionDeudorEdit;
