import React from 'react';
import PropTypes from 'prop-types';

const FormSection = ({ title, children, style }) => (
  <div className="form-section" style={style}>
    <h5 className="form-title">{title}</h5>
    <hr className="form-separator" />
    {children}
  </div>
);

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

FormSection.defaultProps = {
  style: null,
};

export default FormSection;
