import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';

const PosicionDeudorRentaTributaria = ({ rentaTributaria }) => (
  <ListGroup style={{ margin: '25px 0 40px 0', width: '100%' }}>
    {rentaTributaria.map((item) => (
      <ListGroupItem key={item && item.anno} style={{ marginBottom: '2px' }}>
        <Row>
          <Col><p>Año Tributario:</p></Col>
          <Col><p>{(item && item.anno) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Razón Social:</p></Col>
          <Col><p>{(item && item.razon_social) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Tamaño empresa:</p></Col>
          <Col><p>{(item && item.tamano_empresa) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Venta Anual:</p></Col>
          <Col><p>{(item && item.venta_anual) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>N° Trabajadores:</p></Col>
          <Col><p>{(item && item.trabajadores) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Rubro:</p></Col>
          <Col><p>{(item && item.rubro) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Sub-Rubro:</p></Col>
          <Col><p>{(item && item.sub_rubro) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Actividad:</p></Col>
          <Col><p>{(item && item.actividad) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Dirección:</p></Col>
          <Col><p>{(item && item.dirección) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Fecha Inicio Actividades:</p></Col>
          <Col><p>{(item && item.inicio_actividades) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Fecha Fin Actividades:</p></Col>
          <Col><p>{(item && item.termino_actividades) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Razón Fin Actividades:</p></Col>
          <Col><p>{(item && item.tipo_termino_actividades) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Tipo Contribuyente:</p></Col>
          <Col><p>{(item && item.tipo_contribuyente) || '---'}</p></Col>
        </Row>
        <Row>
          <Col><p>Sub-Tipo Contribuyente:</p></Col>
          <Col><p>{(item && item.sub_tipo_contribuyente) || '---'}</p></Col>
        </Row>
      </ListGroupItem>
    ))}
  </ListGroup>
);

PosicionDeudorRentaTributaria.propTypes = {
  rentaTributaria: PropTypes.arrayOf(
    PropTypes.shape({
      anno: PropTypes.number,
      razon_social: PropTypes.string,
      tamano_empresa: PropTypes.string,
      venta_anual: PropTypes.string,
      trabajadores: PropTypes.number,
      rubro: PropTypes.string,
      sub_rubro: PropTypes.string,
      actividad: PropTypes.string,
      dirección: PropTypes.string,
      inicio_actividades: PropTypes.string,
      termino_actividades: PropTypes.string,
      tipo_termino_actividades: PropTypes.string,
      tipo_contribuyente: PropTypes.string,
      sub_tipo_contribuyente: PropTypes.string,
    }),
  ).isRequired,
};

export default PosicionDeudorRentaTributaria;
