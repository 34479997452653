import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import {
  POSICION_DEUDOR_READ,
  INBOX_VIEW_READ,
  RESOLUCIONES_WRITE,
  ASEGURADOS_POLIZAS_READ,
} from "helpers/profilePermission";
import isUserHasProfile from "helpers/profileValidator";
import AppRedirect from '../components/shared/AppRedirect';

import InboxRiesgo from '../components/solicitudes/inboxRiesgo/InboxRiesgo';
import PosicionDeudor from '../components/solicitudes/posicionDeudor/posicionDeudor';
import AseguradosPolizas from '../components/polizas/aseguradosPolizas/AseguradosPolizas';
import AseguradosDetalle from '../components/polizas/aseguradosPolizas/detalles/AseguradosDetalle';
import Resolucion from '../components/resolucion/Resolucion';
import DeudorList from '../components/deudores/DeudorList';

const LoggedInRoutes = ({ user }) =>
  !user.has_access ? (
    <AppRedirect />
  ) : (
    <Switch>
      {isUserHasProfile(INBOX_VIEW_READ) && (
        <Route exact path="/solicitudes/inbox-riesgos" component={InboxRiesgo} />
      )}
      {isUserHasProfile(RESOLUCIONES_WRITE) && (
        <Route exact path="/solicitudes/inbox-riesgos/:id" component={Resolucion} />
      )}
      {isUserHasProfile(POSICION_DEUDOR_READ) && (
        <Route exact path="/solicitudes/posicion-deudor" component={DeudorList} />
      )}
      {isUserHasProfile(POSICION_DEUDOR_READ) && (
        <Route exact path="/solicitudes/posicion-deudor/:id" component={PosicionDeudor} />
      )}
      {isUserHasProfile(ASEGURADOS_POLIZAS_READ) && (
        <Route exact path="/polizas/asegurados-polizas" component={AseguradosPolizas} />
      )}
      {isUserHasProfile(ASEGURADOS_POLIZAS_READ) && (
        <Route exact path="/polizas/asegurados-polizas/:id" component={AseguradosDetalle} />
      )}
      <Route render={() => <Redirect to="/solicitudes/inbox-riesgos" />} />
    </Switch>
  );

LoggedInRoutes.propTypes = {
  user: PropTypes.shape({
    has_access: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
});

export default withRouter(connect(mapStateToProps)(LoggedInRoutes));
