import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';


const FilterSelect = (props) => {
  const {
    onFilterChange,
    filter,
    options,
    defaultOption,
    customOnChange,
  } = props;

  const allOptions = [ defaultOption, ...options];
  return (
    <Select
      className="filter-select"
      onChange={(value) => {
        onFilterChange(value.value);
        if (customOnChange) {
          customOnChange(value.value);
        }
      }}
      value={filter ? allOptions.find(elem => elem.value === filter.value) : allOptions[0]}
      options={allOptions}
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  );
};


FilterSelect.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })).isRequired,
  defaultOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  customOnChange: PropTypes.func,
};

FilterSelect.defaultProps = {
  filter: null,
  defaultOption: null,
  customOnChange: null,
};

export default FilterSelect;
