import React, { useState } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { PROFILES } from 'CONST';
import isUserHasProfile from '../../helpers/profileValidator';
import { INBOX_VIEW_WRITE } from '../../helpers/profilePermission';

const CheckboxDropdown = ({
  analista,
  analistas,
  agregar,
  quitar,
  movId,
  checked,
}) => {
  const [disable, setDisable] = useState(!checked);
  const [check, setCheck] = useState(checked);

  const onChangeSelect = (id, value) => {
    agregar(id, value);
    setCheck(!check);
  }
  const onChangeCheckbox = () => {
    setCheck(!check);
    setDisable(!disable);
    quitar(disable, movId);
  }

  const checkIsDisabled = () => !isUserHasProfile(INBOX_VIEW_WRITE)
    || (!check && analista && isUserHasProfile([PROFILES.analistaRiesgoCredito]));

  return (
    <div
      style={{marginLeft: "0.75rem"}}
    >
      <Input
        type="checkbox"
        onChange={() => onChangeCheckbox()}
        checked={check}
        value={check}
        disabled={checkIsDisabled()}
        style={{marginTop: "0.75rem"}}
      />
      <Select
        options={analistas}
        placeholder="Seleccionar"
        value={analista}
        menuPortalTarget={document.querySelector('body')}
        isDisabled={disable}
        onChange={event => onChangeSelect(movId, event)}
      />
    </div>
  )
};

CheckboxDropdown.propTypes = {
  analista: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  analistas: PropTypes.arrayOf(PropTypes.object).isRequired,
  agregar: PropTypes.func.isRequired,
  quitar: PropTypes.func.isRequired,
  movId: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
}

CheckboxDropdown.defaultProps = {
  analista: null,
}

export default CheckboxDropdown;
