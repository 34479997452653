import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { headerOperation, cellOperation } from 'helpers/tables';
import BaseTable from '../../shared/BaseTable';

const PosicionDeudorDirectorioFull = ({ directorioFull }) => {
  const [actividades, setActividades] = useState([]);

  useEffect(() => {
    if (directorioFull.actividades) {
      setActividades(directorioFull.actividades.map((item) => ({ nombre: item })));
    }
  }, [directorioFull.actividades]);

  const columnsActividades = [
    {
      Header: headerOperation('ACTIVIDADES'),
      accessor: 'nombre',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <p>Razón Social:</p>
        </Col>
        <Col>
          <p>{(directorioFull && directorioFull.razon_social) || '---'}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Dirección:</p>
        </Col>
        <Col>
          <p>{(directorioFull && directorioFull.direccion) || '---'}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Comuna:</p>
        </Col>
        <Col>
          <p>{(directorioFull && directorioFull.comuna) || '---'}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Ciudad:</p>
        </Col>
        <Col>
          <p>{(directorioFull && directorioFull.ciudad) || '---'}</p>
        </Col>
      </Row>
      <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
        <BaseTable
          columns={columnsActividades}
          data={actividades}
          sortable={false}
          style={{ backgroundColor: 'white' }}
        />
      </div>
    </>
  );
};

PosicionDeudorDirectorioFull.propTypes = {
  directorioFull: PropTypes.shape({
    razon_social: PropTypes.string,
    direccion: PropTypes.string,
    comuna: PropTypes.string,
    ciudad: PropTypes.string,
    actividades: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default PosicionDeudorDirectorioFull;
