import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import SafeBtn from 'components/shared/SafeBtn';


const ComentarioResolucionConfirmationModal = ({
  openModal,
  saveComentario,
  setOpenConfirmationModal,
  saveCommentLoading,
  notificar,
}) => (
  <Modal
    style={{maxWidth: '600px' }}
    isOpen={openModal}
    type="check-modal"
  >
    <ModalHeader>
      ¿Está seguro que desea guardar este comentario?
    </ModalHeader>
    <ModalBody style={{ paddingLeft: 41, paddingRight: 41 }}>
      <div id="inline-block-container" className="modal-check text pb-0">
        <div id="inline-block-message" className="check-modal-text">
          Después no podrá&nbsp;
          <u>editar</u>
          &nbsp;ni&nbsp;
          <u>eliminar</u>
          &nbsp;el comentario.
          {(notificar.length > 0) && (
            <div className='mt-4'>
              Además, se le enviará una&nbsp;
              <u>notificación</u>
              &nbsp;por correo a:
              <ul className='mb-0 pl-0'>
                {notificar.map(analista => (
                  <li key={analista.id} style={{ listStyle: "none" }}>
                    <b>
                      {`${analista.first_name} ${analista.last_name}`}
                    </b>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </ModalBody>
    <ModalFooter style={{ padding: "1rem" }}>
      <Button
        className="warning-cancel mr-auto"
        onClick={() => setOpenConfirmationModal(false)}
        style={{ marginLeft: 25 }}
        disabled={saveCommentLoading}
      >
        Cancelar
      </Button>
      <SafeBtn
        className="warning-accept"
        onClick={() => saveComentario()}
        style={{ marginRight: 25 }}
        loading={saveCommentLoading}
      >
        Guardar
      </SafeBtn>
    </ModalFooter>
  </Modal>
)

ComentarioResolucionConfirmationModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  saveComentario: PropTypes.func.isRequired,
  setOpenConfirmationModal: PropTypes.func.isRequired,
  saveCommentLoading: PropTypes.bool.isRequired,
  notificar: PropTypes.arrayOf(PropTypes.object),
}

ComentarioResolucionConfirmationModal.defaultProps = {
  notificar: [],
};

export default ComentarioResolucionConfirmationModal