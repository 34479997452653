import React from 'react';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import { Row, Col } from 'reactstrap';
import BaseTable from '../../../shared/BaseTable';
import PosicionDeudorAccordion from '../posicionDeudorAccordion';
import IndicadorRiesgoHistorico from './indicadorRiesgoHistorico';
import JsonComponent from './jsonComponent';



const Seccion = ({ sectionData }) => {

  const getHeader = (headerName, accessor) => ({
    Header: headerOperation(headerName),
    accessor,
    Cell: (row) => cellOperation(row.value, 'center'),
    minWidth: 150,
    filterable: false,
  })

  const getHTable = (data) => {
    const columns = Object.keys(data.headers).map((header) => (getHeader(data.headers[header].name, header)));
    return (
      <BaseTable columns={columns} data={data.data} sortable={false} style={{ backgroundColor: 'white' }} />
    );
  }

  const getVTable = (data) => (
    Object.keys(data.headers).map((property) => {
      const { name } = data.headers[property];
      const value = data.data[property];
      return (
        <Row key={`${name}-${value}`}>
          <Col>
            <p>{name}</p>
          </Col>
          <Col>
            <p>{value || '---'}</p>
          </Col>
        </Row>
      );
    }));

  const getComponent = (data) => {
    switch (data.type) {
      case 'h_headers':
        return getHTable(data);
      case 'v_headers':
        return getVTable(data);
      case 'summary':
        return getVTable(data);
      case 'line-chart-historical-rate':
        return <IndicadorRiesgoHistorico data={data} />
      case 'JSON':
        return <JsonComponent data={data.data} />
      default:
        return null;
    }
  }

  const sections = sectionData.map((data) => {
    const condition = !!data.data && (data.data.length > 0 || Object.keys(data.data).length > 0);
    return ({
      name: data.title,
      component: condition ? getComponent(data) : null,
      isValid: condition,
      expansible: condition,
    });
  });
  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      {sectionData.length && <PosicionDeudorAccordion sections={sections.slice(0, 19)} />}
    </div>
  )

};

Seccion.propTypes = {
  sectionData: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired
};

export default Seccion;