import React, {useState} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import SafeBtn from 'components/shared/SafeBtn';
import { useSelector } from 'react-redux';
import { post } from 'helpers/requests';
import { BASE_URL, API } from 'CONST';
import FileTable from '../shared/FileTable';
import Icon from "../UI/Icon";
import ComentarioResolucionConfirmationModal from "./ComentarioResolucionConfirmationModal";
import ComentarioResolucionCancelationModal from "./ComentarioResolucionCancelationModal";


const ComentarioResolucionModal = ({
  openModal,
  toggleModal,
  movimientoId,
  getMovimiento,
  notificables,
  rutDeudor,
  setShowSaveComment,
}) => {
  const [comentario, setComentario] = useState('');
  const [notificar, setNotificar] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [saveCommentLoading, setSaveCommentLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openCancelationModal, setOpenCancelationModal] = useState(false);

  const user = useSelector((state) => state.login.user)
  const autorId = user.sub;
  const closeModal = () => {
    toggleModal();
    setComentario('');
    setNotificar([]);
    setDocumentos([]);
  }

  const saveComentario = () => {
    setSaveCommentLoading(true);
    const data = {
      author_id: autorId,
      comentario,
      notification_pool: notificar.map(i => (i.id)),
      // Se envían los uuid de los archivos auxiliares.
      // En el backend se procesan y se almacenan los ids correctos.
      documentos: documentos.map(d => d.uuid),
      movimiento: movimientoId,
    }
    post(`${BASE_URL}${API.comentariosSolicitud}/`,data)
    .then(() => {
      getMovimiento();
      closeModal();
      setOpenConfirmationModal(false);
      setSaveCommentLoading(false);
      setShowSaveComment(true);
    });
  }
  const options = notificables.map(notificable => ({
    label: notificable.user.first_name.concat(' ', notificable.user.last_name),
    value: notificable.user,
  }))

  const addNotificados = (event) => {
    if(event) {
      const lista = event.map(i => (i.value));
      setNotificar(lista);
    }
    else {
      setNotificar([]);
    }
  }

  const cancelComment = () => {
    if (documentos.length || notificar.length || comentario) {
      setOpenCancelationModal(true);
    }
    else {
      closeModal();
    }
  }

  return(
    <>
      <Modal
        style={{maxWidth: '1100px', width: '80%'}}
        isOpen={openModal}
        type="modal-warning warning-container"
      >
        <ModalHeader>
          Agregar Comentario
        </ModalHeader>
        <ModalBody style={{ paddingLeft: 41, paddingRight: 41 }}>
          <FormGroup row>
            <Label sm={2} for="comentario-asegurado">
              Comentario
              <span className="required text-danger">*</span>
            </Label>
            <Col md={10} style={{ paddingLeft: 0, paddingBottom: 15 }}>
              <Input
                id="comentario"
                type="textarea"
                value={comentario|| ""}
                onChange={(event) => setComentario(event.target.value)}
                rows="4"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="notificados">
              Notificar
              <Icon
                id='notificar-tooltip'
                placement="right"
                tooltipText="Se le puede notificar a personas con el perfil Gerente, Administrativo, Analista o Notificaciones Riesgo Crédito."
              >
                <FontAwesomeIcon style={{color: "#315E90"}} icon={faQuestionCircle} />
              </Icon>
            </Label>
            <Col sm={10} style={{ paddingLeft: 0, paddingBottom: 15 }}>
              <Select
                options={options}
                isMulti
                onChange={(event) => addNotificados(event)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="documentos">
              Documentos
            </Label>
            <Col sm={10} style={{ paddingLeft: 0, paddingBottom: 15 }}>
              <FileTable
                prefijo={rutDeudor}
                newFiles={documentos}
                handleAdd={(file) => {
                  setDocumentos((prevState) => [...prevState, file]);
                }}
                handleDeleteNew={(file) => {
                  setDocumentos((prevState) => prevState.filter((doc) => doc.uuid !== file.uuid));
                }}
                uploadUrl={`${BASE_URL}${API.uploadDocumento}/`}
                individualMaxFileSize={13}
                globalMaxFileSize={20}
                loginIdToken=''
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{ padding: "1rem" }}>
          <Button
            className="warning-cancel mr-auto"
            onClick={() => cancelComment()}
            style={{ marginLeft: 25 }}
          >
            Cancelar
          </Button>
          <SafeBtn
            className="warning-accept"
            onClick={() => setOpenConfirmationModal(true)}
            style={{ marginRight: 25 }}
            disabled={!comentario.trim()}
          >
            Guardar
          </SafeBtn>
        </ModalFooter>
      </Modal>
      <ComentarioResolucionConfirmationModal
        openModal={openConfirmationModal}
        saveComentario={saveComentario}
        setOpenConfirmationModal={setOpenConfirmationModal}
        saveCommentLoading={saveCommentLoading}
        notificar={notificar}
      />
      <ComentarioResolucionCancelationModal
        openModal={openCancelationModal}
        setOpenCancelationModal={setOpenCancelationModal}
        closeModal={closeModal}
      />
    </>
  )
}

ComentarioResolucionModal.propTypes = {
    openModal: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    setShowSaveComment: PropTypes.func.isRequired,
    movimientoId: PropTypes.number.isRequired,
    getMovimiento: PropTypes.func.isRequired,
    notificables: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired,
    rutDeudor: PropTypes.string.isRequired
}

export default ComentarioResolucionModal