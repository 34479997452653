import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faEdit, faRedo, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import useTableFilters from "helpers/useTableFilters";
import { useSelector } from 'react-redux';
import FilterSelect from 'components/shared/FilterSelect';
import { analistasToOptions } from "../../../helpers/selects";
import BaseTable from "../../shared/BaseTable";
import CheckboxDropdown from "../../UI/AnalistSelector";
import Icon from "../../UI/Icon";
import { headerOperation, headerDoubleRowOperation, cellOperation, cellNumOperation } from "../../../helpers/tables";
import { API, BASE_URL, FRONT_DF_1, DEFAULT_PAGE_SIZE_SMALL, NO_FILTERED_ID, PROFILES } from "../../../CONST";
import { patch, get } from "../../../helpers/requests";
import { fdate, formatRut } from "../../../helpers/helpers";
import isUserHasProfile from '../../../helpers/profileValidator';
import {
  INBOX_VIEW_WRITE,
} from '../../../helpers/profilePermission';
import CheckModal from "../../shared/CheckModal";
import SolicitudReevaluacionModal from './SolicitudReevaluacionModal';


const InboxRiesgoMovimientosTable = ({ setPendientes }) => {

  const user = useSelector((state) => state.login.user)
  const [analistasFilter, setAnalistasFilter] = useState([]);
  const [analistasOptions, setAnalistasOptions] = useState([]);
  const [arrayAnalistas, setArrayAnalistas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [openReevaluacionModal, setOpenReevaluacionModal] = useState(false);
  const [movimientoIDReevaluacion, setMovimientoIDReevaluacion] = useState(0);
  const [userIDReevaluacion, setUserIDReevaluacion] = useState(0)
  const [nombreDeudor, setNombreDeudor] = useState('')
  const [nombreAsegurado, setNombreAsegurado] = useState('')
  const [onFilteredChange, onFetchData, loading, data, getData] = useTableFilters(
    `${BASE_URL}${API.riskInbox}/`,
  );

  useEffect(() => {
    get(`${BASE_URL}${API.analistasCredito}/`).then((response) => {
      setAnalistasFilter([...analistasToOptions(response.results), { label: 'No Asignado', value: 'null' }]);
      if (isUserHasProfile([PROFILES.gerenteRiesgoCredito, PROFILES.ceo])) {
        setAnalistasOptions([...analistasToOptions(response.results), { label: 'No Asignado', value: null }])
      }
      else {
        setAnalistasOptions(analistasToOptions(response.results))
      }
    });

    setPendientes(data.count);
  }, [data]);

  const adquirirCambios = (movId, analistaId) => {
    const index = arrayAnalistas.map((object) => object.id).indexOf(movId);
    if (index === -1) {
      setArrayAnalistas([...arrayAnalistas, { id: movId, analista: analistaId }]);
    } else {
      const newArr = [...arrayAnalistas];
      newArr[index].analista = analistaId;
      setArrayAnalistas(newArr);
    }
  };

  const eliminarCambios = (disable, movId) => {
    if (!disable) {
      setArrayAnalistas(arrayAnalistas.filter((item) => item.id !== movId));
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const updateAnalista = (objects) => {
    const newArr = objects.map((object) => ({ id: object.id, analista_id: object.analista.value }));
    patch(`${BASE_URL}${API.riskInbox}/asignar-analista/`, newArr).then(() => {
      setArrayAnalistas([]);
      setModalOpen(true);
      getData("");
    });
  };

  const getAnalista = (row) => {
    const index = arrayAnalistas.map((object) => object.id).indexOf(row.original.id);
    if (index !== -1) {
      return arrayAnalistas[index].analista;
    }
    if (row.original.analista) {
      return {
        label: `${row.original.analista.user.first_name} ${row.original.analista.user.last_name}`,
        value: row.original.analista.id,
      };
    }
    return null;
  };

  const isChecked = (row) => {
    const index = arrayAnalistas.map((object) => object.id).indexOf(row.original.id);
    console.log({ index, row, arrayAnalistas });
    if (index !== -1) {
      return true;
    }
    return false;
  };



  const abrirModalReevaluacion = (row) => {
    setMovimientoIDReevaluacion(row.original.id);
    setUserIDReevaluacion(user.sub);
    setOpenReevaluacionModal(true);
    setNombreAsegurado(row.original.asegurado_nombre);
    setNombreDeudor(row.original.nombre_deudor);
  }

  const columns = [
    {
      Header: headerOperation("ID", "left"),
      id: "id__icontains",
      accessor: "id",
      Cell: (row) => cellOperation(row.value),
      minWidth: 70,
    },
    {
      Header: headerDoubleRowOperation("Tipo de", "solicitud"),
      id: "tipo__descripcion__icontains",
      accessor: "tipo_descripcion",
      Cell: (row) => cellOperation(row.value, "left"),
      minWidth: 110,
    },
    {
      Header: headerOperation("Asegurado", "left"),
      id: "linea_credito__cliente",
      accessor: "asegurado_nombre",
      Cell: (row) => cellOperation(row.value, "left"),
      minWidth: 190,
    },
    {
      Header: headerOperation("Póliza", "left"),
      id: "poliza",
      accessor: "poliza",
      Cell: (row) => cellOperation(row.value),
      minWidth: 120,
    },
    {
      Header: headerOperation("Nombre deudor", "left"),
      id: "razon",
      accessor: "nombre_deudor",
      Cell: (row) =>
        cellOperation(
          <Link
            to={`/solicitudes/posicion-deudor/${row.original.id_deudor}`}
            className="table-link"
          >
            {row.value}
          </Link>,
          "left",
        ),
      minWidth: 190,
    },
    {
      Header: headerOperation("RUT deudor", "left"),
      id: "rut",
      accessor: "rut_deudor",
      Cell: (row) => cellOperation(formatRut(row.value), "left"),
      minWidth: 130,
    },
    {
      Header: headerOperation("Tipo Solicitante", "left"),
      id: "tipo_solicitante",
      accessor: "solicitante.organization.is_main",
      Cell: (row) => cellOperation(row.value == null && '-' || (row.value ? 'Interno' : 'Externo')),
      minWidth: 150,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation("Solicitante", "left"),
      id: "solicitante",
      accessor: "solicitante",
      Cell: (row) => cellOperation(row.value ? `${row.value.user.first_name} ${row.value.user.last_name}` : '-'),
      minWidth: 150,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation("Moneda", "póliza"),
      id: "moneda_poliza",
      accessor: "moneda_poliza",
      Cell: (row) => cellOperation(row.value),
      minWidth: 90,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation("Techo", "vigente"),
      id: "monto_maximo_deudor",
      accessor: "monto_maximo_deudor",
      Cell: (row) => cellNumOperation(row.value, "right"),
      minWidth: 100,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation("Cúmulo", "vigente"),
      id: "cumulo_vigente",
      accessor: "cumulo_vigente",
      Cell: (row) => cellNumOperation(row.value, "right"),
      minWidth: 100,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation("Línea", "solicitada"),
      id: "monto_solicitado__icontains",
      accessor: "linea_solicitada",
      Cell: (row) => cellNumOperation(row.value, "right"),
      minWidth: 120,
    },
    {
      Header: headerOperation("Clasificación", "left"),
      id: "nominado",
      accessor: "nominado",
      Cell: (row) => cellOperation(row.value, "left"),
      minWidth: 130,
    },
    {
      Header: headerDoubleRowOperation("Fecha", "solicitud"),
      id: "fecha_solicitud",
      accessor: "fecha_solicitud",
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1), "left"),
      minWidth: 100,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation("Días", "de espera"),
      id: "dias_espera",
      accessor: "dias_espera",
      Cell: (row) => cellOperation(row.value, "right"),
      minWidth: 100,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Decisión', 'motor'),
      id: 'decision_bypass_monto',
      accessor: 'decision_bypass_monto',
      Cell: (row) =>
        cellOperation(
          <div>
            <Icon
              id={`decision_bypass_monto-icon-${row.original.id}`}
              tooltipText={`Decisión motor: ${row.original.decision_bypass_monto}` || '-'}
            >
              <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#315E90' }} />
            </Icon>
            {(row.original.razones_motor_riesgo.length > 0 && (
              <Icon
                id={`razones_motor_riesgo-icon-${row.original.id}`}
                autohide={false}
                tooltipText={(
                  <>
                    <div>Razones:</div>
                    {(
                      row.original.razones_motor_riesgo.map((razon, index) => (
                        <li key={`${index + 1}`} style={{ justifyContent: 'center' }}>
                          {razon}
                        </li>
                      ))
                    )}
                  </>
                )}
              >
                <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#315E90' }} />
              </Icon>
            ))}
          </div>,
        ),
      minWidth: 140,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation("Solicitar", "Reevaluación"),
      id: "reevaluacion",
      Cell: (row) => cellOperation(
        <Button onClick={() => abrirModalReevaluacion(row)} className="table-link download">
          <FontAwesomeIcon icon={faRedo} />
        </Button>,
        "center",
      ),
      sortable: false,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation("Observación", "asegurado"),
      id: "observacion_asegurado",
      accessor: "observacion_asegurado",
      Cell: (row) =>
        cellOperation(
          <Icon id={`obs-${row.original.id}`} tooltipText={row.value}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Icon>,
          "center",
        ),
      minWidth: 120,
      sortable: false,
      filterable: false,
    },
    {
      Header: headerOperation("Analista", "left"),
      id: "analista_id",
      fixed: "right",
      accesor: "analista",
      // eslint-disable-next-line
      Filter: ({ filter, onChange }) => (
        <>
          <FilterSelect
            filter={filter}
            defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
            onFilterChange={onChange}
            options={analistasFilter}
          />
          <Button
            block
            className="btn-orsan"
            style={{ padding: 0 }}
            onClick={() => updateAnalista(arrayAnalistas)}
            // arrayAnalistas.length
            disabled={!isUserHasProfile(INBOX_VIEW_WRITE) || (arrayAnalistas.length === 0)}
          >
            Asignar
          </Button>
        </>
      ),
      Cell: (i) => (
        <CheckboxDropdown
          analista={getAnalista(i)}
          analistas={analistasOptions}
          agregar={adquirirCambios}
          quitar={eliminarCambios}
          movId={i.original.id}
          checked={isChecked(i)}
        />
      ),
      minWidth: 220,
      sortable: false,
    },
    {
      Header: headerOperation("Resolver", "left"),
      id: "resolver",
      fixed: "right",
      sortable: false,
      accessor: "id",
      Cell: (row) => (
        cellOperation(
          isUserHasProfile(INBOX_VIEW_WRITE) ? (
            <Link to={`/solicitudes/inbox-riesgos/${row.value}`} className="table-link">
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          ) : <></>,
        )
      ),
      minWidth: 90,
      filterable: false,
    },
  ];

  return (
    <div style={{ margin: "25px 0 40px 0" }} className='table-bandeja-entrada'>
      <BaseTable
        columns={columns}
        data={data.data}
        resizable={false}
        loading={loading}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
        pages={Math.ceil(data.count / DEFAULT_PAGE_SIZE_SMALL)}
        pageSize={DEFAULT_PAGE_SIZE_SMALL}
        resultCount={data.count}
        hasFixedColumns
        showPagination
        filterable
        sortable
        style={{ maxHeight: "63vh" }}
      />
      <CheckModal
        isOpen={modalOpen}
        toggle={toggleModal}
        handleOk={toggleModal}
        okBtnText="OK"
        confirmMsg="Los cambios se han realizado correctamente."
      />
      <SolicitudReevaluacionModal
        openReevaluacionModal={openReevaluacionModal}
        setOpenReevaluacionModal={setOpenReevaluacionModal}
        movimientoIDReevaluacion={movimientoIDReevaluacion}
        userIDReevaluacion={userIDReevaluacion}
        nombreAsegurado={nombreAsegurado}
        nombreDeudor={nombreDeudor}
      />
    </div>
  );
};

InboxRiesgoMovimientosTable.propTypes = {
  setPendientes: PropTypes.func.isRequired,
}

export default InboxRiesgoMovimientosTable;
