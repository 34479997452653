import React from 'react';
import PropTypes from 'prop-types';
import LineChart from 'components/UI/LineChart';

const PosicionDeudorEvolucionVentas = ({ evolucionVentas }) => {
  const getYear = (date) => date.split('-')[0];
  const getMonth = (date) => parseInt(date.split('-')[1], 10);

  const labels = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
  const datasets = [];
  const years = [];
  const data = {};

  const checkEmpty = (array) => array.every((element) =>
  element == null);

  if (evolucionVentas && evolucionVentas.length > 0){
    evolucionVentas.forEach(venta => {
      if (!data[`${getYear(venta.mes)}`]){
        data[`${getYear(venta.mes)}`] = {};
        years.push(`${getYear(venta.mes)}`);
      }

      data[`${getYear(venta.mes)}`][`${getMonth(venta.mes)}`] = venta.ventas
    }) 
    
    years.forEach(year => {
      const dataValues = new Array(labels.length);
      labels.forEach(month => {
        dataValues[labels.indexOf(month)] = data[year][labels.indexOf(month) + 1] || null;
      })
      if (!checkEmpty(dataValues)){
          datasets.push({
          label: `${year}`,
          data: dataValues,
        });
      }
    });
  }
  else {
    datasets.push({label: 'NO INFO', data: []})
  }

  return (
    <div style={{ margin: '0 auto', width: '70%' }}>
      <LineChart labels={labels} datasets={datasets} />
    </div>
  );
};

PosicionDeudorEvolucionVentas.propTypes = {
  evolucionVentas: PropTypes.arrayOf(
    PropTypes.shape({
      mes: PropTypes.string,
      ventas: PropTypes.number,
      margen: PropTypes.number,
      impuesto: PropTypes.number,
      fecha_declaracion: PropTypes.string,
    }),
  ).isRequired,
};

export default PosicionDeudorEvolucionVentas;
