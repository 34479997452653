import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, FormGroup, Label, Input } from 'reactstrap';

import { get } from 'helpers/requests';
import { BASE_URL, API } from 'CONST';
import { formatRut, fdate } from '../../../helpers/helpers';
import '../../../styles/deudor.css';

const PosicionDeudorInfo = ({ datos }) => {
  const [rubro, setRubro] = useState('---');
  const estados = {
    'vigente': 'Vigente',
    'bloqueado': 'Bloqueado',
  }

  useEffect(() => {
    if (datos && datos.rubro_id) {
      get(`${BASE_URL}${API.actividadEconomica}/${datos.rubro_id}`).then((response) => {
        setRubro(response.descripcion);
      });
    }
  }, [datos]);

  const getInput = (input) => input || '---';

  return (
    <Row>
      <Col>
        <FormGroup row>
          <Label sm={5} for="rutDeudor">
            RUT Deudor
          </Label>
          <p id="rutDeudor">{formatRut(datos.rut)}</p>
        </FormGroup>
        <FormGroup row style={{ flexWrap: 'nowrap' }}>
          <Label sm={5} for="razonSocial">
            Razón Social
          </Label>
          <p id="razonSocial">{datos.name}</p>
        </FormGroup>
        <FormGroup row>
          <Label sm={5} for="estadoDeudor">
            Estado Deudor
          </Label>
          <p id="estadoDeudor">{estados[datos.estado]}</p>
        </FormGroup>
        <FormGroup row>
          <Label sm={5} for="fechaInicio">
            Fecha inicio actividades
          </Label>
          <p id="fechaInicio">{getInput(fdate(datos.inicio_actividad))}</p>
        </FormGroup>
        <FormGroup row style={{ flexWrap: 'nowrap' }}>
          <Label sm={5} for="actividad">
            Actividad
          </Label>
          <p id="actividad">
            {getInput(datos.actividad && datos.actividad.map((actividad) => actividad.actividad_economica).join(', '))}
          </p>
        </FormGroup>
        <FormGroup row>
          <div className="scoremotor scoremotor-col scoremotor-text">Score motor</div>
          <div className="scoremotor scoremotor-number">{getInput(datos.score)}</div>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup row style={{ flexWrap: 'nowrap' }}>
          <Label sm={5} for="rubro">
            Rubro
          </Label>
          <p id="rubro">{rubro}</p>
        </FormGroup>
        <FormGroup row>
          <Label sm={5} for="tamanno">
            Tamaño
          </Label>
          <p id="tamanno">{getInput(datos.tamano)}</p>
        </FormGroup>
        <FormGroup row>
          <Label sm={5} for="emailContacto">
            Email contacto
          </Label>
          <p id="emailContacto">{getInput(datos.email)}</p>
        </FormGroup>
        <FormGroup row>
          <Label sm={5} for="telefonoContacto">
            Teléfono contacto
          </Label>
          <p id="telefonoContacto">{getInput(datos.phone)}</p>
        </FormGroup>
        <FormGroup row style={{ flexWrap: 'nowrap' }}>
          <Label sm={5} for="nombreContacto">
            Nombre Contacto
          </Label>
          <p id="nombreContacto">{getInput(datos.contacto)}</p>
        </FormGroup>
        <FormGroup row>
          <Label sm={5} for="evaluacion">
            Evaluación
          </Label>
          <Col sm={7} style={{ paddingLeft: 0 }}>
            <Input id="evaluacion" type="text" />
          </Col>
        </FormGroup>
      </Col>
    </Row>
  );
};

PosicionDeudorInfo.propTypes = {
  datos: PropTypes.shape({
    rut: PropTypes.string,
    name: PropTypes.string,
    estado: PropTypes.string,
    inicio_actividad: PropTypes.string,
    actividad: PropTypes.arrayOf(
      PropTypes.object,
    ),
    rubro_id: PropTypes.string,
    tamano: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    contacto: PropTypes.string,
    score: PropTypes.number,
  }).isRequired,
};

export default PosicionDeudorInfo;
