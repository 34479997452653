import React from 'react';

import AseguradosPolizasTable from './AseguradosPolizasTable';
import PageContainer from '../../PageContainer';

const AseguradosPolizas = () => (
  <PageContainer
    breadcrumbs={[
      { id: 1, name: 'PÓLIZAS', url: '/polizas/asegurados-polizas' },
      { id: 2, name: 'ASEGURADOS-PÓLIZAS' },
    ]}
  >
    <AseguradosPolizasTable />
  </PageContainer>
);

export default AseguradosPolizas;
