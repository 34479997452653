import { CLEAN_DATA, LOGIN_SUCCESS, TOKEN_ERROR } from '../constants/action-types';
import auth from '../../helpers/auth';

export function alertModal(payload) {
  return { type: TOKEN_ERROR, payload };
}

export function loginSuccess(payload) {
  return { type: LOGIN_SUCCESS, payload };
}

// --------------------------------------------------------------------------

const handleError = (payload, props) => ({ type: 'ERROR', payload, error_type: props.type });

const writeData = (object, method, dispatch) => {
  const promise = auth.fetch(object.url, {
    method,
    body: JSON.stringify(object.config),
    headers: { 'Content-Type': 'application/json' },
  });

  if (object.unmanaged) {
    return promise;
  }

  promise
    .then((response) => {
      dispatch({ type: object.type, payload: response });
    })
    .catch((error) => {
      if (object.onFailedReq) {
        object.onFailedReq();
      }
      if (error.status) {
        if (error.status !== 503) {
          return error.json().then((errorData) => dispatch(handleError(errorData, object)));
        }
      }
      return dispatch(handleError(error, object));
    })
    .finally(() => {
      if (object.callback) {
        object.callback();
      }
    });
  return null;
};

export const cleanData = (payload) => ({ type: CLEAN_DATA, payload });

export const getData = (object) => (dispatch) => {
  const promise = auth.fetch(object.url);

  if (object.unmanaged) {
    return promise;
  }

  promise
    .then((response) => dispatch({ type: object.type, payload: response }))
    .catch((error) => {
      if (error.status !== 503) {
        return error.json().then((errorData) => dispatch(handleError(errorData, object)));
      }
      return dispatch(handleError(error, object));
    });
  return null;
};

export const postData = (object) => (dispatch) => writeData(object, 'POST', dispatch);

export const patchData = (object) => (dispatch) => writeData(object, 'PATCH', dispatch);

export const putData = (object) => (dispatch) => writeData(object, 'PUT', dispatch);

export const uploadFile = (object) => (dispatch) => {
  auth
    .fetch(object.url, {
      method: 'PUT',
      body: object.config.body,
      headers: {
        'Content-Disposition': `attachment; filename="${encodeURI(object.config.name)}"`,
      },
    })
    .then((response) => dispatch({ type: object.type, payload: response }))
    .catch((error) => error.json().then((errorData) => dispatch(handleError(errorData, object))));
};

export const deleteData = (object) => (dispatch) => {
  const promise = auth.fetch(object.url, {
    method: 'DELETE',
    body: '',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (object.unmanaged) {
    return promise;
  }

  promise.then(() => dispatch({ type: object.type, payload: object.id }));
  return null;
};
