import { useState, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { get } from 'helpers/requests';
import { BACKEND_DF, calculatePages, SEARCH_DELAY_MS } from 'CONST';

const cleanParam = (param) => param.replace('__icontains', '');

const useTableFilters = (apiUrl, disabled = false, params = '', dateFields = []) => {
  const [filtering, setFiltering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [], pages: 0, count: 0 });

  const getData = (filterParams) => {
    setLoading(true);
    get(`${apiUrl}?${filterParams}`)
      .then((resp) =>
        setData({
          data: resp.results,
          pages: calculatePages(resp.count),
          count: resp.count,
        }),
      )
      .finally(() => setLoading(false));
  };

  const fetchData = (state) => {
    if (disabled) {
      setData({ data: [], pages: 0, count: 0 });
      return;
    }

    setFiltering(false);
    let filterParams;
    if (state.newParams) {
      filterParams = `${state.newParams}&page=${state.page + 1}&`;
    } else {
      filterParams = params ? `${params}&page=${state.page + 1}&` : `page=${state.page + 1}&`;
    }
    if (state.sorted && state.sorted.length) {
      state.sorted.forEach((node) => {
        const param = cleanParam(node.id);
        if (node.desc) {
          filterParams = `${filterParams}ordering=-${param}&`;
        } else {
          filterParams = `${filterParams}ordering=${param}&`;
        }
      });
    }
    if (state.filtered && state.filtered.length) {
      state.filtered.forEach((node) => {
        let validFilter = false;
        let filterValue = node.value;

        // Si node.value es -1 se asume que se trata de "Todos" del FilterSelect
        if (typeof node.value === 'number' && node.value > 0) {
          validFilter = true;
        } else if (
          typeof node.value === 'string' &&
          node.value.length > 2 &&
          node.value !== 'all-options'
        ) {
          validFilter = true;

          const dateField = dateFields.find((field) => field.id === node.id);
          if (dateField) {
            const date = moment(filterValue, dateField.format, true);
            if (date.isValid()) {
              filterValue = date.format(BACKEND_DF);
            } else {
              validFilter = false;
            }
          }
        }
        if (validFilter) {
          filterParams = `${filterParams}${node.id}=${filterValue}&`;
        }
      });
    }
    getData(filterParams);
  };

  const debounce = useCallback(
    _.debounce((tableState) => fetchData(tableState), SEARCH_DELAY_MS),
    [],
  );

  const onFetchData = (tableState) => {
    if ((tableState.filtered && tableState.filtered.length) || filtering) {
      return debounce(tableState);
    }
    return fetchData(tableState);
  };

  const onFilteredChange = () => setFiltering(true);

  return [onFilteredChange, onFetchData, loading, data, getData];
};

export default useTableFilters;
