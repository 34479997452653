import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { getRandomColors } from 'helpers/colors'

const LineChart = ({ labels, datasets }) => {
  const colors = getRandomColors(datasets.length);
  const data = {
    labels,
    datasets,
  };

  data.datasets = data.datasets.map((element, index) => ({
    ...element,
    borderColor: colors[index],
    backgroundColor: colors[index],
  }));
  
  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <Line data={data} />
    </div>
  );
};

LineChart.propTypes = {
    labels: PropTypes.arrayOf(
        PropTypes.string,
    ),
    datasets: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            data: PropTypes.arrayOf(PropTypes.number),
        })
    ),
};

LineChart.defaultProps = {
    labels: [],
    datasets: [],
};

export default LineChart;
