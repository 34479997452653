import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardText,
  List,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ComentarioResolucionTable from "components/resolucion/ComentarioResolucionTable";

const options = [
  {
    key: "comentario_analista",
    title: "Comentario Analista"
  },
  {
    key: "comentario_asegurado",
    title: "Comentario Asegurado"
  },
  {
    key: "motivo",
    title: "Motivo Evaluación Motor"
  },
  {
    key: "observacion",
    title: "Observacion"
  },
  {
    key: "comentarios",
    title: "Comentarios para Movimiento de Línea"
  },
];


const ComentariosModal = ({ movimiento, openModal, toggleModal }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    setSelectedOption(options[0])
  }, [movimiento])

  const renderCases = () => {
    switch (selectedOption.key) {
      case "comentarios":
        return selectedOption.key === "comentarios" && movimiento[selectedOption.key].length ?
          <ComentarioResolucionTable data={movimiento.comentarios} /> : (
            <Card body style={{ maxHeight: "500px", overflowY: "scroll" }}>
              <CardText>No hay comentarios.</CardText>
            </Card>
          );
      case "motivo":
        return (
          <Card body style={{ maxHeight: "500px", overflowY: "scroll" }}>
            <CardText>{movimiento[selectedOption.key]}</CardText>
            {movimiento.razones_motor_riesgo.length ? (
              <List>
                {movimiento.razones_motor_riesgo.map((razonMotor) => <li key={razonMotor}>{razonMotor}</li>)}
              </List>
            ) : null}
          </Card>
        );
      default:
        return (
          <Card body style={{ maxHeight: "500px", overflowY: "scroll" }}>
            <CardText>{movimiento[selectedOption.key] === null || movimiento[selectedOption.key] === "" ? "No hay comentarios." : movimiento[selectedOption.key]}</CardText>
          </Card>
        );
    }
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        style={{ maxWidth: "2000px", width: "90%", height: "90%" }}
        toggle={toggleModal}
        type="modal-warning warning-container"
      >
        <ModalHeader toggle={toggleModal}>
          Comentarios
        </ModalHeader>
        <ModalBody style={{ display: "flex", flexDirection: "column", gap: "15px", margin: "0 25px" }}>
          <div style={{ display: "flex", justifyContent: "flex-start", gap: "15px" }}>
            {options.map((option) => (
              <Button
                className={selectedOption.key === option.key ? 'btn-orsan-outline' : 'btn-orsan'}
                key={option.key}
                onClick={() => setSelectedOption(option)}
              // disabled={movimiento[option.key] === null}
              >
                {option.title}
              </Button>
            ))}
          </div>
          {renderCases()}

        </ModalBody>
      </Modal>
    </>
  );
};

ComentariosModal.propTypes = {
  movimiento: PropTypes.shape({
    id: PropTypes.number,
    comentarios: PropTypes.arrayOf(
      PropTypes.any,
    ),
    razones_motor_riesgo: PropTypes.arrayOf(
      PropTypes.any,
    ),
    comentario_analista: PropTypes.string,
    comentario_asegurado: PropTypes.string,
  }),
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,

};

ComentariosModal.defaultProps = {
  movimiento: {
    id: null,
    linea_credito: null,
  },
};


export default ComentariosModal;