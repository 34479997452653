import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import BaseModal from "../UI/BaseModal";


const CarpetaResolucionModal = ({ isOpen, toggle, handleOk, loading}) => (
  <div>
    <BaseModal
      handleModal={toggle}
      modalOpen={isOpen}
      type="check-modal"
    >
      <div id="inline-block-container" className="modal-check text">
        <div id="inline-block-icon" className="icon-container">
          <FontAwesomeIcon icon={faCheckCircle} color="green" />
        </div>
        <div id="inline-block-message" className="check-modal-text">
          Carpeta tributaria actualizada exitosamente.
          <br />
          <br />
          Puede que la resolución no esté disponible mientras se genera el reporte consolidado.
          <br />
          De ser así, espere unos momentos y recargue la página nuevamente.
        </div>
      </div>
      <div className="button-container">
        <div>
          <Button
            className="warning-accept"
            disabled={loading}
            onClick={() => handleOk()}
          >
            Ok
          </Button>
        </div>
      </div>
    </BaseModal>
  </div>
);

CarpetaResolucionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CarpetaResolucionModal.defaultProps = {
  loading: false,
};

export default CarpetaResolucionModal;
