import React from 'react';
import PropTypes from 'prop-types';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../../UI/Icon';


const PosicionDeudorLegendModal = ({ openModal, toggleModal}) => (
  <Modal
    isOpen={openModal}
    style={{maxWidth: '400px', width: '90%'}}
    toggle={toggleModal}
    type="modal-warning warning-container"
  >
    <ModalHeader toggle={toggleModal}>
      Leyenda
    </ModalHeader>
    <ModalBody>
      <ul>
        <ul>
          <Icon 
            id='leyenda-comentario-analista' 
            tooltipText='Ver Comentarios y Motivos'
          >
            <FontAwesomeIcon icon={faExclamationCircle} style={{color: '#0065FF'}} />
          </Icon>
          <span>
            : Comentario Analista
          </span>
        </ul>
        <ul>
          <Icon 
            id='leyenda-comentario-asegurado' 
            tooltipText='Ver Comentarios y Motivos'
          >
            <FontAwesomeIcon icon={faExclamationCircle} style={{color: '#FFA900'}} />
          </Icon>
          <span>
            : Comentario Asegurado
          </span>
        </ul>
        <ul>
          <Icon 
            id='leyenda-motivo' 
            tooltipText='Ver Comentarios y Motivos'
          >
            <FontAwesomeIcon icon={faExclamationCircle} style={{color: '#EB0A00'}} />
          </Icon>
          <span>
            : Motivo Evaluación Motor
          </span>
        </ul>
      </ul>
    </ModalBody>
  </Modal>
);

PosicionDeudorLegendModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default PosicionDeudorLegendModal;
