import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import useTableFilters from 'helpers/useTableFilters';
import BaseTable from '../../../shared/BaseTable';
import SafeBtn from '../../../shared/SafeBtn';

import { API, BASE_URL, FRONT_DF_1 } from '../../../../CONST';
import { fdate, formatRut, fileDownload } from '../../../../helpers/helpers';
import {
  headerOperation,
  cellNumOperation,
  cellOperation,
  headerDoubleRowOperation,
} from '../../../../helpers/tables';

const AseguradosDetalleTable = () => {
  const { id } = useParams();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const xlsxUrl = `${BASE_URL}${API.balanceCredito}download_deudores/`;
  const [onFilteredChange, onFetchData, loading, data] = useTableFilters(
    `${BASE_URL}${API.balanceCredito}`,
    false,
    `linea_credito__poliza_id=${id}`,
    [
      { id: 'fecha_respuesta', format: FRONT_DF_1 },
      { id: 'fecha_solicitud', format: FRONT_DF_1 },
      { id: 'fecha_vigencia', format: FRONT_DF_1 },
    ],
    0,
  );

  const columns = [
    {
      Header: headerOperation('ID', 'left'),
      id: 'linea_credito__id',
      accessor: 'linea_credito.id',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 40,
    },
    {
      Header: headerOperation('Razón social', 'left'),
      id: 'razon',
      accessor: 'nombre_deudor',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 100,
    },
    {
      Header: headerOperation('RUT', 'left'),
      id: 'rut',
      accessor: 'rut_deudor',
      Cell: (row) => cellOperation(formatRut(row.value), 'left'),
      minWidth: 65,
      sortable: false,
    },
    {
      Header: headerOperation('Moneda', 'left'),
      id: 'moneda',
      accessor: 'moneda_poliza',
      Cell: (row) => cellOperation(row.value),
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Clasificación', 'left'),
      id: 'clasificacion',
      accessor: 'nominado',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 65,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Monto solicitado', 'left'),
      id: 'monto_solicitado',
      accessor: 'linea_solicitada',
      Cell: (row) => cellNumOperation(row.value, 'right'),
      minWidth: 80,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Monto aprobado', 'left'),
      id: 'monto_aprobado',
      accessor: 'monto_otorgado',
      Cell: (row) => cellNumOperation(row.value, 'right'),
      minWidth: 80,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Estado línea', 'left'),
      id: 'estado_linea',
      accessor: 'linea_credito.estado.nombre',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 80,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'resolución'),
      id: 'fecha_respuesta',
      accessor: 'fecha_respuesta',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1), 'center'),
      minWidth: 70,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'inicio vigencia'),
      id: 'fecha_solicitud',
      accessor: 'fecha_solicitud',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1), 'center'),
      minWidth: 80,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'término vigencia'),
      id: 'fecha_vigencia',
      accessor: 'fecha_vigencia',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1), 'center'),
      minWidth: 85,
    },
  ];

  const handleDownload = () => {
    fileDownload(xlsxUrl, id, 'GET', null, () => setLoadingBtn(false));
    setLoadingBtn(!loadingBtn);
  };

  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <Row style={{ margin: '1rem' }}>
        <Col style={{ fontSize: '25px' }}>Listado de deudores</Col>
        <Col sm={{ size: 2, offset: 8 }}>
          <Row style={{ padding: '5px 0px' }}>
            <SafeBtn
              onClick={() => handleDownload()}
              block
              className='btn-orsan'
              disabled={loadingBtn}
              loading={loadingBtn}
            >
              Descargar listado
            </SafeBtn>
          </Row>
        </Col>
      </Row>
      <BaseTable
        columns={columns}
        data={data.data}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
        pages={data.pages}
        resultCount={data.count}
        loading={loading}
        filterable
        sortable
        style={{ maxHeight: '35vh' }}
      />
    </div>
  );
};

export default AseguradosDetalleTable;
