import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { get } from "helpers/requests";
import DownloadBtn from "components/shared/DownloadBtn";
import { BASE_URL, API, FRONT_DF_1 } from "CONST";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faComments } from "@fortawesome/free-solid-svg-icons";
import Icon from "../../UI/Icon";
import { getIconInfo } from "../../../helpers/helpersMotor";
import {
  headerOperation,
  headerDoubleRowOperation,
  cellOperation,
  cellNumOperation,
} from "../../../helpers/tables";
import { fdate } from '../../../helpers/helpers';
import BaseTable from "../../shared/BaseTable";
import PosicionDeudorComentariosModal from './posicionDeudorComentariosModal';


const PosicionDeudorHistoryModal = ({ openModal, toggleModal, linea }) => {
  const [movimientos, setMovimientos] = useState();
  const [comentarios, setComentarios] = useState([]);
  const [openComentariosModal, setOpenComentariosModal] = useState(false);
  const [movimientoIdComentarios, setMovimientoIdComentarios] = useState();

  useEffect(() => {
    if (linea && linea.id){
      get(`${BASE_URL}${API.movimientoLineaCredito}?linea_credito=${linea.id}`).then((response) =>
        setMovimientos(response && response.results)
      );
    }
  }, [linea]);

  const clearState = () => {
    setMovimientos();
  };

  const onCloseModal = () => {
    clearState();
    toggleModal();
  };

  const closeComentariosModal = () => {
    setOpenComentariosModal(false);
  };

  const handleComentariosModal = (row) => {
    setMovimientoIdComentarios(row.original.id)
    setComentarios(row.original.comentarios);
    setOpenComentariosModal(true);
  };

  const columns = [
    {
      Header: headerOperation("ID"),
      id: "id",
      accessor: "id",
      Cell: (row) => cellOperation(row.value),
      minWidth: 80,
      sortable: false,
    },
    {
      Header: headerOperation("Tipo"),
      id: "tipo_data",
      accessor: i => cellOperation(i.is_migration ? (
        <div>
          {i.tipo_data.descripcion}
          <Icon
            id={`check-${i.id}`}
            placement="right"
            tooltipText="Este movimiento fue cargado en una migración histórica. Dirigirse al sitio de Credex para buscar el historial previo."
          >
            <FontAwesomeIcon style={{color: "#315E90"}} icon={faInfoCircle} />
          </Icon>
        </div>
        ) : i.tipo_data.descripcion,
        "center"
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation("Monto", "Solicitado (UF)"),
      id: "monto_solicitado",
      accessor: "monto_solicitado",
      Cell: (row) => cellNumOperation(row.value),
      minWidth: 100,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation("Monto", "Otorgado (UF)"),
      id: "monto_otorgado",
      accessor: "monto_otorgado",
      Cell: (row) => cellNumOperation(row.value),
      minWidth: 100,
      sortable: false,
    },
    {
      Header: headerOperation("Evaluación"),
      id: "estado__descripcion",
      accessor: "estado.descripcion",
      Cell: (row) => getIconInfo(row),
      minWidth: 180,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation("Motivo"),
      id: "motivo",
      accessor: "motivo",
      Cell: (row) => cellOperation(row.value, "left"),
      minWidth: 180,
      sortable: false,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'solicitud'),
      id: 'fecha_solicitud',
      accessor: 'fecha_solicitud',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1)),
      minWidth: 125,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'resolución'),
      id: 'fecha_resolucion',
      accessor: 'fecha_respuesta',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1)),
      minWidth: 125,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha inicio', 'cobertura'),
      id: 'fecha_inicio_cobertura',
      accessor: 'fecha_vigencia',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1)),
      minWidth: 125,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Fin de', 'cobertura'),
      id: 'fecha_fin_cobertura',
      accessor: 'fecha_fin_vigencia',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1)),
      minWidth: 125,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation("Observaciones"),
      id: "observacion",
      accessor: "observacion",
      Cell: (row) => cellOperation(row.value),
      minWidth: 100,
      sortable: false,
    },
    {
      Header: headerOperation("Comentarios"),
      id: "comentarios",
      accessor: "comentarios",
      Cell: (row) => cellOperation(row.value.length > 0 && (
        <Button
          id='comentarios'
          className="table-link"
          onClick={() => handleComentariosModal(row)}
        >
          <FontAwesomeIcon icon={faComments} />
        </Button>
      )),
      minWidth: 100,
      sortable: false,
    },
  ]

  return (
    <>
      <Modal
        isOpen={openModal}
        style={{maxWidth: "2000px", width: "90%"}}
        toggle={onCloseModal}
        type="modal-warning warning-container"
      >
        <ModalHeader toggle={onCloseModal}>
          {`Historial de movimientos para Línea ID ${linea.id}`}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-end mb-2">
            <DownloadBtn
              id="xlsx-download"
              text="XLSX"
              tipText="Descargar Últimos Estados"
              url={`${BASE_URL}${API.movimientoLineaCredito}historial-movimientos/xlsx?linea_credito=${linea.id}`}
            />
          </div>
          <BaseTable
            columns={columns}
            data={movimientos}
            hasFixedColumns
          />
        </ModalBody>
      </Modal>
      <PosicionDeudorComentariosModal
        openModal={openComentariosModal}
        closeModal={closeComentariosModal}
        comentarios={comentarios}
        movimientoIdComentarios={movimientoIdComentarios}
      />
    </>
  );
};

PosicionDeudorHistoryModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  linea: PropTypes.shape({
    id: PropTypes.number,
  }),
};

PosicionDeudorHistoryModal.defaultProps = {
  linea: {
    id: null,
  },
};

export default PosicionDeudorHistoryModal;
