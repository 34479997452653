import { PROFILES } from 'CONST';

// Bandeja de Entrada
export const INBOX_VIEW_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteRiesgoCredito,
  PROFILES.analistaRiesgoCredito,
];
export const INBOX_VIEW_READ = [
  ...INBOX_VIEW_WRITE,
  PROFILES.administrativoRiesgoCredito,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
];

// Posición Deudor
export const POSICION_DEUDOR_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteRiesgoCredito,
  PROFILES.analistaRiesgoCredito,
  PROFILES.actuariado,
];
export const POSICION_DEUDOR_READ = [
  ...POSICION_DEUDOR_WRITE,
  PROFILES.administrativoRiesgoCredito,
  PROFILES.consultaEspecial,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.comercialCredito,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
];

// Asegurados Pólizas
export const ASEGURADOS_POLIZAS_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteRiesgoCredito,
  PROFILES.analistaRiesgoCredito,
  PROFILES.actuariado,
];
export const ASEGURADOS_POLIZAS_READ = [
  ...ASEGURADOS_POLIZAS_WRITE,
  PROFILES.comercialCredito,
  PROFILES.administrativoRiesgoCredito,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
];

// Resoluciones
export const RESOLUCIONES_WRITE = [
  ...INBOX_VIEW_WRITE,
  ...POSICION_DEUDOR_WRITE,
];
