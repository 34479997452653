import React from 'react';

import PageContainer from '../PageContainer';
import ResolucionForm from './ResolucionForm';

const Resolucion = () => (
  <PageContainer
    breadcrumbs={[
      { id: 1, name: 'SOLICITUDES', url: '/solicitudes/inbox-riesgos' },
      { id: 2, name: 'RESOLUCIONES' },
    ]}
  >
    <ResolucionForm />
  </PageContainer>
);

export default Resolucion;
