import React from 'react';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import 'styles/tables.css';

const ReactTableWithFixedColumns = withFixedColumns(ReactTable);

const shouldShowPagination = (showPagination, resultCount, pageSize) => {
  if (showPagination !== undefined ? showPagination : true) {
    return resultCount > pageSize;
  }
  return false;
};

const BaseTable = ({
  className,
  columns,
  data,
  defaultFilterMethod,
  defaultSorted,
  defaultSortDesc,
  filterable,
  filtered,
  filteredArgs,
  hasFixedColumns,
  loading,
  onFetchData,
  onFilteredChange,
  onPageChange,
  pages,
  resizable,
  resultCount,
  showPagination,
  sortable,
  style,
  tableRef,
}) => {
  const pageSize = 20;

  let TableComponent = ReactTable;
  if (hasFixedColumns)
    TableComponent = ReactTableWithFixedColumns;

  return (
    <TableComponent
      manual
      className={`-highlight ${className || ''}`}
      loadingText="Cargando..."
      pageSize={pageSize}
      defaultPageSize={10}
      minRows={1}
      pageText="Página"
      previousText="Anterior"
      nextText="Siguiente"
      noDataText="Sin resultados"
      rowsText="Filas"
      multiSort={false}
      showPageSizeOptions={false}
      showPagination={shouldShowPagination(showPagination, resultCount, pageSize)}
      ref={tableRef}
      resizable={resizable}
      data={data}
      loading={loading}
      pages={pages || resultCount > 0 ? Math.ceil(resultCount / pageSize) : 1}
      filterable={filterable}
      filtered={filtered}
      filteredArgs={filteredArgs}
      columns={columns}
      defaultFilterMethod={defaultFilterMethod}
      sortable={sortable}
      defaultSorted={defaultSorted}
      defaultSortDesc={defaultSortDesc || false}
      onPageChange={onPageChange}
      onFetchData={onFetchData}
      onFilteredChange={onFilteredChange}
      style={style}
    />
  );
};

BaseTable.propTypes = [];

export default BaseTable;
