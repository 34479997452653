export const colors = {
    "red": [
      "#ff0000",
      "#990000",
      "#ff9999",
    ],
    "green": [
      "#008000",
      "#00ff00",
      "#99e699",
    ],
    "blue": [
      "#0066ff",
      "#002966",
      "#99ffff",
    ],
    "orange": [
      "#ffa500",
      "#995c00",
      "#ffcc80",
    ],
    "violet": [
      "#cc33ff",
      "#660099",
      "#b380ff",
    ]
}

export const defaultPalette = Object.keys(colors);

export const getRandomColors = (n = 1) => {
    const result = [];
    let index = 1;
    if (n > defaultPalette.length)
        throw new RangeError("getRandomColors: more elements taken than available");
    while (result.length < n) {
        const color = defaultPalette[index - 1];
        const firstColor = colors[color][0];
        if (!result.includes(firstColor)){
            result.push(firstColor);
            const add = index % (defaultPalette.length)
            index = add + 1 
        }
    }
    return result;
}