import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import login from './login';
import movimientos from './movimientos';

const rootReducer = combineReducers({
  login,
  movimientos,
  routing: routerReducer,
});

export default rootReducer;
