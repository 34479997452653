import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { POSICION_DEUDOR_WRITE } from "helpers/profilePermission";
import isUserHasProfile from "helpers/profileValidator";
import { API, BASE_URL } from '../../../CONST';
import { get } from '../../../helpers/requests';
import PosicionDeudorInfo from './posicionDeudorInfo';
import PosicionDeudorEdit from './posicionDeudorEdit';
import PosicionDeudorInfoTable from './posicionDeudorInfoTable';
import PosicionDeudorTable from './posicionDeudorTable';
import PageContainer from '../../PageContainer';


const PosicionDeudor = () => {
  const { id } = useParams();
  const [infoDeudorData, setInfoDeudorData] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    get(`${BASE_URL}${API.deudor}/${id}/`).then((response) => {
      setInfoDeudorData(response);
    });
  }, [id, edit]);

  const toggleModal = (event) => {
    if (event){
      event.preventDefault();
    }
    setEdit(!edit);
  };

  return (
    <PageContainer
      breadcrumbs={[
        { id: 1, name: 'SOLICITUDES', url: '/' },
        { id: 2, name: 'POSICIÓN DEL DEUDOR', url: '/solicitudes/posicion-deudor' },
        { id: 3, name: id },
      ]}
      mainAction={
        isUserHasProfile(POSICION_DEUDOR_WRITE) && {
          url: '/',
          onClick: toggleModal,
          caption: 'Editar deudor',
        }
      }
    >
      <Col>
        <Row>
          <Col>
            <PosicionDeudorInfo datos={infoDeudorData} />
          </Col>
          <Col>
            <PosicionDeudorInfoTable datos={infoDeudorData} />
          </Col>
        </Row>
        <Row>
          <Col>
            <PosicionDeudorTable id={id} edit={edit} />
          </Col>
        </Row>
      </Col>
      <PosicionDeudorEdit
        openModal={edit}
        id={id}
        toggleModal={toggleModal}
        datos={infoDeudorData}
      />
    </PageContainer>
  );
};

export default PosicionDeudor;
