import React, { useRef } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'styles/tables.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';

import { DEFAULT_PAGE_SIZE } from '../../CONST';

const ReactTableWithFixedColumns = withFixedColumns(ReactTable);

const shouldShowPagination = (showPagination, resultCount, pageSize) => {
  if (showPagination !== undefined ? showPagination : true) {
    return resultCount > pageSize;
  }
  return false;
};

const BaseTable = ({
  className,
  columns,
  data,
  defaultFilterMethod,
  defaultSorted,
  defaultSortDesc,
  filterable,
  filtered,
  filteredArgs,
  loading,
  onFetchData,
  onFilteredChange,
  onPageChange,
  onSortedChange,
  pages,
  resizable,
  resultCount,
  pageSize = DEFAULT_PAGE_SIZE,
  showPagination,
  style,
  tableRef,
  showBorders,
  hasFixedColumns,
  sortable,
}) => {
  let TableComponent = ReactTable;
  if (hasFixedColumns) TableComponent = ReactTableWithFixedColumns;
  const reactTableRef = useRef(null);
  return (
    <TableComponent
      manual
      className={`-highlight ${className || ''}`}
      loadingText="Cargando..."
      pageSize={pageSize}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      minRows={1}
      pageText="Página"
      previousText="Anterior"
      nextText="Siguiente"
      noDataText="Sin resultados"
      rowsText="Filas"
      multiSort={false}
      showPageSizeOptions={false}
      showPagination={shouldShowPagination(showPagination, resultCount, pageSize)}
      innerRef={reactTableRef}
      ref={tableRef}
      resizable={resizable}
      data={data}
      loading={loading}
      pages={pages}
      filterable={filterable}
      filtered={filtered}
      filteredArgs={filteredArgs}
      columns={columns}
      defaultFilterMethod={defaultFilterMethod}
      sortable={sortable}
      defaultSorted={defaultSorted}
      defaultSortDesc={defaultSortDesc || false}
      onPageChange={onPageChange}
      onFetchData={onFetchData}
      onFilteredChange={onFilteredChange}
      onSortedChange={onSortedChange}
      style={style}
      getTheadProps={() => ({
        style: {
          background: '#EFEFEF',
        },
      })}
      getTheadThProps={() => ({
        style: {
          border: showBorders ? undefined : 'none',
        },
      })}
      getTrGroupProps={() => ({
        style: {
          border: showBorders ? undefined : 'none',
          borderBottom: '1px solid #CCCCCC',
        },
      })}
      getTdProps={() => ({
        style: {
          border: showBorders ? undefined : 'none',
        },
      })}
      getTableProps={() => ({
        style: { overflowX: 'scroll' },
      })}
    />
  );
};

BaseTable.propTypes = [];

export default BaseTable;
