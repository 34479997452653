import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'reactstrap';

class Icon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle = () => {
    const { tooltipOpen } = this.state;
    this.setState({ tooltipOpen: !tooltipOpen })
  }

  render() {
    const {
      children,
      className,
      handleOnClick,
      id,
      isDisabled,
      link,
      placement,
      tooltipText,
      url,
      includeToolTip,
      autohide,
    } = this.props;
    const { tooltipOpen } = this.state;
    return (
      <>
        &nbsp;&nbsp;
        {link ? (
          <Link
            className="table-link"
            id={id}
            to={url}
          >
            {children}
          </Link>
        ) : (
          <Button
            className={`table-link icon ${className}`}
            id={id}
            disabled={isDisabled}
            onClick={handleOnClick}
          >
            {children}
          </Button>
        )}
        {(!isDisabled && includeToolTip) && (
          <Tooltip
            placement={placement}
            isOpen={tooltipOpen}
            target={id}
            toggle={this.toggle}
            autohide={autohide}
            modifiers={{
              // Permite mostrar textos largos.
              preventOverflow: { enabled: false },
              hide: { enabled: false }
            }}
          >
            <div className="text-left">
              {tooltipText}
            </div>
          </Tooltip>
        )}
      </>
    )
  }
}

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  link: PropTypes.bool,
  placement: PropTypes.string,
  tooltipText: PropTypes.node.isRequired,
  url: PropTypes.string,
  includeToolTip: PropTypes.bool,
  autohide: PropTypes.bool,
};

Icon.defaultProps = {
  className: '',
  handleOnClick: () => {},
  isDisabled: false,
  link: false,
  placement: undefined,
  url: '',
  includeToolTip: true,
  autohide: true,
};

export default Icon;
