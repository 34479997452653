import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import SafeBtn from 'components/shared/SafeBtn';


const ComentarioResolucionCancelationModal = ({
  openModal,
  setOpenCancelationModal,
  closeModal,
}) => (
  <Modal
    style={{maxWidth: '600px' }}
    isOpen={openModal}
    type="check-modal"
  >
    <ModalHeader>
      ¿Está seguro que desea cancelar?
    </ModalHeader>
    <ModalBody style={{ paddingLeft: 41, paddingRight: 41 }}>
      <div id="inline-block-container" className="modal-check text">
        <div id="inline-block-message" className="check-modal-text">
          Los datos no se guardarán.
        </div>
      </div>
    </ModalBody>
    <ModalFooter style={{ padding: "1rem" }}>
      <Button
        className="warning-cancel mr-auto"
        onClick={() => setOpenCancelationModal(false)}
        style={{ marginLeft: 25 }}
      >
        No
      </Button>
      <SafeBtn
        className="warning-accept"
        onClick={() => {setOpenCancelationModal(false); closeModal()}}
        style={{ marginRight: 25 }}
      >
        Si, cancelar
      </SafeBtn>
    </ModalFooter>
  </Modal>
)

ComentarioResolucionCancelationModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenCancelationModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default ComentarioResolucionCancelationModal