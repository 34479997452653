import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Container,
  Col,
  FormFeedback,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Input,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import { get, post } from 'helpers/requests';
import { motivosToOption, objectsToOptions } from 'helpers/selects';
import { BASE_URL, API, BACKEND_DF, ACCION_LINEA, ESTADO_MOVIMIENTO } from 'CONST';
import SafeBtn from '../../shared/SafeBtn';

const PosicionDeudorModal = ({ openModal, toggleModal, movimiento, onAcceptModal }) => {
  const [motivo, setMotivo] = useState();
  const [motivos, setMotivos] = useState([]);
  const [descripcion, setDescripcion] = useState();
  const [acciones, setAcciones] = useState([]);
  const [accion, setAccion] = useState();
  const [monto, setMonto] = useState();
  const [loading, setLoading] = useState(false);
  const [estadoAprobado, setEstadoAprobado] = useState();
  const [estadoBloqueado, setEstadoBloqueado] = useState();
  const [comentarioAsegurado, setComentarioAsegurado] = useState('');

  useEffect(() => {
    get(`${BASE_URL}${API.motivosResolucion}/`).then((response) =>
      setMotivos(motivosToOption(response)),
    );
    get(`${BASE_URL}${API.acciones}`).then((response) =>
      setAcciones(objectsToOptions(response.results)),
    );
    get(`${BASE_URL}${API.estadosMovimientos}`).then((response) => {
      setEstadoAprobado(response.find(estado => estado.nemotecnico === ESTADO_MOVIMIENTO.aprobado));
      setEstadoBloqueado(response.find(estado => estado.nemotecnico === ESTADO_MOVIMIENTO.bloqueado));
    });
  }, []);

  const clearState = () => {
    setMotivo();
    setAccion();
    setDescripcion();
    setMonto();
  };

  const onCloseModal = () => {
    clearState();
    toggleModal();
  };

  const onClick = () => {
    setLoading(true);
    const data = {
      linea_credito: movimiento.linea_credito.id,
      tipo: accion.value.id,
      motivo: motivo.motivo.descripcion,
      resuelto: true,
      fecha_respuesta: moment().format(BACKEND_DF),
      fecha_vigencia: moment().format(BACKEND_DF),
      fecha_fin_vigencia: movimiento.fecha_fin_vigencia,
      monto_solicitado: movimiento.linea_credito.monto_vigente,
      estado: accion.value.nemotecnico === ACCION_LINEA.bloqueo ? estadoBloqueado.id : estadoAprobado.id,
      comentario_asegurado: comentarioAsegurado,
      tipo_deudor: movimiento.tipo_deudor,
      analista_id: movimiento.analista_id
    };

    if (
      accion &&
      accion.value &&
      [ACCION_LINEA.bloqueo, ACCION_LINEA.deshabilitar].includes(accion.value.nemotecnico)
    ) {
      data.fecha_fin_vigencia = moment().format(BACKEND_DF);
    }

    if (
      accion &&
      accion.value &&
      accion.value.nemotecnico === ACCION_LINEA.reducir
    ) {
      data.monto_otorgado = monto;
      data.monto_vigente = monto;
    }

    post(`${BASE_URL}${API.movimientoLineaCredito}`, data)
      .then(() => { clearState(); onAcceptModal(); })
      .then(() => setLoading(false));

  };

  const isDisabledSaveBtn = () => {
    const isMontoRequired = accion && accion.value && accion.value.nemotecnico === ACCION_LINEA.reducir ? monto : true;
    return !accion || !motivo || !isMontoRequired
  }

  return (
    <Modal isOpen={openModal} toggle={onCloseModal} type="modal-warning warning-container">
      <ModalHeader toggle={onCloseModal}>
        {`Acciones línea ID ${movimiento && movimiento.linea_credito && movimiento.linea_credito.id
          }`}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="5">
              <Label for="motivo">Seleccione una acción:</Label>
            </Col>
            <Col>
              <Select
                id="accion"
                name="accion"
                className="custom-policy-select"
                options={movimiento.estado_linea && movimiento.estado_linea.nemotecnico === "deshabilitada" ? acciones.filter(x => x.value.nemotecnico !== "deshabilitar") : acciones}
                onChange={(event) => {
                  setAccion(event);
                  setMonto(null);
                }}
                value={accion}
                placeholder=""
                isDisabled={estadoBloqueado && movimiento.estado === estadoBloqueado.id}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="5">
              <Label for="motivo">Seleccione un motivo:</Label>
            </Col>
            <Col>
              <Select
                id="motivo"
                name="motivo"
                className="custom-policy-select"
                options={motivos}
                onChange={(event) => {
                  setMotivo(event);
                  setDescripcion(event.motivo.descripcion);
                }}
                value={motivo}
                placeholder=""
                isDisabled={estadoBloqueado && movimiento.estado === estadoBloqueado.id}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="5">Descripción motivo:</Col>
            <Col>{descripcion}</Col>
          </Row>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="5">
              <Label for="comentario">Comentario al Asegurado:</Label>
            </Col>
            <Col>
              <Input
                type='textarea'
                onChange={(event) => setComentarioAsegurado(event.target.value)}
                disabled={estadoBloqueado && movimiento.estado === estadoBloqueado.id}
              />
            </Col>
          </Row>
          {accion && accion.value && accion.value.nemotecnico === ACCION_LINEA.reducir && (
            <Row style={{ marginBottom: '1rem' }}>
              <Col md="5">
                <Label for="motivo">Monto:</Label>
              </Col>
              <Col>
                <NumberFormat
                  id="monto-parcial"
                  className="form-control"
                  decimalSeparator={false}
                  thousandSeparator="."
                  allowNegative={false}
                  value={monto}
                  onValueChange={(values) => setMonto(values.floatValue)}
                  isAllowed={(values) => (!values.value ||
                    (values.floatValue > 0 && values.floatValue <= parseInt(movimiento.linea_credito.monto_vigente, 10))
                  )}
                />
              </Col>
            </Row>
          )}
          {estadoBloqueado && movimiento.estado === estadoBloqueado.id && (
            <FormFeedback>
              No se puede realizar una acción a una línea bloqueada. Favor solicitar desbloqueo en el módulo de Workflow.
            </FormFeedback>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button className="warning-cancel mr-auto" onClick={() => onCloseModal()}>
          Cancelar
        </Button>
        <SafeBtn
          className="warning-accept"
          onClick={() => onClick()}
          disabled={isDisabledSaveBtn()}
          loading={loading}
        >
          Aceptar
        </SafeBtn>
      </ModalFooter>
    </Modal>
  );
};

PosicionDeudorModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  movimiento: PropTypes.shape({
    id: PropTypes.number,
    analista_id: PropTypes.number,
    estado_linea: PropTypes.objectOf(
      PropTypes.any,
    ),
    linea_credito: PropTypes.objectOf(
      PropTypes.any,
    ),
    tipo_deudor: PropTypes.number,
    fecha_vigencia: PropTypes.string,
    fecha_fin_vigencia: PropTypes.string,
    monto_otorgado: PropTypes.number,
    estado: PropTypes.number,
  }),
  onAcceptModal: PropTypes.func.isRequired,
};

PosicionDeudorModal.defaultProps = {
  movimiento: {
    id: null,
    linea_credito: null,
  },
};

export default PosicionDeudorModal;
