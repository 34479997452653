import React from 'react';
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from 'reactstrap';
import SafeBtn from 'components/shared/SafeBtn';
import { BASE_URL, API } from 'CONST';
import { post } from "../../../helpers/requests";



const SolicitudReevaluacionModal = ({
  openReevaluacionModal,
  setOpenReevaluacionModal,
  movimientoIDReevaluacion,
  userIDReevaluacion,
  nombreDeudor,
  nombreAsegurado
}) => {
  const solicitarReevaluacion = () => {
    const body = {
      mov_id: movimientoIDReevaluacion,
      user_id: userIDReevaluacion,
    }
    post(`${BASE_URL}${API.solicitarReevaluacion}`,body)
    setOpenReevaluacionModal(false);
  }
  return (
    <Modal
      style={{maxWidth: '600px'}}
      isOpen={openReevaluacionModal}
      type="check-modal"
    >
      <ModalHeader>
        ¿Está seguro que desea solicitar una reevaluación para este movimiento?
      </ModalHeader>
      <ModalBody>
        <p style={{justifyContent:'center'}}>
          {`Se reevaluará el movimiento con id ${movimientoIDReevaluacion} del deudor
          ${nombreDeudor} 
          y asegurado
          ${nombreAsegurado}`}
        </p>
        Esto implica enviar una solicitud al motor de riesgo Credex.
      </ModalBody>
      <ModalFooter>
        <Button
          className="warning-cancel mr-auto"
          onClick={() => setOpenReevaluacionModal(false)}
          style={{ marginLeft: 25 }}
        >
          Cancelar
        </Button>
        <SafeBtn
          className="warning-accept"
          onClick={() => solicitarReevaluacion(movimientoIDReevaluacion, userIDReevaluacion)}
          style={{ marginRight: 25 }}
        >
          Solicitar
        </SafeBtn>
      </ModalFooter>
    </Modal>
  )
}

SolicitudReevaluacionModal.propTypes = {
  openReevaluacionModal: PropTypes.bool.isRequired,
  setOpenReevaluacionModal: PropTypes.func.isRequired,
  movimientoIDReevaluacion: PropTypes.number.isRequired,
  userIDReevaluacion: PropTypes.number.isRequired,
  nombreDeudor: PropTypes.string.isRequired,
  nombreAsegurado: PropTypes.string.isRequired,
}


export default SolicitudReevaluacionModal