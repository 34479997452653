import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ACCOUNTANT_URL, DOCS_URL, POLICY_URL, USERS_URL } from '../../CONST';

const appUrls = {
  'users': ['usuarios', USERS_URL],
  'accountant': ['reservas', ACCOUNTANT_URL],
  'policies': ['póliza', POLICY_URL],
  'docs': ['documentos', DOCS_URL],
};
const REDIRECT_SECS = 10;

const AppRedirect = ({user}) => {
  const app = Object.keys(appUrls).find(name => user.apps.includes(name));
  const [ secs, setSecs ] = useState(REDIRECT_SECS);
  useEffect(() => {setTimeout(() => setSecs(secs - 1), 1000)});

  let msg;
  if (app) {
    msg = 'Usted no está autorizado para usar esta aplicación.';
    msg += ` Será dirigido a la aplicación de ${appUrls[app][0]} en ${secs} `;
    msg += `segundo${secs > 1 ? 's' : ''}...`;
    const url = appUrls[app][1];
    if (secs === 10) {
      setTimeout(() => window.location.assign(url), REDIRECT_SECS * 1000);
    }
  } else {
    msg = 'Usted no está autorizado para usar ninguna aplicación.';
    msg += ' Por favor, contacte con ORSAN para resolver su situación.';
  }

  return <p>{msg}</p>;
};

AppRedirect.propTypes = {
  user: PropTypes.shape({
    apps: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = state => ({
  user: state.login.user,
});

export default withRouter(connect(mapStateToProps)(AppRedirect));
