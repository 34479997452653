/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const SafeBtn = ({ loading, children, text, ...btnProps }) =>
  loading ? (
    <Button {...btnProps} disabled>
      <FontAwesomeIcon icon={faSpinner} pulse />
      {text}
    </Button>
  ) : (
    <Button {...btnProps}>{children}</Button>
  );

SafeBtn.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
};

SafeBtn.defaultProps = {
  loading: false,
  text: "",
};

export default SafeBtn;
