import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import 'styles/modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const BaseModal =
  ({
    children,
    handleModal,
    link,
    modalOpen,
    title,
    type,
  }) => (
    <Modal className={type} isOpen={modalOpen} toggle={handleModal} size="lg">
      {title && (
        <ModalHeader toggle={handleModal}>
          {title}
          {link && (
            <a href={link} className="table-link poliza">
              <span className="text">Ver póliza</span>
              <span className="icon">
                <FontAwesomeIcon icon={faSignOutAlt} className="table-link" />
              </span>
            </a>
          )}
        </ModalHeader>
      )}
      <ModalBody className={type}>
        {children}
      </ModalBody>
    </Modal>
);

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleModal: PropTypes.func.isRequired,
  link: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
};

BaseModal.defaultProps = {
  link: '',
  title: '',
};

export default BaseModal;
