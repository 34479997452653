import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import AseguradosDetalleData from './AseguradosDetalleData';
import AseguradosDetalleTable from './AseguradosDetalleTable';
import { API, BASE_URL } from '../../../../CONST';
import { get } from '../../../../helpers/requests';
import PageContainer from '../../../PageContainer';

const AseguradosDetalle = () => {
  const { id } = useParams();
  const [poliza, setPoliza] = useState('Cargando...');

  useEffect(() => {
    get(`${BASE_URL}${API.credito}/${id}/`).then((response) => {
      setPoliza(response);
    });
  }, [id]);

  return (
    <PageContainer
      breadcrumbs={[
        { id: 1, name: 'PÓLIZAS', url: '/polizas/asegurados-polizas' },
        { id: 2, name: 'ASEGURADOS-PÓLIZAS', url: '/polizas/asegurados-polizas' },
        { id: 3, name: `${poliza.base && poliza.base.asegurado.nombre}` },
      ]}
    >
      <AseguradosDetalleData poliza={poliza} />
      <AseguradosDetalleTable />
    </PageContainer>
  );
};

export default AseguradosDetalle;
