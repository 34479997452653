import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import PosicionDeudorReporteConsolidado from './posicionDeudorReporteConsolidado';

const PosicionDeudorReporteConsolidadoModal = ({ isOpen, toggle, reporteConsolidadoData }) => (

  <Modal
    isOpen={isOpen}
    style={{maxWidth: "2000px", width: "90%"}}
    toggle={toggle}
    type="modal-warning warning-container"
  >
    <ModalHeader
      toggle={toggle}
      style={{paddingBottom: "0px", paddingTop: "40px"}}
    >
      Reporte Consolidado
    </ModalHeader>
    <ModalBody
      style={{paddingTop: "0px"}}
    >
      <PosicionDeudorReporteConsolidado reporteConsolidado={reporteConsolidadoData} />
    </ModalBody>
  </Modal>

);

PosicionDeudorReporteConsolidadoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  reporteConsolidadoData: PropTypes.shape({
    balance_tributario: PropTypes.arrayOf(
      PropTypes.object,
    ),
    ratios_financieros: PropTypes.arrayOf(
      PropTypes.object,
    ),
    socios_sociedades: PropTypes.arrayOf(
      PropTypes.object,
    ),
    representantes: PropTypes.arrayOf(
      PropTypes.object,
    ),
    infocred: PropTypes.arrayOf(
      PropTypes.object,
    ),
    directorio_full: PropTypes.objectOf(
      PropTypes.any,
    ),
    renta_tributaria: PropTypes.arrayOf(
      PropTypes.object,
    ),
    situacion_tributaria: PropTypes.objectOf(
      PropTypes.any,
    ),
    compra_venta: PropTypes.arrayOf(
      PropTypes.object,
    ),
  }).isRequired,
};

export default PosicionDeudorReporteConsolidadoModal;
