import React from 'react';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation } from 'helpers/tables';
import BaseTable from '../../shared/BaseTable';

const PosicionDeudorSociedades = ({ sociedades, showType }) => {
  const columns = [
    {
      Header: headerOperation('NOMBRE O RAZÓN SOCIAL'),
      accessor: 'nombre',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    },
    {
      Header: headerOperation('RUT'),
      accessor: 'rut',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    },
    {
      Header: headerOperation('INCORPORACIÓN'),
      accessor: 'fecha_vinculo',
      Cell: (row) => cellOperation(row.value || '---', 'center'),
      minWidth: 150,
      filterable: false,
    },
  ];

  if (showType) {
    columns.push({
      Header: headerOperation('TIPO'),
      accessor: 'tipo.nombre',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    });
  }

  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <BaseTable columns={columns} data={sociedades} sortable={false} style={{backgroundColor:'white'}} />
    </div>
  );
};

PosicionDeudorSociedades.propTypes = {
  sociedades: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string,
      rut: PropTypes.string,
      fecha_vinculo: PropTypes.string,
      tipo: PropTypes.shape({
        nombre: PropTypes.string,
      }),
    }),
  ).isRequired,
  showType: PropTypes.bool,
};

PosicionDeudorSociedades.defaultProps = {
  showType: false,
};

export default PosicionDeudorSociedades;
