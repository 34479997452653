import DownloadBtn from 'components/shared/DownloadBtn';
import { BASE_URL, API } from 'CONST';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import PageContainer from '../../PageContainer';
import InboxRiesgoMovimientosTable from './InboxRiesgoMovimientosTable';

const InboxRiesgo = () => {
  const [pendientes, setPendientes] = useState(0);

  return (
    <PageContainer
      breadcrumbs={[
        { id: 1, name: 'SOLICITUDES', url: '/solicitudes/inbox-riesgos' },
        { id: 2, name: 'BANDEJA DE ENTRADA RIESGOS' },
      ]}
    >
      <Row style={{ margin: 0 }}>
        <Col
          sm={{ size: 2, offset: 8 }}
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'end',
          }}
        >
          <DownloadBtn
            id="xlsx-download"
            text='XLSX'
            tipText="Descarga Bandeja de Entrada"
            url={`${BASE_URL}${API.riskInbox}/xlsx`}
          />
        </Col>
        <Col sm={{ size: 2 }} style={{ borderStyle: "solid", borderWidth: "thin" }}>
          <Row style={{ padding: "5px 0px" }}>
            <Col sm="10">
              Total solicitudes pendientes:
            </Col>
            <Col sm="0">
              {pendientes}
            </Col>
          </Row>
        </Col>
      </Row>
      <InboxRiesgoMovimientosTable setPendientes={setPendientes} />
    </PageContainer>
  )
}

export default InboxRiesgo;
