const BASE_URL = process.env.REACT_APP_API;
const BASE_OAUTH = process.env.REACT_APP_OAUTH;
const CLIENT_ID = process.env.REACT_APP_CLIENT;
const LOCAL_URL = process.env.REACT_APP_LOCAL;
const USERS_URL = process.env.REACT_APP_USERS;
const DOCS_URL = process.env.REACT_APP_DOCS;
const POLICY_URL = process.env.REACT_APP_POLICY;
const ACCOUNTANT_URL = process.env.REACT_APP_ACCOUNTANT;
const REASEGURO_URL = process.env.REACT_APP_REASEGURO;
const RISK_URL = process.env.REACT_APP_RISK;
const WORKFLOW_URL = process.env.REACT_APP_WORKFLOW;
const SEARCH_DELAY_MS = process.env.REACT_APP_SEARCH_DELAY_MS;
const ORSAN_LOGO_URL = "https://orsan-static.s3.amazonaws.com/public/logotipo_orsan_seguros.png";

export const DEFAULT_PAGE_SIZE_SMALL = 10;
export const DEFAULT_PAGE_SIZE = 20;
export const calculatePages = (rows) => Math.ceil(rows / DEFAULT_PAGE_SIZE);

const API = {
  login: 'authorize',
  endSession: 'end-session',
  userinfo: 'userinfo',
  riskInbox: 'riesgos-bandeja-entrada',
  resolucion: 'resoluciones',
  motivosResolucion: 'motivos-resolucion',
  actividadEconomica: 'actividad-economica',
  analistas: 'analistas',
  analistasCredito: 'analistas-credito',
  estadosLinea: 'estados-linea',
  deudor: 'deudor',
  posicionDeudorTable: 'posicion-deudor-table',
  acciones: 'acciones/',
  movimientoLineaCredito: 'movimiento-linea-credito/',
  historicoMovimientosLineaCredito: 'historial-movimientos-linea-credito',
  credito: 'credito',
  organization: 'organization',
  balanceCredito: 'balance-credito/',
  deudores: 'deudores/',
  reporteConsolidado: 'reporte-consolidado/',
  tamanoEmpresa: 'tamano-empresa',
  comentariosSolicitud: 'comentarios-solicitud',
  uploadDocumento: 'upload-documento',
  downloadDocumento: 'download-documento',
  notificablesCredito: 'notificables-credito',
  estadosMovimientos: 'estados-movimientos',
  solicitarReevaluacion: 'solicitar-reevaluacion',
};

const ESTADOS_DEUDOR = {
  bloqueado: 'bloqueado',
  vigente: 'vigente',
}

const PROFILES = {
  ceo: 'ceo',
  consultaBasico: 'consulta-basico',
  comercialGarantia: 'comercial-garantia',
  suscripcionGarantia: 'suscripcion-garantia',
  analistaRiesgoGarantia: 'analista-riesgo-garantia',
  gerenteRiesgoGarantia: 'gerente-riesgo-garantia',
  comercialCredito: 'comercial-credito',
  riesgoCredito: 'riesgo-credito',
  fiscalia: 'fiscalia',
  actuariado: 'actuariado',
  gerenteOperaciones: 'gerente-operaciones',
  analistaOperaciones: 'analista-operaciones',
  riesgoOperacional: 'riesgo-operacional',
  siniestros: 'siniestros',
  recuperoSiniestros: 'recupero-siniestros',
  administrador: 'administrador',
  consultaEspecial: 'consulta-especial',
  gerenteGarantia: 'gerente-garantia',
  gerenteRiesgoCredito: 'gerente-riesgo-credito',
  administrativoRiesgoCredito: 'administrativo-riesgo-credito',
  analistaRiesgoCredito: 'analista-riesgo-credito',
  notificacionesRiesgoCredito: 'notificaciones-riesgo-credito',
};

export const FILTROS_TEXTO = {
  vigentes: 'Vigentes',
  pendientes: 'Pendientes',
  historicas: 'Históricas',
  todas: 'Todas las líneas',
};

export const ESTADO_LINEA = {
  pendiente: 'Pendiente',
  aprobada: 'Aprobada',
  limitada: 'Limitada',
  rechazada: 'Rechazada',
  reducida: 'Reducida',
  cancelada: 'Cancelada',
  deshabilitada: 'Deshabilitada',
  error: 'Error',
};

export const TIPO_MOVIMIENTO_LINEA_CREDITO = {
  migracion: 'Migracion',
  desbloqueo: 'Desbloqueo',
}

export const ACCION_LINEA = {
  bloqueo: 'bloqueo',
  deshabilitar: 'deshabilitar',
  reducir: 'reducir',
}

export const ESTADO_MOVIMIENTO = {
  aprobado: 'aprobado',
  bloqueado: 'bloqueado',
}

export const ESTADOS_MOVIMIENTOS = {
  rechazado: 'rechazado',
  procesando: 'procesando',
  error: 'error',
  pendiente: 'pendiente',
}

const FRONT_DF_1 = 'DD-MM-YYYY';
const BACKEND_DF = 'YYYY-MM-DD';

export const RESOLUCION_APROBADA = 'aprobado';
export const RESOLUCION_APROBADA_PARCIAL = 'aprobado-parcial';
export const RESOLUCION_RECHAZADA = 'rechazado';
export const RESOLUCION_BLOQUEADA = 'bloqueado'

export const NO_FILTERED_ID = -1;

export {
  ACCOUNTANT_URL,
  API,
  BASE_URL,
  ESTADOS_DEUDOR,
  BASE_OAUTH,
  CLIENT_ID,
  LOCAL_URL,
  DOCS_URL,
  POLICY_URL,
  SEARCH_DELAY_MS,
  USERS_URL,
  WORKFLOW_URL,
  REASEGURO_URL,
  RISK_URL,
  PROFILES,
  FRONT_DF_1,
  BACKEND_DF,
  ORSAN_LOGO_URL,
};
