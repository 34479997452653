import React from 'react';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import BaseTable from '../../shared/BaseTable';

const PosicionDeudorInfoCred = ({ infoCred }) => {
  const dataColumns = [
    {tipo: 'Dir. Vigente'},
    {tipo: 'Dir. Morosa'},
    {tipo: 'Dir. Vencida'},
    {tipo: 'Dir. Consumo'},
    {tipo: 'Cant. Acreedores'},
    {tipo: 'Dir. Hipotecaria'},
    {tipo: 'Dir. Inv. Financiera'},
    {tipo: 'Dir. Ope. C/Pacto'},
    {tipo: 'Dir. Comercial'},
    {tipo: '+ MEX. Vigente'},
    {tipo: '+ MEX. Vencida'},
    {tipo: 'Indir. Vigente'},
    {tipo: 'Indir. Vencida'},
    {tipo: 'Castigada Dir.'},
    {tipo: 'Castigada Indir.'},
    {tipo: 'L. CRed. Disp. N. U.'},
  ];

  const columns = [
    {
      Header: headerOperation('ITEM'),
      accessor: 'tipo',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 150,
      filterable: false,
    }
  ];

  if (infoCred){
    infoCred.forEach(info => {
      columns.push({
        Header: headerOperation(`${info.mes}`),
        accessor: `${info.mes}`,
        Cell: (row) => cellNumOperation(row.value),
        minWidth: 150,
        filterable: false,
      });
      dataColumns[0][`${info.mes}`] = info.dir_vigente;
      dataColumns[1][`${info.mes}`] = info.dir_morosa;
      dataColumns[2][`${info.mes}`] = info.dir_vencida;
      dataColumns[3][`${info.mes}`] = info.dir_consumo;
      dataColumns[4][`${info.mes}`] = info.dir_acreedor;
      dataColumns[5][`${info.mes}`] = info.dir_hipotecaria;
      dataColumns[6][`${info.mes}`] = info.dir_inversion;
      dataColumns[7][`${info.mes}`] = info.dir_operacion_pacto;
      dataColumns[8][`${info.mes}`] = info.dir_comercial;
      dataColumns[9][`${info.mes}`] = info.mex_vigente;
      dataColumns[10][`${info.mes}`] = info.mex_vencida;
      dataColumns[11][`${info.mes}`] = info.indirecta_vigente;
      dataColumns[12][`${info.mes}`] = info.indirecta_vencida;
      dataColumns[13][`${info.mes}`] = info.castigada_directa;
      dataColumns[14][`${info.mes}`] = info.castigada_indirecta;
      dataColumns[15][`${info.mes}`] = info.linea_credito;
    })
  }

  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <BaseTable columns={columns} data={dataColumns} sortable={false} style={{backgroundColor:'white'}} />
    </div>
  );
};

PosicionDeudorInfoCred.propTypes = {
  infoCred: PropTypes.arrayOf(
    PropTypes.shape({
      dir_vigente: PropTypes.number,
      dir_morosa: PropTypes.number,
      dir_vencida: PropTypes.number,
      dir_consumo: PropTypes.number,
      dir_acreedor: PropTypes.number,
      dir_hipotecaria: PropTypes.number,
      dir_inversion: PropTypes.number,
      dir_operacion_pacto: PropTypes.number,
      dir_comercial: PropTypes.number,
      mex_vigente: PropTypes.number,
      mex_vencida: PropTypes.number,
      indirecta_vigente: PropTypes.number,
      indirecta_vencida: PropTypes.number,
      castigada_directa: PropTypes.number,
      castigada_indirecta: PropTypes.number,
      linea_credito: PropTypes.number,
    }),
  ).isRequired,
};

export default PosicionDeudorInfoCred;
