import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ComentarioResolucionTable from "../../resolucion/ComentarioResolucionTable";

const PosicionDeudorComentariosModal = ({ openModal, closeModal, comentarios, movimientoIdComentarios }) => (
  <Modal
    isOpen={openModal}
    style={{maxWidth: "1500px"}}
    toggle={closeModal}
    type="modal-warning warning-container"
  >
    <ModalHeader toggle={closeModal}>
      {`Comentarios para Movimiento de Línea ID ${movimientoIdComentarios}`}
    </ModalHeader>
    <ModalBody>
      <ComentarioResolucionTable
        data={comentarios}
      />
    </ModalBody>
  </Modal>
);

PosicionDeudorComentariosModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  comentarios: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  movimientoIdComentarios: PropTypes.number,
};

PosicionDeudorComentariosModal.defaultProps = {
  movimientoIdComentarios: null
};

export default PosicionDeudorComentariosModal;
