import React from 'react';
import PropTypes from 'prop-types';
import { faCheckCircle, faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, UncontrolledTooltip } from 'reactstrap';

const PosicionDeudorAccordionSection = ({ title, children, toggle, collapsed, isValid, expansible }) => (
  <Col
    className='form-section'
    style={{opacity: expansible ? 1 : 0.5}}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div
      className={`accordion-section-header${expansible ? ' expansible' : ''}`}
      style={{
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: '20px auto 20px',
        gridGap: '2px',
        alignItems: 'center',
        cursor: expansible ? 'pointer' : undefined,
      }}
      onClick={expansible ? toggle : () => {}}
    >
      <FontAwesomeIcon
        id={`${title.replaceAll(' ','')}-accordion-section`}
        className='fa-xs'
        icon={isValid ? faCheckCircle : faExclamationCircle}
        style={{ marginBottom: '5px', color: `${isValid ? '#315e90' : '#dc3545'}` }}
      />
      { !isValid && (
      <UncontrolledTooltip target={`${title.replaceAll(' ','')}-accordion-section`}>
        No hay información para esta sección
      </UncontrolledTooltip>
      )}
        
      
      <div><h6 className="form-title">{title}</h6></div>
      <FontAwesomeIcon
        icon={faChevronRight}
        style={{ cursor: expansible ? 'pointer' : undefined, marginBottom: '5px' }}
        transform={collapsed ? {} : {rotate: 90}}
      />
    </div>
    <hr className="form-separator" />
    {children}
  </Col>
);

PosicionDeudorAccordionSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  toggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  expansible: PropTypes.bool.isRequired,
};

export default PosicionDeudorAccordionSection;
