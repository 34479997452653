import React from 'react';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import BaseTable from '../../shared/BaseTable';

const PosicionDeudorBalanceTributario = ({ balanceTributario }) => {
  
  const dataColumns = [
    {tipo: 'Caja'},
    {tipo: 'Banco'},
    {tipo: 'Existencia'},
    {tipo: 'Provisiones'},
    {tipo: 'Otros circulantes'},
    {tipo: 'Total activo circulante'},
    {tipo: 'Activo inmovilizado'},
    {tipo: 'Total activo fijo'},
    {tipo: 'Otros activos'},
    {tipo: 'Total otros activos'},
    {tipo: 'TOTAL ACTIVOS'},
    {tipo: 'Pasivos circulantes'},
    {tipo: 'Total pasivos circulantes'},
    {tipo: 'Pasivos de largo plazo'},
    {tipo: 'Total pasivo largo plazo'},
    {tipo: 'TOTAL PASIVO EXIGIBLE'},
    {tipo: 'Capital enterado'},
    {tipo: 'Otros pasivos no exigibles'},
    {tipo: 'Utilidad del ejercicio'},
    {tipo: 'TOTAL PATRIMONIO'},
    {tipo: 'TOTAL PASIVO'},
  ];

  const columns = [
    {
      Header: headerOperation('CUENTA'),
      accessor: 'tipo',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 150,
      filterable: false,
    }
  ];

  if (balanceTributario){
    balanceTributario.forEach(balance => {
      dataColumns[0][`${balance.anno}`] = balance.caja;
      dataColumns[1][`${balance.anno}`] = balance.banco;
      dataColumns[2][`${balance.anno}`] = balance.existencia;
      dataColumns[3][`${balance.anno}`] = balance.provisiones;
      dataColumns[4][`${balance.anno}`] = balance.otros_circulantes;
      dataColumns[5][`${balance.anno}`] = balance.total_activo_circulante;
      dataColumns[6][`${balance.anno}`] = balance.activo_inmovilizado;
      dataColumns[7][`${balance.anno}`] = balance.total_activo_fijo;
      dataColumns[8][`${balance.anno}`] = balance.otros_activos;
      dataColumns[9][`${balance.anno}`] = balance.total_otros_activos;
      dataColumns[10][`${balance.anno}`] = balance.total_activos;
      dataColumns[11][`${balance.anno}`] = balance.pasivos_circulantes;
      dataColumns[12][`${balance.anno}`] = balance.total_pasivo_circulante;
      dataColumns[13][`${balance.anno}`] = balance.pasivo_largo_plazo;
      dataColumns[14][`${balance.anno}`] = balance.total_pasivo_largo_plazo;
      dataColumns[15][`${balance.anno}`] = balance.total_pasivo_exigible;
      dataColumns[16][`${balance.anno}`] = balance.capital_enterado;
      dataColumns[17][`${balance.anno}`] = balance.otros_pasivo_no_exigible;
      dataColumns[18][`${balance.anno}`] = balance.utilidad_ejercicio;
      dataColumns[19][`${balance.anno}`] = balance.total_patrimonio;
      dataColumns[20][`${balance.anno}`] = balance.total_pasivo;

      const checkEmpty = dataColumns.every(data => data[`${balance.anno}`]=== 0);
      if (!checkEmpty){
        columns.push({
          Header: headerOperation(`${balance.anno}`),
          accessor: `${balance.anno}`,
          Cell: (row) => cellNumOperation(row.value, 'center'),
          minWidth: 150,
          filterable: false,
        });
      }
    })
  }

  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <BaseTable columns={columns} data={dataColumns} sortable={false} style={{backgroundColor:'white'}} />
    </div>
  );
};

PosicionDeudorBalanceTributario.propTypes = {
  balanceTributario: PropTypes.arrayOf(
    PropTypes.shape({
      anno: PropTypes.number,
      caja: PropTypes.number,
      banco: PropTypes.number,
      existencia: PropTypes.number,
      provisiones: PropTypes.number,
      otros_circulantes: PropTypes.number,
      total_activo_circulante: PropTypes.number,
      activo_inmovilizado: PropTypes.number,
      total_activo_fijo: PropTypes.number,
      otros_activos: PropTypes.number,
      total_otros_activos: PropTypes.number,
      total_activos: PropTypes.number,
      pasivos_circulantes: PropTypes.number,
      total_pasivo_circulante: PropTypes.number,
      pasivo_largo_plazo: PropTypes.number,
      total_pasivo_largo_plazo: PropTypes.number,
      total_pasivo_exigible: PropTypes.number,
      capital_enterado: PropTypes.number,
      otros_pasivo_no_exigible: PropTypes.number,
      utilidad_ejercicio: PropTypes.number,
      total_patrimonio: PropTypes.number,
      total_pasivo: PropTypes.number,
    }),
  ).isRequired,
};

export default PosicionDeudorBalanceTributario;
