import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ESTADO_LINEA } from 'CONST';
import { cellOperation } from './tables';
import Icon from '../components/UI/Icon';

let icon = <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'orange'}} />;
let msg = 'En revisión por motor';

export const setIconAndMsg = (row) => {
  if (row.value && row.value === 'Aprobado') {
    icon = <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}} />;
    msg = ESTADO_LINEA.aprobada;
  } else if (row.value && row.value !== 'Aprobado' && row.value !== ESTADO_LINEA.error) {
    icon = <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'orange'}} />
    msg = row.value;
  } else {
    icon = <FontAwesomeIcon icon={faTimesCircle} style={{color: 'red'}} />
    msg = 'Sin información de motor';
  }
}

export const getIconInfo = (row) => {
  setIconAndMsg(row);

  return cellOperation(
    <Icon id={`check-${row.index}`} tooltipText={msg}>
      {icon}
    </Icon>,
  )
};