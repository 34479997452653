import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BaseModal from './BaseModal';

const WarningModal = ({
  isOpen,
  toggle,
  handleOk,
  loading,
  okBtnText,
  confirmMsg,
  onlyCancelButton,
  cancelBtnText,
}) => (
  <div>
    <BaseModal handleModal={toggle} modalOpen={isOpen} type="modal-warning warning-container">
      <div className="warning-section text">
        <div className="icon-container">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
        <div>{confirmMsg}</div>
      </div>
      <div className="button-container warning-section">
        <div>
          <Button className="warning-cancel" disabled={loading} onClick={toggle}>
            {cancelBtnText}
          </Button>
        </div>
        {!onlyCancelButton && (
          <div>
            <Button className="warning-accept" disabled={loading} onClick={() => handleOk()}>
              {okBtnText}
            </Button>
          </div>
        )}
      </div>
    </BaseModal>
  </div>
);

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  okBtnText: PropTypes.string,
  confirmMsg: PropTypes.string.isRequired,
  onlyCancelButton: PropTypes.bool,
  cancelBtnText: PropTypes.string,
};

WarningModal.defaultProps = {
  okBtnText: 'Aceptar',
  cancelBtnText: 'Cancelar',
  onlyCancelButton: false,
  loading: false,
};

export default WarningModal;
