import React from "react";
import NumberFormat from 'react-number-format';
import { fdate, roundInt } from './helpers';
import { FRONT_DF_1 } from '../CONST';

export const headerOperation = (instance, align = 'center') => (
  <div className={`text-${align} table-header`}>
    {instance}
  </div>
);

export const headerDoubleRowOperation = (instance1, instance2) => (
  headerOperation((
    <>
      <div>{instance1}</div>
      <div>{instance2}</div>
    </>
  ))
);

export const cellOperation = (instance, align = 'center', wrap = true) => (
  <div className={`text-${align} table-cell ${wrap ? 'wrap' : ''}`}>
    {(instance || instance === 0) ? instance : '-'}
  </div>
);

export function cellNumOperation(instance, align) {
  return (
    <div className={`text-${align || 'right'} table-cell`}>
      <NumberFormat
        decimalSeparator={false}
        displayType="text"
        value={(instance || instance ===0)? roundInt(instance) : '-'}
        decimalScale={0}
        thousandSeparator="."
      />
    </div>
  );
}

export function cellFloatOperation(instance) {
  return (
    <div className={`text-right' table-cell`}>
      <NumberFormat
        decimalSeparator=","
        fixedDecimalScale
        displayType="text"
        value={(instance || instance ===0)? instance : '-'}
        decimalScale={2}
        thousandSeparator="."
      />
    </div>
  );
}

export const cellDateOperation = (instance, format = FRONT_DF_1) => cellOperation(
  fdate(instance, format)
);
