import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import moment from "moment";
import { Col, FormGroup, Label, Row, Input, FormFeedback, Button } from "reactstrap";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faCoins, faSave } from "@fortawesome/free-solid-svg-icons";
import WarningModal from "components/UI/WarningModal";
import {
  BASE_URL,
  API,
  FRONT_DF_1,
  BACKEND_DF,
  RESOLUCION_APROBADA,
  RESOLUCION_APROBADA_PARCIAL,
  RESOLUCION_RECHAZADA,
  PROFILES,
  RESOLUCION_BLOQUEADA,
  TIPO_MOVIMIENTO_LINEA_CREDITO
} from "CONST";
import { get, patch, post } from "helpers/requests";
import { formatRut, fdate, fileDownload, roundInt } from "helpers/helpers";
import { motivosToOption } from "helpers/selects";
import Icon from "components/UI/Icon";
import store from "redux-store/store";
import isUserHasProfile from "helpers/profileValidator";
import { RESOLUCIONES_WRITE } from "helpers/profilePermission";
import PosicionDeudorReporteConsolidadoModal from "components/solicitudes/posicionDeudor/posicionDeudorReporteConsolidadoModal";
import FileUploader from "components/shared/FileUploader";
import SafeBtn from "../shared/SafeBtn";
import CheckModal from "../shared/CheckModal";
import ComentarioResolucionTable from "./ComentarioResolucionTable";
import ComentarioResolucionModal from "./ComentarioResolucionModal";
import CarpetaResolucionModal from "./CarpetaResolucionModal";


const ResolucionForm = () => {
  const [movimiento, setMovimiento] = useState(null);
  const [optionsMotivoResolucion, setOptionsMotivoResolucion] = useState(null);
  // datos formulario
  const [warningTecho, setWarningTecho] = useState(false);
  const [cumuloDisponible, setCumuloDisponible] = useState(0);
  // datos deudor
  const [motivoResolucion, setMotivoResolucion] = useState(null);
  const [inicioVigenciaLinea, setInicioVigenciaLinea] = useState(null);
  const [finVigenciaLinea, setFinVigenciaLinea] = useState(null);
  const [inicioVigenciaPoliza, setInicioVigenciaPoliza] = useState(null);
  const [finVigenciaPoliza, setFinVigenciaPoliza] = useState(null);
  const [resolucion, setResolucion] = useState(null);
  const [comentarioAnalista, setComentarioAnalista] = useState("");
  const [comentarioAsegurado, setComentarioAsegurado] = useState("");
  const [montoOtorgado, setMontoOtorgado] = useState(0);
  const [montoSolicitado, setMontoSolicitado] = useState(0);
  const [montoVigente, setMontoVigente] = useState(0);
  const [solicitante, setSolicitante] = useState(null);
  // validación
  const [isValidMotivo, setIsValidMotivo] = useState(false);
  const [isValidInicioVigenciaLinea, setIsValidInicioVigenciaLinea] = useState(true);
  const [isValidFinVigenciaLinea, setIsValidFinVigenciaLinea] = useState(true);
  const [isValidRangoFecha, setIsValidRangoFecha] = useState(true);
  const [isValidResolucion, setIsValidResolucion] = useState(true);
  const [isValidUserAndProfile, setIsValidUserAndProfile] = useState(true);
  const [isValidMontoSolicitado, setIsValidMontoSolicitado] = useState(true);
  const [invalidMontoError, setInvalidMontoError] = useState(true);
  const [rechazadoConComentario, setRechazadoConComentario] = useState(true)
  // modal
  const [showModal, setShowModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [showResolveModal, setShowResolveModal] = useState(false);
  // modal reporte consolidado  
  const [reporteConsolidadoModal, setReporteConsolidadoModal] = useState(false);
  const [reporteConsolidadoData, setReporteConsolidadoData] = useState({});
  const [openWarningModal, setOpenWarningModal] = useState(false);
  // modal comentarios solicitud
  const [showSaveComment, setShowSaveComment] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [resolveLoading, setResolveLoading] = useState(false);

  // Notificables
  const [notificables, setNotificables] = useState([]);

  // Carpeta Tributaria
  const [carpetaTributaria, setCarpetaTributaria] = useState(null);
  const [showCarpetaModal, setShowCarpetaModal] = useState(false);
  const [showCarpetaWarning, setShowCarpetaWarning] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const resolutionData = (resolver) => {
    let montoOtorgadoResolution;
    let montoVigenteResolution;
    if (resolucion === RESOLUCION_APROBADA) {
      montoOtorgadoResolution = movimiento.linea_solicitada
      montoVigenteResolution = movimiento.linea_solicitada
    }
    else if (resolucion === RESOLUCION_APROBADA_PARCIAL) {
      montoOtorgadoResolution = montoOtorgado
      montoVigenteResolution = montoOtorgado
    }
    else {  // resolucion === RESOLUCION_RECHAZADA
      montoOtorgadoResolution = 0
      montoVigenteResolution = movimiento.monto_vigente
    }

    const data = {
      fecha_vigencia: moment(inicioVigenciaLinea, FRONT_DF_1).format(BACKEND_DF),
      fecha_fin_vigencia: moment(finVigenciaLinea, FRONT_DF_1).format(BACKEND_DF),
      comentario_analista: comentarioAnalista,
      comentario_asegurado: comentarioAsegurado,
      motivo: motivoResolucion && motivoResolucion.motivo.descripcion,
      resuelto: resolver,
      monto_otorgado: montoOtorgadoResolution,
      monto_vigente: montoVigenteResolution,
    };

    if (resolver) {
      data.fecha_respuesta = moment().format(BACKEND_DF);
    }

    if (movimiento.resolucion) {
      data.resolucion.id = movimiento.resolucion.id;
    }

    if (resolucion) {
      data.estado = resolucion;
    }

    return data;
  };

  const validateMontoSolicitado = (mov) => {
    if (mov) {
      const montoResolucion = (resolucion === RESOLUCION_APROBADA)
        ? mov.linea_solicitada
        : montoOtorgado

      if (isUserHasProfile([PROFILES.analistaRiesgoCredito])) {
        if (montoResolucion <= 10000) {
          return true;
        }
        setInvalidMontoError('No tiene permitido resolver para monto mayor a 10.000 UF.');
      }
      else if (isUserHasProfile([PROFILES.gerenteRiesgoCredito])) {
        if (montoResolucion <= 40000) {
          return true;
        }
        setInvalidMontoError('No tiene permitido resolver para monto mayor a 40.000 UF.');
      }
      else if (isUserHasProfile([PROFILES.actuariado])) {
        if (montoResolucion <= 68000) {
          return true;
        }
        setInvalidMontoError('No tiene permitido resolver para monto mayor a 68.000 UF.');
      }
      else if (isUserHasProfile([PROFILES.ceo])) {
        return true;
      }
      return false;
    }
    return true
  }

  const validateUserAndProfile = (mov) => {
    const state = store.getState();
    let analista = null;
    if (mov) {
      analista = mov.analista;
    }
    // reviso si tengo permisos administrativos y no es analista
    if (isUserHasProfile(RESOLUCIONES_WRITE) && !isUserHasProfile([PROFILES.analistaRiesgoCredito])) {
      setIsValidUserAndProfile(true);
      return;
    }
    // reviso si hay analista
    if (analista) {
      // obtengo datos del analista y datos del usuario logeado
      const userMail = state.login.user.email;
      const analistaMail = analista.user.email;

      // si el analista es el usuario loggeado
      if (userMail === analistaMail && isUserHasProfile([PROFILES.analistaRiesgoCredito])) {
        setIsValidUserAndProfile(true);
        return;
      }
    }
    setIsValidUserAndProfile(false);
  };

  const getMovimiento = (firstExecution = false) => {
    get(`${BASE_URL}${API.resolucion}/${id}/`).then((response) => {
      setMovimiento(response);
      setSolicitante(response.solicitante);
      setInicioVigenciaLinea(moment(
        response.fecha_vigencia ? response.fecha_vigencia : response.fecha_solicitud
      ));
      setFinVigenciaLinea(moment(
        response.fecha_fin_vigencia ? response.fecha_fin_vigencia : response.fecha_termino_poliza
      ));
      setInicioVigenciaPoliza(moment(response.fecha_vigencia_poliza));
      setFinVigenciaPoliza(moment(response.fecha_termino_poliza));
      if (firstExecution) {
        setComentarioAnalista(response.comentario_analista);
        setComentarioAsegurado(response.comentario_asegurado);
      }
      setMontoSolicitado(response.linea_solicitada);
      setMontoVigente(response.monto_vigente);

      if (response.estado && response.estado !== RESOLUCION_APROBADA) {
        setMontoOtorgado(response.monto_otorgado);
      }

      // Se valida usuario una vez se tenga el movimiento.
      validateUserAndProfile(response);
    });
  }

  useEffect(() => {
    setRechazadoConComentario(resolucion === RESOLUCION_APROBADA || !!(comentarioAsegurado))
  }, [resolucion, comentarioAsegurado])

  useEffect(() => {
    get(`${BASE_URL}${API.motivosResolucion}/`).then((response) =>
      setOptionsMotivoResolucion(motivosToOption(response)),
    );
    get(`${BASE_URL}${API.notificablesCredito}/`).then((response) => {
      setNotificables(response.results);
    })
    getMovimiento(true);
  }, [id]);

  useEffect(() => {
    let cumuloDisponibleTemp = cumuloDisponible;
    if (movimiento && !cumuloDisponibleTemp) {
      get(`${BASE_URL}${API.deudor}/${movimiento.id_deudor}/`).then((response) => {
        cumuloDisponibleTemp = (response.techo_vigente ?
          response.techo_vigente - response.linea_vigente + movimiento.monto_vigente : -1);
        setCumuloDisponible(cumuloDisponibleTemp);
      });
    }
    setWarningTecho(cumuloDisponibleTemp >= 0 && montoOtorgado > cumuloDisponibleTemp && montoSolicitado >= montoOtorgado);
  }, [movimiento, montoOtorgado, resolucion]);

  // Validaciones

  useEffect(() => {
    setIsValidInicioVigenciaLinea(inicioVigenciaLinea !== null);
  }, [inicioVigenciaLinea]);

  useEffect(() => {
    setIsValidFinVigenciaLinea(finVigenciaLinea !== null);
  }, [finVigenciaLinea]);

  useEffect(() => {
    setIsValidRangoFecha(inicioVigenciaLinea < finVigenciaLinea);
  }, [inicioVigenciaLinea, finVigenciaLinea]);

  useEffect(() => {
    setIsValidResolucion(resolucion !== null);
    setIsValidMontoSolicitado(validateMontoSolicitado(movimiento));
  }, [resolucion]);

  useEffect(() => {
    setIsValidMotivo(motivoResolucion !== null);
  }, [motivoResolucion]);

  useEffect(() => {
    setIsValidMontoSolicitado(validateMontoSolicitado(movimiento));
  }, [montoOtorgado]);

  const toggleModal = (event) => {
    if (event) {
      event.preventDefault();
    }
    setOpenModal(!openModal);
  };

  const toggleReporteConsolidadoModal = () => {
    setReporteConsolidadoModal(false);
  }

  const toggleWarningModal = () => {
    setOpenWarningModal(false);
  }

  const handleReporteConsolidadoModal = (idDeudor, idMovimiento) => {
    setReporteConsolidadoModal(true);
    get(`${BASE_URL}${API.reporteConsolidado}?deudor_id=${idDeudor}&movimiento_linea_credito_id=${idMovimiento}`)
      .then(({ results }) => {

        if (results && results.length === 1) {
          setReporteConsolidadoData(...results);
        }
        else {
          setReporteConsolidadoModal(false);
          setOpenWarningModal(true);
        }
      })
      .catch((error) => {
        if (error) {
          setReporteConsolidadoModal(false);
          setOpenWarningModal(true);
        }
      });
  }

  const onResolution = () => {
    const data = resolutionData(true);
    setResolveLoading(true);
    patch(`${BASE_URL}${API.riskInbox}/asignar-analista/`, [{ "id": movimiento.id }])
      .then(([response]) => {
        if (response.analista_id) {
          post(`${BASE_URL}${API.resolucion}/${id}/resolver/`, data)
            .then((secondResponse) => {
              if (secondResponse.resuelto) {
                setShowResolveModal(true);
              }
            })
            .catch(() => {
              setModalErrorMessage("Actualización de la resolución");
              setShowModal(true);
            })
            .finally(() => {
              setResolveLoading(false);
            })
        }
      })
      .catch(() => {
        setModalErrorMessage("Asignación Analista");
        setShowModal(true);
      })
  };

  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value < montoSolicitado) return inputObj;
    return null
  }

  const validInitialDates = (current) => {
    if (finVigenciaLinea > inicioVigenciaPoliza) {
      return inicioVigenciaPoliza <= current && current <= finVigenciaLinea;
    }
    return finVigenciaLinea <= current && current <= inicioVigenciaPoliza;
  }

  const validEndDates = (current) => {
    if (inicioVigenciaLinea > finVigenciaPoliza) {
      return finVigenciaPoliza <= current && current <= inicioVigenciaLinea;
    }
    return inicioVigenciaLinea <= current && current <= finVigenciaPoliza;
  }

  const handleDownload = (documentType) => {
    const baseUrl = `${BASE_URL}${API.resolucion}/${id}/`;
    const urlByType = documentType === "carpeta-tributaria" ? "tributaria" : "download";
    const url = `${baseUrl}${urlByType}`;

    fileDownload(url, "");
  };

  const actualizarCarpetaTributaria = () => {
    patch(`${BASE_URL}${API.resolucion}/${id}/update-tributaria/`, { "uuid_tributaria": carpetaTributaria.uuid })
      .then(() => {
        setShowCarpetaModal(true);
      })
      .catch(() => {
        setShowCarpetaWarning(true);
      })
  }

  const checkIsValid = () => (
    isValidInicioVigenciaLinea &&
    isValidFinVigenciaLinea &&
    isValidMontoSolicitado &&
    isValidUserAndProfile &&
    isValidRangoFecha &&
    isValidResolucion &&
    (resolucion === RESOLUCION_APROBADA || isValidMotivo) &&
    (resolucion !== RESOLUCION_APROBADA_PARCIAL || montoOtorgado > montoVigente) &&
    (resolucion !== RESOLUCION_APROBADA_PARCIAL || montoOtorgado < montoSolicitado)
  )

  return (
    movimiento && (
      <>
        <Col
          md={{ offset: 1, size: 10 }}
          style={{
            padding: 50,
            borderStyle: "solid",
            borderColor: "#CBD1D7",
            borderWidth: "1px",
            borderRadius: 10,
          }}
        >
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>ID</strong>
                </Label>
                <p>{movimiento.id}</p>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Asegurado</strong>
                </Label>
                <p>{movimiento.asegurado.nombre}</p>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Deudor</strong>
                </Label>
                <p>{movimiento.nombre_deudor}</p>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>RUT Deudor</strong>
                </Label>
                <p>{formatRut(movimiento.rut_deudor)}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Monto Solicitado</strong>
                </Label>
                <div>
                  <NumberFormat
                    decimalScale="0"
                    decimalSeparator={false}
                    displayType="text"
                    value={roundInt(movimiento.linea_solicitada)}
                    thousandSeparator="."
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Tipo Solicitante</strong>
                </Label>
                <div>
                  {solicitante && (
                    <p>{movimiento.solicitante.organization.is_main ? 'Interno' : 'Externo'}</p>
                  ) || '-'}
                </div>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Resolución Motor</strong>
                </Label>
                <div>
                  {movimiento.razones_motor_riesgo.length ? (
                    <li>
                      {movimiento.razones_motor_riesgo.map((razonMotor) => <li key={razonMotor}>{razonMotor}</li>)}
                    </li>
                  ) : null}
                </div>
              </FormGroup>
            </Col>




            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Solicitante</strong>
                </Label>
                <div>
                  {solicitante && (
                    <p>
                      {`${solicitante.user.first_name} ${solicitante.user.last_name}`}
                    </p>
                  ) || '-'}
                </div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Moneda</strong>
                </Label>
                <p>UF</p>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Score Motor</strong>
                </Label>
                <div>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale
                    decimalSeparator=","
                    displayType="text"
                    value={movimiento.score || "---"}
                    thousandSeparator="."
                  />
                </div>
              </FormGroup>
            </Col>
            {(movimiento.carpeta_tributaria_id || movimiento.documentos_ids.length > 0) && (
              <Col md={3}>
                <FormGroup>
                  <Label>
                    <strong>Documentos</strong>
                  </Label>
                  <div>
                    {movimiento.carpeta_tributaria_id && (
                      <div style={{ display: "flex" }}>
                        <p>Carpeta tributaria</p>
                        <Icon
                          id="carpeta-tributaria-download"
                          handleOnClick={() => handleDownload("carpeta-tributaria")}
                          includeToolTip={false}
                          tooltipText=""
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </Icon>
                      </div>
                    )}
                    {movimiento.documentos_ids.length > 0 && (
                      <div style={{ display: "flex" }}>
                        <p>Otros</p>
                        <Icon
                          id="documentos-download"
                          handleOnClick={() => handleDownload()}
                          includeToolTip={false}
                          tooltipText=""
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </Icon>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            )}
            <Col md={3}>
              <Label>
                <strong>Reporte Consolidado</strong>
              </Label>
              <p>
                <Button
                  id='reporteConsolidado'
                  className="table-link"
                  onClick={() => handleReporteConsolidadoModal(movimiento.id_deudor, movimiento.id)}
                >
                  <FontAwesomeIcon icon={faCoins} />
                </Button>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Fecha Solicitud</strong>
                </Label>
                <p>{fdate(movimiento.fecha_solicitud)}</p>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <strong>Fecha Vigencia Póliza</strong>
                </Label>
                <p>{fdate(movimiento.fecha_vigencia_poliza)}</p>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="fechaInicioVigenciaLinea">
                  <strong>Inicio Vigencia Línea</strong>
                  <span className="required text-danger">*</span>
                </Label>
                <Datetime
                  id="fechaInicioVigenciaLinea"
                  onChange={(value) => setInicioVigenciaLinea(value)}
                  value={inicioVigenciaLinea}
                  dateFormat={FRONT_DF_1}
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{ required: true }}
                  isValidDate={validInitialDates}
                />
                {!isValidInicioVigenciaLinea && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Ingrese una fecha.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="fechaFinVigenciaLinea">
                  <strong>Fin Vigencia Línea</strong>
                  <span className="required text-danger">*</span>
                </Label>
                <Datetime
                  id="fechaFinVigenciaLinea"
                  onChange={(value) => setFinVigenciaLinea(value)}
                  value={finVigenciaLinea}
                  dateFormat={FRONT_DF_1}
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{ required: true }}
                  isValidDate={validEndDates}
                />
                {!isValidFinVigenciaLinea && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Ingrese una fecha.
                  </FormFeedback>
                )}
                {!isValidRangoFecha && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Fecha de fin vigencia debe ser mayor a inicio vigencia.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ width: '100%' }}>
                <Label for="carpetaTributaria">
                  <strong>Actualizar Carpeta Tributaria</strong>
                </Label>
                <div style={{ display: 'flex' }}>
                  <FileUploader
                    inputId="carpeta-tributaria-id"
                    uploadUrl={`${BASE_URL}${API.uploadDocumento}/`}
                    inputText="Seleccione un archivo (.pdf)"
                    accept=".pdf"
                    handleResponse={(file) => setCarpetaTributaria(file)}
                    clearable
                    filename={carpetaTributaria ? carpetaTributaria.file : ''}
                    prefijo={movimiento.rut_deudor}
                    onClearFile={() => setCarpetaTributaria(null)}
                  />
                  {carpetaTributaria && (
                    <Button
                      onClick={() => actualizarCarpetaTributaria()}
                      className="table-link download"
                      style={{ marginLeft: '5px' }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <hr style={{ marginTop: 40, marginBottom: 40 }} />
          <Label>
            <strong>Comentarios</strong>
          </Label>
          <ComentarioResolucionTable
            data={movimiento.comentarios}
          />
          <Col md={{ offset: 10 }}>
            <Row>
              <Col>
                <SafeBtn
                  outline
                  className="btn-orsan"
                  block
                  onClick={toggleModal}
                >
                  Agregar comentario
                </SafeBtn>
              </Col>
            </Row>
          </Col>
          <hr style={{ marginTop: 40, marginBottom: 40 }} />
          <Row>
            <Col style={{ paddingLeft: 30 }}>
              <FormGroup>
                <Label check>
                  <Input
                    type="radio"
                    value="aprobado"
                    checked={resolucion === RESOLUCION_APROBADA}
                    onChange={(event) => {
                      setResolucion(event.target.value);
                      setMotivoResolucion(null);
                      setMontoOtorgado(montoSolicitado);
                    }}
                  />
                  Aprobado
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label check>
                  <Input
                    type="radio"
                    value="aprobado-parcial"
                    checked={resolucion === RESOLUCION_APROBADA_PARCIAL}
                    onChange={(event) => {
                      setResolucion(event.target.value);
                      setMotivoResolucion(null);
                    }}
                  />
                  Aprobado Parcial
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check className="radio-with-input-at-the-end">
                <Label check>
                  <Input
                    type="radio"
                    value="rechazado"
                    checked={resolucion === RESOLUCION_RECHAZADA}
                    onChange={(event) => {
                      setResolucion(event.target.value);
                      setMotivoResolucion(null);
                      setMontoOtorgado(0);
                    }}
                  />
                  Rechazado
                </Label>
              </FormGroup>
            </Col>
            {movimiento.tipo_descripcion === TIPO_MOVIMIENTO_LINEA_CREDITO.desbloqueo && (
              <Col>
                <FormGroup check className="radio-with-input-at-the-end">
                  <Label check>
                    <Input
                      type="radio"
                      value="bloqueado"
                      checked={resolucion === RESOLUCION_BLOQUEADA}
                      onChange={(event) => {
                        setResolucion(event.target.value);
                        setMotivoResolucion(null);
                        setMontoOtorgado(0);
                      }}
                    />
                    Bloqueado
                  </Label>
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup>
                <Label for="motivo">Motivo</Label>
                <Select
                  id="motivo"
                  name="motivo"
                  className="custom-policy-select"
                  options={optionsMotivoResolucion}
                  onChange={(event) => {
                    setMotivoResolucion(event);
                  }}
                  value={motivoResolucion}
                  placeholder="Seleccione Motivo"
                  isDisabled={resolucion === RESOLUCION_APROBADA}
                />
                {resolucion && resolucion !== RESOLUCION_APROBADA && !isValidMotivo && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Ingrese un motivo.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="monto-parcial">Monto Parcial</Label>
                <div>
                  <NumberFormat
                    id="monto-parcial"
                    className="form-control"
                    decimalScale="0"
                    decimalSeparator={false}
                    thousandSeparator="."
                    allowNegative={false}
                    value={montoOtorgado}
                    onValueChange={(values) => {
                      setMontoOtorgado(values.floatValue);
                    }}
                    isAllowed={withValueLimit}
                    disabled={resolucion !== RESOLUCION_APROBADA_PARCIAL}
                  />
                </div>
                {resolucion === RESOLUCION_APROBADA_PARCIAL && montoOtorgado <= montoVigente && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Monto parcial debe ser mayor a monto vigente. (
                    {roundInt(montoVigente)}
                    )
                  </FormFeedback>
                )}
                {resolucion === RESOLUCION_APROBADA_PARCIAL && montoOtorgado >= montoSolicitado && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Monto parcial debe ser menor a monto solicitado. (
                    {roundInt(montoSolicitado)}
                    )
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: '0.5rem' }}>
            <Col md={6}>
              <FormGroup>
                <Label for="comentario-analista">Comentario Analista</Label>
                <Input
                  id="comentario-analista"
                  type="textarea"
                  value={comentarioAnalista || ""}
                  onChange={(event) => setComentarioAnalista(event.target.value)}
                />
                {!comentarioAnalista && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Debe agregar un comentario de analista.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="comentario-asegurado">Comentario al Asegurado</Label>
                <Input
                  id="comentario-asegurado"
                  type="textarea"
                  value={comentarioAsegurado || ""}
                  onChange={(event) => setComentarioAsegurado(event.target.value)}
                />
                {!rechazadoConComentario && (
                  <FormFeedback style={{ fontSize: "12px" }}>
                    Debe agregar un comentario al asegurado.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Col md={{ offset: 10 }} style={{ marginTop: 35 }}>
            <Row>
              <Col>
                <SafeBtn
                  loading={resolveLoading}
                  className="warning-accept w-100"
                  block
                  onClick={() => onResolution()}
                  disabled={
                    !checkIsValid() ||
                    warningTecho ||
                    !comentarioAnalista ||
                    !rechazadoConComentario
                  }
                  text=" Resolviendo"
                >
                  Resolver
                </SafeBtn>
              </Col>
            </Row>
            {warningTecho && (
              <FormFeedback style={{ fontSize: "12px" }}>
                Monto solicitado mayor a cúmulo disponible del deudor.
              </FormFeedback>
            )}
            {!isValidUserAndProfile && (
              <FormFeedback style={{ fontSize: "12px" }}>
                No es el analista asignado para resolver esta línea.
              </FormFeedback>
            )}
            {!isValidMontoSolicitado && (
              <FormFeedback style={{ fontSize: "12px" }}>
                <span>{invalidMontoError}</span>
              </FormFeedback>
            )}
            {!isValidResolucion && (
              <FormFeedback style={{ fontSize: "12px" }}>
                Ingrese un estado de resolución.
              </FormFeedback>
            )}
          </Col>
        </Col>
        <CheckModal
          isOpen={showResolveModal}
          toggle={() => history.push("/solicitudes/inbox-riesgos")}
          handleOk={() => history.push("/solicitudes/inbox-riesgos")}
          okBtnText="OK"
          confirmMsg="Solicitud Resuelta Satisfactoriamente."
        />
        <CheckModal
          isOpen={showSaveComment}
          toggle={() => setShowSaveComment(false)}
          handleOk={() => setShowSaveComment(false)}
          okBtnText="OK"
          confirmMsg="Comentario Guardado Satisfactoriamente."
        />
        <CarpetaResolucionModal
          isOpen={showCarpetaModal}
          toggle={() => history.push(`/solicitudes/inbox-riesgos/${movimiento.id}`)}
          handleOk={() => history.push("/solicitudes/inbox-riesgos")}
        />
        <WarningModal
          isOpen={showCarpetaWarning}
          toggle={() => setShowCarpetaWarning(false)}
          handleOk={() => {
            setShowCarpetaWarning(false);
          }}
          okBtnText="Aceptar"
          confirmMsg="Ha ocurrido un error al actualizar la carpeta tributaria"
          onlyCancelButton
        />
        <WarningModal
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          handleOk={() => {
            setShowModal(false);
          }}
          okBtnText="Aceptar"
          confirmMsg={`Ha ocurrido un error al guardar resolución: ${modalErrorMessage}`}
          onlyCancelButton
        />
        <ComentarioResolucionModal
          openModal={openModal}
          toggleModal={toggleModal}
          movimientoId={movimiento.id}
          getMovimiento={getMovimiento}
          notificables={notificables}
          rutDeudor={movimiento.rut_deudor}
          setShowSaveComment={setShowSaveComment}
        />
        <PosicionDeudorReporteConsolidadoModal
          isOpen={reporteConsolidadoModal}
          reporteConsolidadoData={reporteConsolidadoData}
          toggle={toggleReporteConsolidadoModal}
        />
        <WarningModal
          isOpen={openWarningModal}
          toggle={toggleWarningModal}
          handleOk={() => setOpenWarningModal(false)}
          loading={false}
          confirmMsg="Reporte Consolidado inexistente"
          cancelBtnText="OK"
          onlyCancelButton
        />
      </>
    )
  );
};

export default ResolucionForm;
