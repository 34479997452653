import React from 'react';
import PropType from 'prop-types';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';

import { FRONT_DF_1 } from '../../../../CONST';
import { fdate, formatRut, roundInt } from '../../../../helpers/helpers';

const AseguradosDetalleData = ({ poliza }) => (
  <Row
    style={{ margin: '1rem', borderStyle: 'solid', padding: '1.5rem 2rem', borderWidth: 'thin' }}
  >
    <Col sm={7} style={{ borderStyle: 'none solid none none', borderWidth: 'thin' }}>
      <Row>
        <Col>
          <FormGroup row>
            <Label sm={5} for="rutAsegurado">
              RUT Asegurado
            </Label>
            <p id="rutAsegurado">{poliza.base && formatRut(poliza.base.asegurado.rut)}</p>
          </FormGroup>
          <FormGroup row>
            <Label sm={5} for="razonSocial">
              Razón social
            </Label>
            <Col style={{ padding: 0 }}>
              <p id="razonSocial">{poliza.base && poliza.base.asegurado.nombre}</p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={5} for="rubro">
              Rubro
            </Label>
            <Col style={{ padding: 0 }}>
              <p id="rubro">{poliza.base && poliza.base.actividad_economica.descripcion}</p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={5} for="materialAsegurada">
              Materia asegurada
            </Label>
            <Col style={{ padding: 0 }}>
              <p id="materialAsegurada">{poliza.balance && poliza.balance.glosa}</p>
            </Col>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup row>
            <Label sm={6} for="estado">
              Estado
            </Label>
            <p id="estado">{poliza.estado_poliza}</p>
          </FormGroup>
          <FormGroup row>
            <Label sm={6} for="nPoliza">
              Nº póliza
            </Label>
            <p id="nPoliza">{poliza.base && poliza.base.nemotecnico}</p>
          </FormGroup>
          <FormGroup row>
            <Label sm={6} for="fechaInicio">
              Fecha inicio vigencia
            </Label>
            <p id="fechaInicio">
              {poliza.balance && fdate(poliza.balance.inicio_vigencia, FRONT_DF_1)}
            </p>
          </FormGroup>
          <FormGroup row>
            <Label sm={6} for="fechaTermino">
              Fecha término vigencia
            </Label>
            <p id="fechaTermino">
              {poliza.balance && fdate(poliza.balance.termino_vigencia, FRONT_DF_1)}
            </p>
          </FormGroup>
        </Col>
      </Row>
    </Col>
    <Col>
      <Row>
        <Col>
          <FormGroup row>
            <Label sm={8} for="coberturaNominados">
              Cobertura nominados
            </Label>
            <p id="coberturaNominados">
              {poliza.balance && poliza.balance.cobertura_nominados_pct ? (
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale
                  decimalSeparator=","
                  displayType="text"
                  value={poliza.balance.cobertura_nominados_pct.cobertura_pct}
                  thousandSeparator="."
                  suffix=" %"
                />
              ) : (
                '-'
              )}
            </p>
          </FormGroup>
          <FormGroup row>
            <Label sm={8} for="coberturaInnominados">
              Cobertura innominados
            </Label>
            <p id="coberturaInnominados">
              {poliza.balance && poliza.balance.cobertura_innominados_pct ? (
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale
                  decimalSeparator=","
                  displayType="text"
                  value={poliza.balance.cobertura_innominados_pct.cobertura_pct}
                  thousandSeparator="."
                  suffix=" %"
                />
              ) : (
                '-'
              )}
            </p>
          </FormGroup>
          <FormGroup row>
            <Label sm={8} for="plazoMaximo">
              Plazo máximo venta
            </Label>
            <p id="plazoMaximo">
              {poliza.balance && `${poliza.balance.plazo_maximo_credito_dias} días`}
            </p>
          </FormGroup>
          <FormGroup row>
            <Label sm={8} for="corteInnominado">
              Corte innominado
            </Label>
            <p id="corteInnominado">
              {poliza.balance && (
                <NumberFormat
                  decimalSeparator={false}
                  displayType="text"
                  value={roundInt(poliza.balance.maximo_innominados_amt)}
                  thousandSeparator="."
                  suffix=" UF"
                />
              )}
            </p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup row>
            <Label sm={8} for="umbralSiniestro">
              Umbral siniestro
            </Label>
            <p id="umbralSiniestro">
              {poliza.balance && (
                <NumberFormat
                  decimalSeparator={false}
                  displayType="text"
                  value={roundInt(poliza.balance.umbral_cobertura_amt)}
                  thousandSeparator="."
                  suffix=" UF"
                />
              )}
            </p>
          </FormGroup>
          <FormGroup row>
            <Label sm={8} for="gestionesPropias">
              Gestiones propias
            </Label>
            <p id="gestionesPropias">
              {poliza.balance && `${poliza.balance.plazo_gestiones_propias_dias.dias} días`}
            </p>
          </FormGroup>
          <FormGroup row>
            <Label sm={8} for="ventaAsegurada">
              Venta asegurada
            </Label>
            <p id="ventaAsegurada">
              {poliza.balance && (
                <NumberFormat
                  decimalSeparator={false}
                  displayType="text"
                  thousandSeparator="."
                  value={roundInt(poliza.balance.monto_asegurado_estimado_amt)}
                  suffix=" UF"
                />
              )}
            </p>
          </FormGroup>
          <FormGroup row>
            <Label sm={8} for="tasa">
              Tasa
            </Label>
            <p id="tasa">
              {poliza.balance && (
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale
                  decimalSeparator=","
                  displayType="text"
                  value={poliza.balance.tasa_costo_seguro_defecto_pct}
                  thousandSeparator="."
                  suffix=" %"
                />
              )}
            </p>
          </FormGroup>
        </Col>
      </Row>
    </Col>
  </Row>
);

AseguradosDetalleData.propTypes = {
  poliza: PropType.shape().isRequired,
};

export default AseguradosDetalleData;
