import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faTasks,
  faEdit,
  faHistory,
  faExclamationCircle,
  faCoins,
  faComments,
  } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { POSICION_DEUDOR_WRITE } from 'helpers/profilePermission';
import isUserHasProfile from 'helpers/profileValidator';
import useTableFilters from 'helpers/useTableFilters';
import { FRONT_DF_1, BASE_URL, API, FILTROS_TEXTO, ESTADO_LINEA, ESTADOS_MOVIMIENTOS } from 'CONST';
import FilterSelect from 'components/shared/FilterSelect';
import { get } from '../../../helpers/requests';
import ComentariosModal from './comentariosModal';
import PosicionDeudorModal from './posicionDeudorModal';
import PosicionDeudorHistoryModal from './posicionDeudorHistoryModal';
import PosicionDeudorLegendModal from './posicionDeudorLegendModal';
import PosicionDeudorReporteConsolidadoModal from './posicionDeudorReporteConsolidadoModal';
import Icon from '../../UI/Icon';
import { getIconInfo } from "../../../helpers/helpersMotor";
import WarningModal from '../../UI/WarningModal';
import {
  headerOperation,
  headerDoubleRowOperation,
  cellOperation,
  cellNumOperation,
} from '../../../helpers/tables';
import BaseTable from '../../shared/BaseTable';
import DownloadBtn from '../../shared/DownloadBtn';
import { fdate } from '../../../helpers/helpers';

const PosicionDeudorTable = ({ id, edit }) => {
  const DEFAULT_VALUE = -1;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalComments, setModalComments] = useState(false);
  const [modalHistoryOpen, setModalHistoryOpen] = useState(false);
  const [modalLegendOpen, setModalLegendOpen] = useState(false);
  const [movimiento, setMovimiento] = useState();
  const [linea, setLinea] = useState();
  const [botones, setBotones] = useState({
    'Todas las líneas': true,
    'Pendientes': false,
    'Vigentes': false,
  });
  const [reporteConsolidadoModal, setReporteConsolidadoModal] = useState(false);
  const [reporteConsolidadoData, setReporteConsolidadoData] = useState({});
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [estadosLinea, setEstadosLinea] = useState([]);
  const todas = {
    texto: FILTROS_TEXTO.todas,
    busqueda: FILTROS_TEXTO.historicas,
  }
  const vigentes = {
    texto: FILTROS_TEXTO.vigentes,
  };
  const pendientes = {
    texto: FILTROS_TEXTO.pendientes,
    busqueda: `${ESTADO_LINEA.pendiente}`,
  };

  const [queryParams, setQueryParams] = useState(`linea_credito__deudor_id=${id}&historicas=true`);
  const [onFilteredChange, onFetchData, loading, data, getData] = useTableFilters(
    `${BASE_URL}${API.balanceCredito}`,
    false,
    queryParams,
    [],
    0,
  );

  const tableRef = useRef(null);

  const toggleModal = (record) => {
    setModalOpen(!modalOpen);
    setMovimiento(record);
  };

  const toggleHistoryModal = (record) => {
    setModalHistoryOpen(!modalHistoryOpen);
    setLinea(record && record.linea_credito);
  };

  const toggleLegendModal = () => {
    setModalLegendOpen(false);
  };

  useEffect(() => {
    get(`${BASE_URL}${API.estadosLinea}`).then((response) => {
      setEstadosLinea(response.results);
    });
  }, []);

  useEffect(() => {
    getData(queryParams);
  }, [edit]);

  const onAcceptModal = () => {
    setModalOpen(!modalOpen);
    tableRef.current.state.page = 0;
    onFetchData(tableRef.current.state);
  };

  const changeButtons = (text) => {
    setBotones({
      'Todas las líneas': text === FILTROS_TEXTO.todas,
      'Pendientes': text === FILTROS_TEXTO.pendientes,
      'Vigentes': text === FILTROS_TEXTO.vigentes,
    });
  };

  const toggleReporteConsolidadoModal = () => {
    setReporteConsolidadoModal(false);
  };

  const toggleWarningModal = () => {
    setOpenWarningModal(false);
  };

  const handleReporteConsolidadoModal = (idDeudor, idMovimiento) => {
    setReporteConsolidadoModal(true);
    get(
      `${BASE_URL}${API.reporteConsolidado}?deudor_id=${idDeudor}&movimiento_linea_credito_id=${idMovimiento}`,
    )
      .then(({ results }) => {
        if (results && results.length === 1) {
          setReporteConsolidadoData(...results);
        } else {
          setReporteConsolidadoModal(false);
          setOpenWarningModal(true);
        }
      })
      .catch((error) => {
        if (error) {
          setReporteConsolidadoModal(false);
          setOpenWarningModal(true);
        }
      });
  };

  const columns = [
    {
      Header: headerOperation('ID'),
      id: 'linea_credito__id',
      accessor: 'linea_credito.id',
      Cell: (row) => cellOperation(row.value, 'right'),
      minWidth: 100,
      sortable: false,
    },
    {
      Header: headerOperation('Asegurado'),
      id: 'linea_credito__cliente',
      accessor: 'asegurado_nombre',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 240,
      sortable: false,
    },
    {
      Header: headerOperation('Póliza'),
      id: 'poliza',
      accessor: 'poliza',
      Cell: (row) => cellOperation(row.value),
      minWidth: 140,
      sortable: false,
    },
    {
      Header: headerOperation('Moneda'),
      Cell: () => cellOperation('UF', 'right'),
      minWidth: 75,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Monto', 'solicitado'),
      id: 'monto_solicitado',
      accessor: 'linea_solicitada',
      Cell: (row) => cellNumOperation(row.value || 0),
      minWidth: 130,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Monto', 'aprobado'),
      id: 'monto_otorgado',
      accessor: 'monto_otorgado',
      Cell: (row) => cellNumOperation(row.value || 0),
      minWidth: 130,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Monto', 'vigente'),
      id: 'monto_vigente',
      accessor: 'monto_vigente',
      Cell: (row) => cellNumOperation(row.value || 0),
      minWidth: 130,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Tipo', 'Deudor'),
      id: 'tipo_deudor_name',
      accessor: 'tipo_deudor_name',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 130,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Estado línea'),
      id: 'estado_linea__id',
      accessor: 'estado_linea.nombre',
      Cell: (row) => cellOperation(row.value, 'center'),
      Filter: (obj) => (
        <FilterSelect
          filter={obj.filter}
          onFilterChange={obj.onChange}
          options={estadosLinea.map((elem) => ({ label: elem.nombre, value: elem.id }))}
          defaultOption={{ label: 'Todos', value: DEFAULT_VALUE }}
        />
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      Header: headerOperation('Evaluación'),
      id: 'estado__descripcion',
      accessor: 'estado_descripcion',
      Cell: (row) =>
        getIconInfo(row),
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'solicitud'),
      id: 'fecha_solicitud',
      accessor: 'fecha_solicitud',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1)),
      minWidth: 125,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'resolución'),
      id: 'fecha_respuesta',
      accessor: 'fecha_respuesta',
      Cell: (row) => {
        if (row.original.linea_credito.estado.nemotecnico === ESTADOS_MOVIMIENTOS.pendiente &&
          row.original.is_balance
        ) {
          return cellOperation('-');
        }
        return cellOperation(fdate(row.value, FRONT_DF_1))
      },
      minWidth: 125,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha inicio', 'cobertura'),
      id: 'fecha_vigencia',
      accessor: 'fecha_vigencia',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1)),
      minWidth: 125,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Fin de', 'cobertura'),
      id: 'fecha_fin_cobertura',
      accessor: 'fecha_fin_vigencia',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1)),
      minWidth: 125,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Analista ', 'asignado'),
      id: 'analista_asignado',
      accessor: 'analista',
      Cell: (row) =>
        cellOperation(
          row.value && row.value.user
            ? `${row.value.user.first_name} ${row.value.user.last_name}`
            : '-',
          'right',
        ),
      minWidth: 150,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Comentarios', 'y Motivos'),
      id: 'comentarios',
      accessor: 'motivo',
      Cell: (row) =>
        cellOperation(
          <div>
            <Icon
              id={`comentario-analista-icon-${row.original.id}`}
              tooltipText="Comentarios"
              handleOnClick={() => {
                setModalComments(true);
                setMovimiento(row.original);
              }}
            >
              <FontAwesomeIcon icon={faComments} />
            </Icon>
          </div>,
        ),
      minWidth: 140,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'acciones',
      fixed: 'right',
      Cell: ({ original }) =>
        cellOperation(
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '5px' }}
            className="table-cell"
          >
            <div style={{ textAlign: 'center' }} title="Reporte Consolidado">
              {!(
                (original.tipo_solicitud === null && original.estado_linea.nombre === 'Bloqueado') ||
                (original.tipo_solicitud === null && original.estado_linea.nombre === 'Reducido') ||
                (original.tipo_solicitud === null && original.estado_linea.nombre === 'Deshabilitada')
              ) && (
                <Button
                  className="table-link"
                  id="reporteConsolidado"
                  onClick={() => handleReporteConsolidadoModal(original.id_deudor, original.id)}
                >
                  <FontAwesomeIcon icon={faCoins} />
                </Button>
              )}
            </div>
            <div style={{ textAlign: 'center' }} title="Histórico">
              <Button
                className="table-link"
                id="iconAcciones"
                onClick={() => toggleHistoryModal(original)}
              >
                <FontAwesomeIcon icon={faHistory} />
              </Button>
            </div>
            {isUserHasProfile(POSICION_DEUDOR_WRITE) &&
              !botones['Históricas'] &&
              original.is_balance &&
              (original.linea_credito.estado &&
                original.linea_credito.estado.nombre !== ESTADO_LINEA.pendiente ? (
                  <div style={{ textAlign: 'center' }} title="Acciones Línea">
                    <Button
                      className="table-link"
                      id="iconAcciones"
                      onClick={() => toggleModal(original)}
                    >
                      <FontAwesomeIcon icon={faTasks} />
                    </Button>
                  </div>
              ) : (
                <div style={{ textAlign: 'center' }} title="Resolver Solicitud">
                  <Link to={`/solicitudes/inbox-riesgos/${original.id}`} className="table-link">
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                </div>
              ))}
          </div>,
        ),
      minWidth: 160,
      filterable: false,
      sortable: false,
    },
  ];

  const handleFilterButton = (params, texto) => {
    setQueryParams(params);
    tableRef.current.props.innerRef.current.state.newParams = params;
    tableRef.current.props.innerRef.current.state.page = 0;
    onFetchData(tableRef.current.props.innerRef.current.state);
    changeButtons(texto);
  };

  const FilterButton = (object) => {
    const { texto } = object.estado;
    let params = ``;
    if (object.estado && object.estado.texto === FILTROS_TEXTO.todas) {
      params = `linea_credito__deudor_id=${id}&historicas=true`;
    } else if (object.estado?.texto === FILTROS_TEXTO.vigentes) {
      params = `linea_credito__deudor_id=${id}`;
    } else {
      params = object.estado
        ? `linea_credito__deudor_id=${id}&linea_credito__estado__nombre__in=${object.estado.busqueda}`
        : `linea_credito__deudor_id=${id}`;
    }

    return (
      <Button
        className={object.botones[texto] ? 'btn-orsan-outline' : 'btn-orsan'}
        style={{
          marginLeft: '0.5rem',
          width: '10rem',
          height: '26px',
          padding: '0',
        }}
        onClick={() => handleFilterButton(params, texto)}
      >
        {texto}
      </Button>
    );
  };

  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <div style={{ marginBottom: '1rem' }}>
        Mostrar:
        <FilterButton estado={todas} botones={botones} />
        <FilterButton estado={pendientes} botones={botones} />
        <FilterButton estado={vigentes} botones={botones} />
        <div style={{ display: 'inline-block', float: 'right' }}>
          <DownloadBtn
            width="auto"
            id="xlsx-download"
            text={`Descarga ${Object.keys(botones).filter((key) => botones[key])}`}
            tipText={`Descarga ${Object.keys(botones).filter((key) => botones[key])}`}
            url={`${BASE_URL}${API.movimientoLineaCredito
              }historial-movimientos-deudor/?deudor=${id}&tipo_descarga=${Object.keys(botones).filter(
                (key) => botones[key],
              )}`}
          />
        </div>
      </div>
      <BaseTable
        tableRef={tableRef}
        columns={columns}
        data={data.data}
        loading={loading}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
        pages={data.pages}
        resultCount={data.count}
        resizable={false}
        filterable
        showPagination
        noBorders
        hasFixedColumns
      />
      <ComentariosModal movimiento={movimiento} openModal={modalComments} toggleModal={() => setModalComments(false)} />
      <PosicionDeudorModal
        openModal={modalOpen}
        toggleModal={toggleModal}
        movimiento={movimiento}
        onAcceptModal={onAcceptModal}
      />
      <PosicionDeudorHistoryModal
        openModal={modalHistoryOpen}
        toggleModal={toggleHistoryModal}
        linea={linea}
      />
      <PosicionDeudorLegendModal openModal={modalLegendOpen} toggleModal={toggleLegendModal} />
      <PosicionDeudorReporteConsolidadoModal
        isOpen={reporteConsolidadoModal}
        toggle={toggleReporteConsolidadoModal}
        reporteConsolidadoData={reporteConsolidadoData}
      />
      <WarningModal
        isOpen={openWarningModal}
        toggle={toggleWarningModal}
        handleOk={() => setOpenWarningModal(false)}
        loading={false}
        confirmMsg="Reporte Consolidado inexistente"
        cancelBtnText="OK"
        onlyCancelButton
      />
    </div>
  );
};

PosicionDeudorTable.propTypes = {
  id: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
};

export default PosicionDeudorTable;
