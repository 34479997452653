import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
// import { connect } from 'react-redux';
import { fileDownload } from '../../helpers/helpers';
import SafeBtn from './SafeBtn';

const DownloadBtn = ({ id, text, tipText, url, className, width }) => {
  const [ loading, setLoading ] = useState(false);
  const stopLoading = () => setLoading(false);

  return (
    <>
      <SafeBtn
        loading={loading}
        id={id}
        className={className}
        style={{
          width,
          fontSize: '16px'
        }}
        disabled={!url}
        onClick={() => {
          setLoading(true);
          fileDownload(url, '', 'GET', null, stopLoading);
        }}
      >
        <FontAwesomeIcon icon={faDownload} />
        &nbsp;&nbsp;
        {text}
      </SafeBtn>
      <UncontrolledTooltip target={id}>
        {tipText}
      </UncontrolledTooltip>
    </>
  )
};

DownloadBtn.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tipText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
};

DownloadBtn.defaultProps = {
  className: 'btn-orsan-primary',
  width: '170px',
};

export default DownloadBtn;
