import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import PosicionDeudorAccordion from '../posicionDeudorAccordion';

const JsonComponent = ({ data, key }) => {
  if (!!data && data.constructor === Object) {
    return (
      <div style={{ "border": "solid 1px rgb(49, 94, 144)", "padding": "5px" }}>
        {Object.keys(data).map((property) => {
          const currentProperty = data[property]
          if (!!currentProperty && (currentProperty.constructor === Object || Array.isArray(currentProperty))) {
            const sections = [{
              name: `${property}`,
              component: (
                <JsonComponent data={currentProperty} />),
              isValid: true,
              expansible: true,
            }]
            return (<PosicionDeudorAccordion sections={sections} />)
          }
          return (
            <Row key={`${property}}`}>
              <Col>
                <p>{property}</p>
              </Col>
              <Col>
                {currentProperty}
              </Col>
            </Row>
          )
        })}
      </div>
    )
  }
  if (Array.isArray(data)) {
    const sections = data.map((element, index) => ({
        name: `${index}`,
        component: (
          <JsonComponent data={element} />),
        isValid: true,
        expansible: true,
      })
    );
    return <div style={{ "border": "solid 1px rgb(49, 94, 144)", "padding": "5px" }}><PosicionDeudorAccordion sections={sections} /></div>
  }

  return (
    <Row key={`${key}-${data}`}>
      <Col>
        <p>{key}</p>
      </Col>
      <Col>
        <p>{data || '---'}</p>
      </Col>
    </Row>
  )

};

JsonComponent.defaultProps = {
  key: '',
}


JsonComponent.propTypes = {
  data: PropTypes.any.isRequired,
  key: PropTypes.string,
};

export default JsonComponent;