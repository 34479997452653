import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import TimeDate from './TimeDate';

const PageContainer = ({ children, mainAction, breadcrumbs }) => (
  <div>
    <TimeDate />
    <div style={{display: 'grid', gridTemplateColumns: '3fr 1fr'}}>
      <Breadcrumbs arrayMap={breadcrumbs} />
      <div style={{paddingTop: '12px', paddingRight: '4%'}}>
        {mainAction && (
          <Link
            className="btn btn-orsan"
            disabled={false}
            to={mainAction.url}
            onClick={mainAction.onClick}
            style={{maxWidth: '300px', float: 'right'}}
          >
            {mainAction.caption}
          </Link>
        )}
      </div>
    </div>
    {children}
    <div className="footer text-left">
      {`© ${moment().year()} ORSAN. Encomenderos 113, Of. 801 Las Condes.`}
    </div>
  </div>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  mainAction: PropTypes.shape({
    url: PropTypes.string,
    caption: PropTypes.string,
    onClick: PropTypes.func,
  }),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
};

PageContainer.defaultProps = {
  mainAction: null,
};

export default PageContainer;
