import React from 'react';
import PropTypes from 'prop-types'
import BaseTable from "components/UI/BaseTable";
import { Button, Col, Row } from 'reactstrap';
import { BASE_URL, API } from "CONST";
import { headerOperation, cellOperation, cellDateOperation } from "helpers/tables";
import { fileDownload } from "helpers/helpers";

const ComentarioResolucionTable = ({ data = [] }) => {
    const columns = [
        {
            Header: headerOperation('Fecha', 'center'),
            id: "fecha",
            accessor: "fecha_creacion",
            Cell: (row) => cellDateOperation(row.value, 'HH:mm - DD/MM/YYYY'),
            minWidth: 150,
        },
        {
            Header: headerOperation('Analista', 'center'),
            id: "analista",
            accessor: "nombre_author",
            Cell: (row) => cellOperation(row.value),
            minWidth: 200,
        },
        {
            Header: headerOperation('Comentario', 'center'),
            id: "comentario",
            accessor: "comentario",
            Cell: (row) => cellOperation(row.value),
            minWidth: 500,
        },
        {
            Header: headerOperation('Documentos', 'center'),
            id: "documentos",
            accessor: "documentos_data",
            Cell: (row) => cellOperation(
              <Col>
                {row.value.map(r => (
                  <Row key={r.id} style={{ justifyContent: 'center' }}>
                    <Button
                      className="table-link"
                      onClick={() => fileDownload(`${BASE_URL}${API.downloadDocumento}/`, r.id)}
                    >
                      {r.original_name}
                    </Button>
                  </Row>
                ))}
              </Col>
            ),
            minWidth: 250,
        },
        {
            Header: headerOperation('Notificacion', 'center'),
            id: "notificacion",
            accessor: "notification_pool_data",
            Cell: (row) => cellOperation(
              <Col>
                {row.value.map(r => (
                  <Row key={r.id} style={{ justifyContent: 'center' }}>
                    {`${r.first_name} ${r.last_name}`}
                  </Row>
                ))}
              </Col>
            ),
            minWidth: 200,
        },
    ]

  return (
    <div style={{ marginBottom: "15px" }}>
      <BaseTable
        columns={columns}
        data={data}
        sortable={false}
        style={{ maxHeight: '63vh' }}
      />
    </div>
  )
}

ComentarioResolucionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ComentarioResolucionTable;