import React from 'react';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import BaseTable from '../../shared/BaseTable';
import Icon from '../../UI/Icon'


const PosicionDeudorCompraVenta = ({ compraVenta }) => {
  const getYear = (date) => date.split('-')[0];
  const dataColumns = compraVenta.filter(data => data.fecha_declaracion);
  const dataResume = [
    {tipo: 'VENTAS'},
    {tipo: 'COMPRAS'},
    {tipo: 'MARGEN'},
    {tipo: 'IMPUESTO'},
  ];

  const columnsAll = [
    {
      Header: headerOperation('PERÍODO'),
      id: 'periodo',
      accessor: 'mes',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 100,
    },
    {
      Header: headerOperation('VENTAS'),
      id: 'ventas',
      accessor: 'ventas',
      Cell: (row) => cellNumOperation(row.value, 'center'),
      minWidth: 150,
    },
    {
      Header: headerOperation('COMPRAS'),
      id: 'compras',
      accessor: 'compras',
      Cell: (row) => cellNumOperation(row.value, 'center'),
      minWidth: 150,
    },
    {
      Header: headerOperation('MARGEN'),
      id: 'margen',
      accessor: 'margen',
      Cell: (row) => cellNumOperation(row.value, 'center'),
      minWidth: 150,
    }
    ,{
      Header: headerOperation('IMPUESTO'),
      id: 'impuesto',
      accessor: 'impuesto',
      Cell: (row) => cellNumOperation(row.value, 'center'),
      minWidth: 150,

    }
    ,{
      Header: headerOperation('IVA POSTERGADO'),
      id: 'iva_postergado',
      accessor:'ppi6_12c',
      Cell: (row) => cellOperation(
        <div>
          <Icon id='tooltip-ppi60d' tooltipText='Plazo 60 días'>
            {row.original.ppi60d==='si' &&(
              <FontAwesomeIcon icon={faCheckCircle} style={{color: '#0065FF'}} />
            )}
            {row.original.ppi60d==='no' &&(
              <FontAwesomeIcon icon={faMinusCircle} style={{color: '#C1C1C1'}} />
            )}
          </Icon>
          <Icon id='tooltip-ppi12c' tooltipText='12 cuotas'>
            {row.original.ppi12c==='si' &&(
              <FontAwesomeIcon icon={faCheckCircle} style={{color: '#0065FF'}} />
            )}
            {row.original.ppi12c==='no' &&(
              <FontAwesomeIcon icon={faMinusCircle} style={{color: '#C1C1C1'}} />
            )}
          </Icon>
          <Icon id='tooltip-ppi6_12c' tooltipText='6 a 12 cuotas'>
            {row.original.ppi6_12c==='si' &&(
              <FontAwesomeIcon icon={faCheckCircle} style={{color: '#0065FF'}} />
            )}
            {row.original.ppi6_12c==='no' &&(
              <FontAwesomeIcon icon={faMinusCircle} style={{color: '#C1C1C1'}} />
            )}
          </Icon>
        </div>
      ),
      minWidth: 130
    }
    ,{
      Header: headerOperation('FECHA DECLARACIÓN'),
      id: 'fecha_declaracion',
      accessor: 'fecha_declaracion',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
    }
  ];

  const columnsResume = [{
    Header: headerOperation(),
    accessor: 'tipo',
    Cell: (row) => cellOperation(row.value, 'left'),
    minWidth: 150,
    }]

  dataColumns.forEach(venta => {
    const anno = getYear(venta.mes);
    const found = dataResume.some(el => el[anno]);
    dataResume[0][anno] = (!found)? venta.ventas : venta.ventas + dataResume[0][anno];
    dataResume[1][anno] = (!found)? venta.compras : venta.compras + dataResume[1][anno];
    dataResume[2][anno] = (!found)? venta.margen : venta.margen + dataResume[2][anno];
    dataResume[3][anno] = (!found)? venta.impuesto : venta.impuesto + dataResume[3][anno];

    if (!found){
      columnsResume.push({
      Header: headerOperation(`${anno}`),
      accessor: `${anno}`,
      Cell: (row) => cellNumOperation(row.value, 'center'),
      minWidth: 150,
      })}
  })

  return (
    <>
      <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
        <BaseTable
          columns={columnsAll}
          data={dataColumns}
          filterable={false}
          style={{backgroundColor:'white'}}
          sortable={false}
        />
      </div>
      <p style={{marginTop:'40px'}}>Resumen</p>
      <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
        <BaseTable
          columns={columnsResume}
          data={dataResume}
          filterable={false}
          style={{backgroundColor:'white'}}
          sortable={false}
        />
      </div>
    </>
  );
};

PosicionDeudorCompraVenta.propTypes = {
  compraVenta: PropTypes.arrayOf(
    PropTypes.shape({
      mes: PropTypes.string,
      ventas: PropTypes.number,
      margen: PropTypes.number,
      impuesto: PropTypes.number,
      fecha_declaracion: PropTypes.string,
    }),
  ).isRequired,
};

export default PosicionDeudorCompraVenta;
