import React from 'react';
import BaseTable from 'components/UI/BaseTable';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';

const PosicionDeudorInfoTable = ( values ) => {
  const cumuloDisponible = (
    values.datos &&
    values.datos.techo_vigente &&
    values.datos.techo_vigente - values.datos.linea_vigente
  ) || 0;

  const datosColumns = [
    {
      tipo: 'Techo vigente (UF)',
      credito: values.datos.techo_vigente || '-',
    },
    {
      tipo: 'Lineas/Cúmulos vigentes',
      credito: values.datos.linea_vigente || 0,
    },
    {
      tipo: 'Cúmulo disponible',
      credito: cumuloDisponible || '-',
    },
    {
      tipo: 'Ventas declaradas vigentes',
    },
  ];

  const cellOperationPosicionDeudor = (instance, align='center', wrap=true) => {
    if(instance==='-'){
      return cellOperation(instance, align, wrap)
    }
    return cellNumOperation(instance, align);
  }

  const columns = [
    {
      Header: headerOperation('Cúmulos deudor'),
      accessor: 'tipo',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 150,
      filterable: false,
    },
    {
      Header: headerOperation('Seguro de crédito (UF)'),
      id: 'credito',
      accessor: 'credito',
      Cell: (row) => cellOperationPosicionDeudor(row.value, 'right'),
      filterable: false,
    },
    {
      Header: headerOperation('Garantías (UF)'),
      id: 'garantia',
      accessor: 'garantia',
      Cell: (row) => cellOperationPosicionDeudor(row.value, 'right'),
      filterable: false,
    },
  ];

  return <BaseTable columns={columns} data={datosColumns} resizable={false} sortable={false} />;
};

PosicionDeudorInfoTable.propTypes = {
  datos: PropTypes.shape({
    techo_vigente: PropTypes.number,
    linea_vigente: PropTypes.number,
  }).isRequired,
};

export default PosicionDeudorInfoTable;
