import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y:
    {
      grid: {
        borderDash: [1, 2],
        color: 'rgb(128,128,128)',
      },
      max: 999,
      min: 1,
      ticks: {
        font: {
          weight: 'bold'
        }
      },
      title: {
        color: 'rgb(105,105,105)',
        display: true,
        font: {
          size: 14,
          weight: 'bold',

        },
        text: "Puntaje"
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        autoSkip: false,
        // eslint-disable-next-line object-shorthand
        callback: function (val) {
          return `${this.getLabelForValue(val).substring(0, 4)}/${this.getLabelForValue(val).substring(4)}`;
        },
        font: {
          weight: 'bold'
        },
        maxRotation: 50,
        minRotation: 50
      },
      title: {
        color: 'rgb(105,105,105)',
        display: true,
        font: {
          size: 14,
          weight: 'bold'
        },
        text: "Meses"
      }
    }
  }
};

const IndicadorRiesgoHistorico = ({ data }) => {
  const labels = data.data.map((dataPoint) => dataPoint.reference_date ? dataPoint.reference_date.toString() : dataPoint.Period.toString());
  const chartData = {
    labels,
    datasets: [
      {
        data: data.data.map((dataPoint) => dataPoint.value ? dataPoint.value : dataPoint.Rate),
        borderColor: 'rgb(173, 216, 230)',
        backgroundColor: 'rgb(114,188,212)',
        pointRadius: 7,
        borderWidth: 8,
      },
    ],
  };
  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <Line data={chartData} options={options} />
    </div>
  )

};

IndicadorRiesgoHistorico.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndicadorRiesgoHistorico;