import { GET_MOVIMIENTOS_FOR_INBOX } from '../constants/action-types';

const initialState = {
  movimientos: [],
  movimientosCount: 0,
}

const executeGetMovimientosForInbox = (state, action) => ({
    ...state,
    movimientos: action.payload.results,
    movimientosCount: action.payload.count,
});

function movimientos(state = initialState, action) {
  switch (action.type) {
    case GET_MOVIMIENTOS_FOR_INBOX:
      return executeGetMovimientosForInbox(state, action);
    default:
      return state;
  }
}

export default movimientos;
