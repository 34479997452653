import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { ACCOUNTANT_URL, DOCS_URL, POLICY_URL, USERS_URL, WORKFLOW_URL, RISK_URL, REASEGURO_URL, ORSAN_LOGO_URL } from 'CONST';
import { signout } from 'helpers/auth';
import { useSelector } from 'react-redux';
import { POSICION_DEUDOR_READ, INBOX_VIEW_READ, ASEGURADOS_POLIZAS_READ } from "helpers/profilePermission";
import isUserHasProfile from "helpers/profileValidator";

import 'styles/navbar.css';

const BaseNavbar = () => {
  const [appsOpen, setAppsOpen] = useState(false);
  const [solicitudesOpen, setSolicitudesOpen] = useState(false);
  const [polizasOpen, setPolizasOpen] = useState(false);

  const handleSignOut = (event) => {
    event.preventDefault();
    signout();
  };

  const toggleApps = () => {
    setAppsOpen(!appsOpen);
  };

  const toggleSolicitudes = () => {
    setSolicitudesOpen(!solicitudesOpen);
  };

  const togglePolizas = () => {
    setPolizasOpen(!polizasOpen);
  };

  const user = useSelector((state) => state.login.user);

  return (
    user && (
      <nav className="navbar-content">
        <div className="nav-logo-container">
          <Link to="/users">
            <img src={ORSAN_LOGO_URL} className="nav-logo" alt="" />
          </Link>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
          <ul className="nav-links-group">
            <Dropdown nav className="nav-link-element" isOpen={appsOpen} toggle={toggleApps}>
              <DropdownToggle nav caret className="navbar-header">
                RIESGO DE CREDITO
              </DropdownToggle>
              <DropdownMenu>
                {user.apps.includes('users') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={USERS_URL}>
                      USUARIOS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('docs') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={DOCS_URL}>
                      DOCUMENTOS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('policies') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={POLICY_URL}>
                      PÓLIZAS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('accountant') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={ACCOUNTANT_URL}>
                      RESERVAS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('reaseguro') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={REASEGURO_URL}>
                      REASEGURO
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('risk') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={RISK_URL}>
                      RIESGO
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('workflow') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={WORKFLOW_URL}>
                      WORKFLOW
                    </a>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            {(isUserHasProfile(INBOX_VIEW_READ) || isUserHasProfile(POSICION_DEUDOR_READ)) && (
              <Dropdown
                nav
                className="nav-link-element"
                isOpen={solicitudesOpen}
                toggle={toggleSolicitudes}
              >
                <DropdownToggle nav caret>
                  Solicitudes
                </DropdownToggle>
                <DropdownMenu>
                  {isUserHasProfile(INBOX_VIEW_READ) && (
                    <DropdownItem>
                      <Link className="nav-link" to="/solicitudes/inbox-riesgos">
                        Bandeja de Entrada Riesgos
                      </Link>
                    </DropdownItem>
                  )}
                  {isUserHasProfile(POSICION_DEUDOR_READ) && (
                    <DropdownItem>
                      <Link className="nav-link" to="/solicitudes/posicion-deudor">
                        Posición del Deudor
                      </Link>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            )}
            {isUserHasProfile(ASEGURADOS_POLIZAS_READ) && (
              <Dropdown nav className="nav-link-element" isOpen={polizasOpen} toggle={togglePolizas}>
                <DropdownToggle nav caret>
                  Pólizas
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <Link className="nav-link" to="/polizas/asegurados-polizas">
                      Asegurados Pólizas
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </ul>
          <ul className="nav-links-group">
            <li className="nav-link-element nav-ite dropdown">
              <p className="nav-link">{user.username || ''}</p>
            </li>
            <li className="nav-link-element nav-item dropdown">
              <Button className="nav-link" onClick={handleSignOut}>
                Cerrar Sesión
              </Button>
            </li>
          </ul>
        </div>
      </nav>
    )
  );
};

export default BaseNavbar;
