import React from 'react';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation, cellFloatOperation } from 'helpers/tables';
import BaseTable from '../../shared/BaseTable';

const PosicionDeudorRatiosFinancieros = ({ ratiosFinancieros }) => {
  const dataColumns = [
    {tipo: 'Liquidez'},
    {tipo: 'Prueba Activa'},
    {tipo: 'Endeudamiento'},
    {tipo: 'Capital de Trabajo'},
  ];

  const columns = [
    {
      Header: headerOperation('RATIO'),
      accessor: 'tipo',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 150,
      filterable: false,
    }
  ];

  if (ratiosFinancieros){
    ratiosFinancieros.forEach(ratio => {
      dataColumns[0][`${ratio.anno}`] = ratio.liquidez;
      dataColumns[1][`${ratio.anno}`] = ratio.prueba_activa;
      dataColumns[2][`${ratio.anno}`] = ratio.endeudamiento;
      dataColumns[3][`${ratio.anno}`] = ratio.capital_trabajo;

      const checkEmpty = dataColumns.every(data => data[`${ratio.anno}`]=== 0);
      if (!checkEmpty){
      columns.push({
        Header: headerOperation(`${ratio.anno}`),
        accessor: `${ratio.anno}`,
        Cell: (row) => cellFloatOperation(row.value),
        minWidth: 150,
        filterable: false,
      });
    }
    })
  }

  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <BaseTable columns={columns} data={dataColumns} sortable={false} style={{backgroundColor:'white'}} />
    </div>
  );
};

PosicionDeudorRatiosFinancieros.propTypes = {
  ratiosFinancieros: PropTypes.arrayOf(
    PropTypes.shape({
      anno: PropTypes.number,
      liquidez: PropTypes.number,
      prueba_activa: PropTypes.number,
      endeudamiento: PropTypes.number,
      capital_trabajo: PropTypes.number,
    }),
  ).isRequired,
};

export default PosicionDeudorRatiosFinancieros;
