import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/en-gb'
import { FRONT_DF_1 } from 'CONST';
import store from '../redux-store/store/index';

numeral.locale('en-gb');

export const setFontSize = (num) => {
  const string = num.toString();
  const fontSize = string.length > 12 ? 36 - 3 * (12 / 2) : 36;
  return `${fontSize}px`;
};

export const fileDownload = (downloadUrl, id, method = 'GET', body = null, handleLoading) => {
  const { login } = store.getState();
  const xhr = new XMLHttpRequest();
  xhr.open(method, `${downloadUrl}${id}`, true);
  xhr.responseType = 'blob';
  xhr.setRequestHeader('Authorization', `JWT ${login.id_token}`);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  xhr.onload = function onload() {
    if (this.status === 200) {
      const content = xhr.getResponseHeader('Content-Disposition');
      let filename = '';
      if (content.includes('filename=')) {
        filename = content.split('filename=')[1].replace(/^"+|"+$/g, '');
      } else {
        filename = decodeURI(content.split("filename*=utf-8''")[1].replace(/^"+|"+$/g, ''));
      }
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([this.response]));
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    }
    if (handleLoading) {
      handleLoading();
    }
  };

  xhr.send(body);
};

export const roundInt = (value) => (numeral(value).format());

export const fdate = (date, format = FRONT_DF_1) => (date ? moment(date).format(format) : '');

export function sanitizeRut(rut) {
  return rut.replace(/[^\dk]/gi, '');
}

function internalFormatRut(rawRut) {
  const sanitizedRut = sanitizeRut(rawRut);
  const body = sanitizedRut.slice(0, -1);
  const dv = sanitizedRut.slice(-1).toUpperCase();
  const formatted = `${body.replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, '.$1')}-${dv}`;
  return { sanitizedRut, body, dv, formatted };
}

export function formatRut(rawRut) {
  if (!rawRut) {
    return '';
  }
  return internalFormatRut(rawRut).formatted;
}

export const bytesToMb = (number) => (number / 1024 / 1024).toFixed(2);

export const mbToBytes = (number) => number * 1024 * 1024;
