import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import useTableFilters from 'helpers/useTableFilters';
import BaseTable from '../shared/BaseTable';
import { headerOperation, cellOperation } from '../../helpers/tables';
import { API, BASE_URL } from '../../CONST';
import { formatRut } from '../../helpers/helpers';

const DeudorListTable = () => {
  const [onFilteredChange, onFetchData, loading, data] = useTableFilters(
    `${BASE_URL}${API.deudores}`,
  );

  const columns = [
    {
      Header: headerOperation('RUT', 'center'),
      id: 'rut__icontains',
      accessor: 'rut',
      Cell: (row) => cellOperation(formatRut(row.value), 'left'),
      minWidth: 90,
    },
    {
      Header: headerOperation('Nombre', 'center'),
      id: 'name__icontains',
      accessor: 'name',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 170,
    },
    {
      Header: headerOperation('Acciones', 'center'),
      id: 'acciones',
      accessor: 'id',
      Cell: (row) =>
        cellOperation(
          <Link to={`/solicitudes/posicion-deudor/${row.value}`} className="table-link">
            <FontAwesomeIcon icon={faEye} />
          </Link>,
        ),
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <div style={{ margin: '25px 0 40px 0' }}>
      <BaseTable
        columns={columns}
        data={data.data}
        resizable={false}
        loading={loading}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
        pages={data.pages}
        resultCount={data.count}
        hasFixedColumns
        showPagination
        filterable
        sortable
        style={{ maxHeight: '63vh' }}
      />
    </div>
  );
};

export default DeudorListTable;
