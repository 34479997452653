import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

import useTableFilters from 'helpers/useTableFilters';
import BaseTable from '../../shared/BaseTable';
import SafeBtn from '../../shared/SafeBtn';
import { BASE_URL, API, FRONT_DF_1 } from '../../../CONST';
import { fdate, formatRut, fileDownload } from '../../../helpers/helpers';
import { headerOperation, cellOperation, headerDoubleRowOperation } from '../../../helpers/tables';

const AseguradosPolizasTable = () => {
  const xlsxUrl = `${BASE_URL}${API.credito}/download/`;
  const [isCheck, setIsCheck] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingBtnAll, setLoadingBtnAll] = useState(false);
  const [checked, setChecked] = useState([]);
  const [onFilteredChange, onFetchData, loading, data] = useTableFilters(
    `${BASE_URL}${API.credito}/`,
  );

  const onChangeBox = (id) => {
    if (checked.includes(id)) {
      if (isCheck) {
        setIsCheck(!isCheck);
      }
      setChecked(checked.filter((item) => item !== id));
    } else {
      setChecked([...checked, id]);
    }
  };

  const onChangeBoxAll = (datos) => {
    if (isCheck) {
      setChecked([]);
    } else {
      setChecked(datos.map((value) => value.checkbox));
    }
    setIsCheck(!isCheck);
  };

  const handleDownloadAll = () => {
    fileDownload(xlsxUrl, '', 'GET', null, () => setLoadingBtnAll(false));
    setLoadingBtnAll(!loadingBtnAll);
  };

  const handleDownload = () => {
    fileDownload(xlsxUrl, '', 'POST', JSON.stringify({ ids: checked }), () => setLoadingBtn(false));
    setLoadingBtn(!loadingBtn);
  };

  const columns = [
    {
      Footer: (row) =>
        headerOperation(
          <Input
            type="checkbox"
            checked={isCheck}
            onChange={() => onChangeBoxAll(row.data)}
            style={{ marginLeft: 0 }}
          />,
        ),
      id: 'checkbox',
      accessor: 'id',
      Cell: (row) =>
        cellOperation(
          <Input
            type="checkbox"
            value={row.value}
            checked={checked.includes(row.value)}
            onChange={() => onChangeBox(row.value)}
            style={{ position: 'sticky' }}
          />,
        ),
      minWidth: 30,
      sortable: false,
      filterable: false,
    },
    {
      Header: headerOperation('Asegurado', 'left'),
      id: 'asegurado',
      accessor: 'base.asegurado.nombre',
      Cell: (row) =>
        cellOperation(
          <Link to={`/polizas/asegurados-polizas/${row.original.id}`} className="table-link">
            {row.value}
          </Link>,
          'left',
        ),
      minWidth: 140,
      filterable: false,
      Footer: (
        <div>
          <SafeBtn
            onClick={() => handleDownload()}
            block
            className='btn-orsan'
            disabled={loadingBtn}
            loading={loadingBtn}
          >
            Descargar seleccionado(s)
          </SafeBtn>
        </div>
      ),
    },
    {
      Header: headerOperation('RUT Asegurado', 'left'),
      id: 'base__asegurado',
      accessor: 'base.asegurado.rut',
      Cell: (row) => cellOperation(formatRut(row.value), 'left'),
      minWidth: 80,
      sortable: false,
      Footer: (
        <div>
          <SafeBtn
            onClick={() => handleDownloadAll()}
            block
            className='btn-orsan'
            disabled={loadingBtnAll}
            loading={loadingBtnAll}
          >
            Descargar todo
          </SafeBtn>
        </div>
      ),
    },
    {
      Header: headerOperation('Estado póliza', 'left'),
      id: 'estado',
      accessor: 'estado_poliza',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 80,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Rubro', 'left'),
      id: 'base__actividad_economica__descripcion',
      accessor: 'base.actividad_economica.descripcion',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 80,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'creación'),
      id: 'emision',
      accessor: 'emision',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1), 'left'),
      minWidth: 70,
      filterable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'inicio vigencia'),
      id: 'balance__inicio_vigencia',
      accessor: 'balance.inicio_vigencia',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1), 'left'),
      minWidth: 90,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Fecha', 'término vigencia'),
      id: 'balance__termino_vigencia',
      accessor: 'balance.termino_vigencia',
      Cell: (row) => cellOperation(fdate(row.value, FRONT_DF_1), 'left'),
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Número póliza', 'left'),
      id: 'base__nemotecnico',
      accessor: 'base.nemotecnico',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 90,
      sortable: false,
    },
  ];
  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <BaseTable
        columns={columns}
        data={data.data}
        loading={loading}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
        pages={data.pages}
        resultCount={data.count}
        filterable
        onSortedChange={onFilteredChange}
        sortable
        style={{ maxHeight: '63vh' }}
      />
    </div>
  );
};

export default AseguradosPolizasTable;
