export const objectToOption = (object, labelField = 'descripcion') => ({
  label: object[labelField],
  value: object,
});

export const objectToOptionForMultiSelect = (object, labelField = 'descripcion') => ({
  label: object[labelField],
  value: object.id,
  object,
});

export const objectsToOptions = (
  objects,
  activeOnly = false,
  isMultiSelect = false,
  labelField = 'descripcion',
) => {
  let objs = objects;
  if (activeOnly) {
    objs = objects.filter((node) => node.activo);
  }
  if (isMultiSelect) {
    return objs.map((obj) => objectToOptionForMultiSelect(obj, labelField));
  }
  return objs.map((obj) => objectToOption(obj, labelField));
};

export const filterOption = (inputValue, options) =>
  options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));

export const loadAsyncOptions = (options, inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterOption(inputValue, options));
    }, 1000);
  });

export const motivosToOption = (options) =>
  options.map((motivo) => ({
    label: `${motivo.codigo} - ${motivo.descripcion}`,
    value: motivo.id,
    motivo,
  }));

export const analistasToOptions = (options) =>
  options.map((analista) => ({
    label: `${analista.nombre} ${analista.apellido}`,
    value: analista.id,
  }));
