import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, CustomInput, FormFeedback, FormText, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import { uploadFile as rUploadFile } from '../../helpers/requests';
import { fileDownload } from '../../helpers/helpers';

const FileUploader = ({
  type,
  uploadUrl,
  inputId,
  showLabel,
  label,
  inputText,
  helpText,
  accept,
  handleInitUploading,
  handleResponse,
  disabled,
  existentFile,
  clearable,
  onClearFile,
  solicitada,
  handleSolicitada,
  filename,
  prefijo,
}) => {
  const loginIdToken = useSelector((state) => state.login.id_token);
  const [maxSizeError, setMaxSizeError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (existentFile) {
      setFileName(existentFile.name);
    }
  }, [existentFile]);

  const uploadFile = (file, fileObj) => {
    setUploading(true);
    handleInitUploading();
    rUploadFile(uploadUrl, file, fileObj)
      .then((resp) => {
        setSuccess(true);
        handleResponse({ ...resp, file });
      })
      .finally(() => {
        setFileName(file);
        setUploading(false);
      });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      if (files[0].size <= 1e8) {
        setMaxSizeError(false);
        const reader = new FileReader();
        const name = prefijo ? prefijo.concat('_', e.target.files[0].name) : e.target.files[0].name;        
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.labels[0].textContent = name;
        }
        reader.readAsArrayBuffer(files[0]);
        reader.onload = (event) => {
          uploadFile(name, event.target.result);
        };
      } else {
        setMaxSizeError(true);
      }
    }
  };

  const clearInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.labels[0].textContent = inputText;
    }
    setFileName('');
    onClearFile();
  };

  useEffect(() => {
    if (solicitada) {
      clearInput();
      handleSolicitada();
    }
  }, [solicitada]);

  useEffect(() => {
    setFileName(filename);
  }, [filename]);

  const templateCols = () => {
    let template = '1fr';
    if (existentFile) {
      template += ' auto';
    }
    if (clearable && fileName) {
      template += ' auto';
    }
    return template;
  };

  const getLabel = () => {
    if (filename !== '') {
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.labels[0].textContent = fileName;
      }
      return fileName;
    }
    return uploading ? 'Subiendo...' : fileName || inputText;
  };

  const inputType = () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: templateCols(),
      }}
    >
      <div>
        {showLabel && <Label for={inputId}>{label}</Label>}
        <CustomInput
          innerRef={fileInputRef}
          type="file"
          id={inputId}
          name="file-uploader"
          label={getLabel()}
          onChange={handleFileChange}
          invalid={maxSizeError}
          accept={accept}
          disabled={disabled}
        >
          {maxSizeError && (
            <FormFeedback>El archivo supera el tamaño máximo permitido (100 MB).</FormFeedback>
          )}
        </CustomInput>
        {helpText && <FormText color="muted">{helpText}</FormText>}
      </div>
      {existentFile && (
        <div>
          <Button
            type="button"
            color="orsan-link"
            onClick={() => fileDownload(existentFile.downloadUrl, loginIdToken)}
            title="Descargar"
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </div>
      )}
      {clearable && fileName && (
        <div>
          <Button
            type="button"
            color="orsan-link"
            onClick={() => clearInput()}
            title="Limpiar"
            style={{
              color: '#000',
              opacity: '.5',
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
      )}
    </div>
  );

  const getLinkColor = () => {
    let color = '#F98724';
    if (disabled) {
      color = '#CCCCCC';
    } else if (!uploading && maxSizeError) {
      color = '#dc3545';
    } else if (!uploading && success) {
      color = '#25983F';
    }
    return color;
  };

  const linkType = () => (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        htmlFor={inputId}
        style={{
          fontSize: '18px',
          color: getLinkColor(),
          cursor: disabled ? 'default' : 'pointer',
        }}
      >
        {uploading ? (
          <FontAwesomeIcon icon={faSpinner} pulse />
        ) : (
          <FontAwesomeIcon icon={faUpload} />
        )}
        {!disabled && (
          <input
            style={{ display: 'none' }}
            type="file"
            id={inputId}
            name="file-uploader"
            onChange={handleFileChange}
            accept={accept}
          />
        )}
      </label>
    </>
  );

  if (type === 'link') {
    return linkType();
  }
  return inputType();
};

FileUploader.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  inputText: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  accept: PropTypes.string,
  handleInitUploading: PropTypes.func,
  handleResponse: PropTypes.func,
  type: PropTypes.oneOf(['input', 'link']),
  disabled: PropTypes.bool,
  existentFile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    downloadUrl: PropTypes.string,
  }),
  clearable: PropTypes.bool,
  onClearFile: PropTypes.func,
  solicitada: PropTypes.bool,
  handleSolicitada: PropTypes.func,
  filename: PropTypes.string,
  prefijo: PropTypes.string,
};

FileUploader.defaultProps = {
  inputId: 'file-uploader',
  showLabel: false,
  label: 'Archivo',
  inputText: 'Seleccione un archivo',
  helpText: '',
  accept: undefined,
  handleInitUploading: () => {},
  handleResponse: () => {},
  type: 'input',
  disabled: false,
  existentFile: null,
  clearable: false,
  onClearFile: () => {},
  filename: '',
  prefijo: '',
};

export default FileUploader;
