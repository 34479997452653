import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { headerOperation, cellOperation } from 'helpers/tables';
import BaseTable from '../../shared/BaseTable';

const PosicionDeudorSitaucionTributaria = ({ situacionTributaria }) => {
  const columnsActividades = [
    {
      Header: headerOperation('ACTIVIDAD'),
      accessor: 'nombre',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    },
    {
      Header: headerOperation('CÓDIGO'),
      accessor: 'codigo',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    },
    {
      Header: headerOperation('CATEGORÍA'),
      accessor: 'categoria_id',
      Cell: (row) => cellOperation(row.value || '---', 'center'),
      minWidth: 150,
      filterable: false,
    },
  ];

  const columnsDocumentos = [
    {
      Header: headerOperation('DOCUMENTO'),
      accessor: 'nombre',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    },
    {
      Header: headerOperation('FECHA'),
      accessor: 'anno',
      Cell: (row) => cellOperation(row.value, 'center'),
      minWidth: 150,
      filterable: false,
    },
  ];

  const columnsObservaciones = [
    {
      Header: headerOperation('TÍTULO'),
      accessor: 'nombre',
      Cell: (row) => cellOperation(`${row.value} (${row.original.codigo})`, 'center'),
      minWidth: 150,
      filterable: false,
    },
    {
      Header: headerOperation('OBSERVACIÓN'),
      accessor: 'texto',
      Cell: (row) => cellOperation(row.value || '---', 'left'),
      minWidth: 150,
      filterable: false,
    },
  ];
  
  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <Row>
        <Col><p>Con Inicio de Actividades:</p></Col>
        <Col><p>{(situacionTributaria && situacionTributaria.activa) ? 'Sí' : 'No'}</p></Col>
      </Row>
      <Row>
        <Col><p>Fecha Inicio Actividades:</p></Col>
        <Col><p>{(situacionTributaria && situacionTributaria.inicio_actividades) || '---'}</p></Col>
      </Row>
      <Row>
        <Col><p>Categoría:</p></Col>
        <Col><p>{(situacionTributaria && situacionTributaria.categoria_id) || '---'}</p></Col>
      </Row>
      <Row>
        <Col><p>Empresa de Menor Tamaño Pro-PYME:</p></Col>
        <Col><p>{(situacionTributaria && situacionTributaria.emtp) ? 'Sí' : 'No'}</p></Col>
      </Row>
      <Row>
        <Col><p>Autorizado Pagar Moneda Extranjera:</p></Col>
        <Col><p>{(situacionTributaria && situacionTributaria.apme) ? 'Sí' : 'No'}</p></Col>
      </Row>
      <p style={{marginTop:'40px'}}>Actividades</p>
      <BaseTable
        columns={columnsActividades}
        data={situacionTributaria && situacionTributaria.actividades || []}
        sortable={false}
        style={{backgroundColor:'white'}}
      />
      <p style={{marginTop:'40px'}}>Timbrajes</p>
      <BaseTable
        columns={columnsDocumentos}
        data={situacionTributaria && situacionTributaria.documentos || []}
        sortable={false}
        style={{backgroundColor:'white'}}
      />
      <p style={{marginTop:'40px'}}>Observaciones</p>
      <BaseTable
        columns={columnsObservaciones}
        data={situacionTributaria && situacionTributaria.observaciones || []}
        sortable={false}
        style={{backgroundColor:'white'}}
      />
    </div>
  );
};

PosicionDeudorSitaucionTributaria.propTypes = {
  situacionTributaria: PropTypes.shape({
    id: PropTypes.number,
    activa: PropTypes.bool,
    inicio_actividades: PropTypes.string,
    categoria_id: PropTypes.number,
    emtp: PropTypes.bool,
    apme: PropTypes.bool,
    actividades: PropTypes.arrayOf(PropTypes.shape({
      actividad: PropTypes.string,
      codigo: PropTypes.string,
      categoria_id: PropTypes.number,
    })),
    documentos: PropTypes.arrayOf(PropTypes.shape({
      documento: PropTypes.string,
      fecha: PropTypes.number,
    })),
    observaciones: PropTypes.arrayOf(PropTypes.shape({
      nombre: PropTypes.string,
      codigo: PropTypes.string,
      texto: PropTypes.string,
    })),
  }).isRequired,
};

export default PosicionDeudorSitaucionTributaria;
