import FormSection from 'components/UI/FormSection';
import { Col, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PosicionDeudorBalanceTributario from './posicionDeudorReporteBalanceTributario';
import PosicionDeudorRatiosFinancieros from './posicionDeudorReporteRatiosFinancieros';
import PosicionDeudorEvolucionVentas from './posicionDeudorReporteEvolucionVentas';
import PosicionDeudorCompraVenta from './posicionDeudorReporteCompraVenta';
import PosicionDeudorVariacionVentas from './posicionDeudorReporteVariacionVentas';
import PosicionDeudorSociedades from './posicionDeudorReporteSociedades';
import PosicionDeudorInfoCred from './posicionDeudorReporteInfoCred';
import PosicionDeudorDirectorioFull from './posicionDeudorReporteDirectorioFull';
import PosicionDeudorSituacionTributaria from './posicionDeudorReporteSituacionTributaria';
import PosicionDeudorRentaTributaria from './posicionDeudorReporteRentaTributaria';
import PosicionDeudorAccordion from './posicionDeudorAccordion';
import Seccion from './ReporteConsolidado/seccion';


const PosicionDeudorReporteConsolidado = ({ reporteConsolidado }) => {
  const [balanceTributario, setBalanceTributario] = useState([]);
  const [equifax, setEquifax] = useState([]);
  const [ratiosFinancieros, setRatiosFinancieros] = useState([]);
  const [sociosSociedades, setSociosSociedades] = useState([]);
  const [representantes, setRepresentantes] = useState([]);
  const [infocred, setInfocred] = useState([]);
  const [directorioFull, setDirectorioFull] = useState({});
  const [rentaTributaria, setRentaTributaria] = useState([]);
  const [situacionTributaria, setSituacionTributaria] = useState({});
  const [compraVenta, setCompraVenta] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [gesintel, setGesintel] = useState([]);

  const setReporteFields = () => {
    setBalanceTributario(reporteConsolidado.balance_tributario || []);
    setEquifax(reporteConsolidado.equifax || [])
    setRatiosFinancieros(reporteConsolidado.ratios_financieros || []);
    setSociosSociedades(reporteConsolidado.socios_sociedades || []);
    setRepresentantes(reporteConsolidado.representantes || []);
    setInfocred(reporteConsolidado.infocred || []);
    setDirectorioFull(reporteConsolidado.directorio_full || {});
    setRentaTributaria(reporteConsolidado.renta_tributaria || []);
    setSituacionTributaria(reporteConsolidado.situacion_tributaria || {});
    setCompraVenta(reporteConsolidado.compra_venta || []);
    setGesintel(reporteConsolidado.gesintel || []);
  };

  useEffect(() => {
    if (Object.keys(reporteConsolidado).length !== 0) {
      setReporteFields();
      setIsLoading(false)
    }
  }, [reporteConsolidado])

  const sections = [
    {
      name: 'Equifax',
      component: <Seccion sectionData={equifax} />,
      isValid: (equifax.length > 0),
      expansible: (equifax.length > 0),
    },
    {
      name: 'Gesintel',
      component: <Seccion sectionData={gesintel} />,
      isValid: (gesintel.length > 0),
      expansible: (gesintel.length > 0),
    },
    {
      name: 'Balance Tributario',
      component: <PosicionDeudorBalanceTributario balanceTributario={balanceTributario} />,
      isValid: (balanceTributario.length > 0),
      expansible: (balanceTributario.length > 0),
    },
    {
      name: 'Ratios Financieros',
      component: <PosicionDeudorRatiosFinancieros ratiosFinancieros={ratiosFinancieros} />,
      isValid: (ratiosFinancieros.length > 0),
      expansible: (ratiosFinancieros.length > 0),
    },
    {
      name: 'Evolución de Ventas',
      component: <PosicionDeudorEvolucionVentas evolucionVentas={compraVenta} />,
      isValid: (compraVenta.length > 0),
      expansible: (compraVenta.length > 0),
    },
    {
      name: 'Compras y Ventas',
      component: <PosicionDeudorCompraVenta compraVenta={compraVenta} />,
      isValid: (compraVenta.length > 0),
      expansible: (compraVenta.length > 0),
    },
    {
      name: 'Variación de Ventas',
      component: <PosicionDeudorVariacionVentas variacionVenta={compraVenta} />,
      isValid: (compraVenta.length > 0),
      expansible: (compraVenta.length > 0),
    },
    {
      name: 'Socios y Sociedades',
      component: <PosicionDeudorSociedades sociedades={sociosSociedades} showType />,
      isValid: (sociosSociedades.length > 0),
      expansible: (sociosSociedades.length > 0),
    },
    {
      name: 'Representantes',
      component: <PosicionDeudorSociedades sociedades={representantes} />,
      isValid: (representantes.length > 0),
      expansible: (representantes.length > 0),
    },
    {
      name: 'InfoCred',
      component: <PosicionDeudorInfoCred infoCred={infocred} />,
      isValid: (infocred.length > 0),
      expansible: (infocred.length > 0),
    },
    {
      name: 'Directorio Oficial de Personas Full',
      component: <PosicionDeudorDirectorioFull directorioFull={directorioFull} />,
      isValid: (Object.keys(directorioFull).length > 0),
      expansible: (Object.keys(directorioFull).length > 0),
    },
    {
      name: 'Situación Tributaria',
      component: <PosicionDeudorSituacionTributaria situacionTributaria={situacionTributaria} />,
      isValid: (Object.keys(situacionTributaria).length > 0),
      expansible: (Object.keys(situacionTributaria).length > 0),
    },
    {
      name: 'Renta Tributaria',
      component: <PosicionDeudorRentaTributaria rentaTributaria={rentaTributaria} />,
      isValid: (rentaTributaria.length > 0),
      expansible: (rentaTributaria.length > 0),
    },
  ]


  return (
    <Col>
      <FormSection title="">
        <div className="form-box">
          {isLoading ? (
            <div className="spinner">
              <Spinner />
            </div>
          ) : (
            <PosicionDeudorAccordion
              sections={sections}
            />
          )}
        </div>
      </FormSection>
    </Col>
  );
};

PosicionDeudorReporteConsolidado.propTypes = {
  reporteConsolidado: PropTypes.shape({
    balance_tributario: PropTypes.arrayOf(
      PropTypes.object,
    ),
    equifax: PropTypes.arrayOf(
      PropTypes.object,
    ),
    ratios_financieros: PropTypes.arrayOf(
      PropTypes.object,
    ),
    socios_sociedades: PropTypes.arrayOf(
      PropTypes.object,
    ),
    representantes: PropTypes.arrayOf(
      PropTypes.object,
    ),
    infocred: PropTypes.arrayOf(
      PropTypes.object,
    ),
    directorio_full: PropTypes.objectOf(
      PropTypes.any,
    ),
    renta_tributaria: PropTypes.arrayOf(
      PropTypes.object,
    ),
    situacion_tributaria: PropTypes.objectOf(
      PropTypes.any,
    ),
    compra_venta: PropTypes.arrayOf(
      PropTypes.object,
    ),
    gesintel: PropTypes.any,
  }).isRequired,
};

export default PosicionDeudorReporteConsolidado;
