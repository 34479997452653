import React from 'react';
import PropTypes from 'prop-types';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import BaseTable from '../../shared/BaseTable';

const PosicionDeudorVariacionVentas = ({ variacionVenta }) => {
  const getYear = (date) => date.split('-')[0];
  const getMonth = (date) => parseInt(date.split('-')[1], 10);

  const dataColumns = [
    {tipo: 'Enero'},
    {tipo: 'Febrero'},
    {tipo: 'Marzo'},
    {tipo: 'Abril'},
    {tipo: 'Mayo'},
    {tipo: 'Junio'},
    {tipo: 'Julio'},
    {tipo: 'Agosto'},
    {tipo: 'Septiembre'},
    {tipo: 'Octubre'},
    {tipo: 'Noviembre'},
    {tipo: 'Diciembre'},
  ];

  const data = {};
  const years = [];

  const columns = [
    {
      Header: headerOperation('MES'),
      accessor: 'tipo',
      Cell: (row) => cellOperation(row.value, 'left'),
      minWidth: 150,
      filterable: false,
    }
  ];

  if (variacionVenta){
    variacionVenta.forEach(variacion => {
      if (!data[`${getYear(variacion.mes)}`]){
        data[`${getYear(variacion.mes)}`] = {};
        years.push(`${getYear(variacion.mes)}`);
      }

      data[`${getYear(variacion.mes)}`][`${getMonth(variacion.mes)}`] = variacion.variacion
    }) 

    years.forEach(year => {
      columns.push({
        Header: headerOperation(`${year}`),
        accessor: `${year}`,
        Cell: (row) => cellNumOperation(row.value),
        minWidth: 150,
        filterable: false,
      });
  
      dataColumns[0][year] = data[year][0] || '---';
      dataColumns[1][year] = data[year][1] || '---';
      dataColumns[2][year] = data[year][2] || '---';
      dataColumns[3][year] = data[year][3] || '---';
      dataColumns[4][year] = data[year][4] || '---';
      dataColumns[5][year] = data[year][5] || '---';
      dataColumns[6][year] = data[year][6] || '---';
      dataColumns[7][year] = data[year][7] || '---';
      dataColumns[8][year] = data[year][8] || '---';
      dataColumns[9][year] = data[year][9] || '---';
      dataColumns[10][year] = data[year][10] || '---';
      dataColumns[11][year] = data[year][11] || '---';
    });
  }

  return (
    <div style={{ margin: '25px 0 40px 0', width: '100%' }}>
      <BaseTable columns={columns} data={dataColumns} sortable={false} style={{backgroundColor:'white'}} />
    </div>
  );
};

PosicionDeudorVariacionVentas.propTypes = {
  variacionVenta: PropTypes.arrayOf(
    PropTypes.shape({
      mes: PropTypes.string,
      ventas: PropTypes.number,
      margen: PropTypes.number,
      impuesto: PropTypes.number,
      fecha_declaracion: PropTypes.string,
    }),
  ).isRequired,
};

export default PosicionDeudorVariacionVentas;
