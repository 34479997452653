import React from 'react';
import PageContainer from '../PageContainer';
import DeudorListTable from './DeudorListTable';
import DownloadBtn from "../shared/DownloadBtn";
import { BASE_URL, API } from "../../CONST";

const DeudorList = () => (
  <PageContainer
    breadcrumbs={[
      { id: 1, name: 'SOLICITUDES', url: '/' },
      { id: 2, name: 'POSICIÓN DEL DEUDOR' },
    ]}
  >
    <div className="d-flex justify-content-end mb-2">
      <DownloadBtn
        id="xlsx-download"
        text="Cartera Completa"
        tipText="Descarga Cartera Completa"
        url={`${BASE_URL}${API.movimientoLineaCredito}cartera-completa`}
      />
    </div>
    <DeudorListTable />
  </PageContainer>
);

export default DeudorList;
